import * as Sentry from '@sentry/browser';
import Backbone from 'backbone';
import $ from 'jquery';
import _ from 'underscore';

import { ErrorCode, I9nSchemaBySystem, Log, Strings } from '@biteinc/common';
import { StringHelper, TimeHelper } from '@biteinc/core-react';
import {
  BiteLogDeviceEvent,
  CardEntryMethod,
  CardSchemeIdHelper,
  FulfillmentMethodHelper,
  IntegrationSystem,
  MenuItemCategory,
  OrderClientEventName,
  OrderClientState,
  OrderPaymentDestination,
} from '@biteinc/enums';

import { BackboneEvents } from '~/app/js/backbone-events';
import { localizeStr, str } from '~/app/js/localization/localization';
import { GcnFulfillmentHelper } from '~/helpers';

import { useStore } from '../../stores';
import SessionStartAction from '../../types/session_start_action';
import GcnHelper from './gcn_helper';
import GcnOrderLeadTimeService from './gcn_lead_time_helper';
import OrderHelper from './gcn_order_helper';
import GcnRecoTracker from './gcn_reco_tracker';
import { GCNOrder } from './models/gcn_order';
import { GCNOrderedItem } from './models/gcn_ordered_item';
import { GCNTransaction } from './models/gcn_transaction';
import Analytics from './utils/analytics';
import Errors from './utils/errors';
import LocalStorage from './utils/local_storage';
import OrderEventRepo from './utils/order_event_repo';
import { GCNMenuItemEditView } from './views/gcn_menu_item_edit_view';

export const GCNOrderManager = Backbone.Model.extend({
  eventRepo: null, // OrderEventRepo

  initialize() {
    this.clearSession(true /* silent */);
    this._storedValueCards = [];
    // successful unrefunded transactions
    this._transactions = [];
    // set only when the manager has successfully entered the passcode
    this._hasPassedAlcoholPasscode = false;

    this.comboUpsellPromptCount = 0;

    this.listenTo(this, BackboneEvents.GCNOrderManager.OrderDidChange, () => {
      useStore.getState().cart.fetch();
      this.storeOrder();
    });
  },

  formatOrderPayload(uiOnly = false) {
    const orderedItems = _.map(this.getOrderedItems(), (orderedItem) => {
      return orderedItem.toJSON(uiOnly);
    });

    const payload = {
      subTotal: this.getSubTotal(),
      orderedItems,
    };

    const { language, menuFilters } = useStore.getState().config;

    // the data structure we use in the config store is Record<MenuFilterCategory, BadgeId[]>
    // but the data structure we need to send to the backend is Record<BadgeId, MenuFilterCategory>
    const formattedMenuFilters = _.reduce(
      menuFilters,
      (badges, badgeIds, key) => {
        badgeIds.forEach((badgeId) => {
          badges[badgeId] = key;
        });
        return badges;
      },
      {},
    );

    payload.preferences = {
      // check if the object is empty
      'gcn:setting:badges':
        Object.values(formattedMenuFilters).length > 0 ? formattedMenuFilters : undefined,
      'gcn:setting:language': language,
    };

    return payload;
  },

  getOrderForStore() {
    const orderedItems = this.getOrderedItems().map((orderedItem) => {
      return orderedItem.toJSON(true);
    });

    if (this._order) {
      return {
        ...(this._deliveryAddress && { deliveryAddress: this._deliveryAddress }),
        // TODO: don't store this on the order once we move the logic to use redux stores
        ...(this._deliveryInstructions && { deliveryInstructions: this._deliveryInstructions }),
        ...this._order.attributes,
        checkinUrl: this.getCheckinUrl(),
        orderNumber: GcnFulfillmentHelper.getOrderNumber(gcn.location, this._order),
        discountNames: this._order.getDiscountNames().join(', '),
        orderedItems,
        ...(this._order.get('pickupAt') && {
          pickupAtIso: TimeHelper.stringWithTimezone(
            this._order.get('pickupAt'),
            gcn.location.get('timezone'),
          ),
        }),
      };
    }

    const orderLeadTime = this.getOrderLeadTime();
    return {
      wasValidated: undefined,
      fulfillmentMethod: this._fulfillmentMethod,
      ...(this._pickupAtIso && {
        pickupAt: new Date(this._pickupAtIso).valueOf(),
        pickupAtIso: this._pickupAtIso,
      }),
      ...(orderLeadTime > 0 && {
        orderedItemsLeadTime: orderLeadTime,
      }),
      ...(this._deliveryInstructions && { deliveryInstructions: this._deliveryInstructions }),
      ...(this._deliveryAddress && { deliveryAddress: this._deliveryAddress }),
      orderedItems,
      subTotal: this.getSubTotal(),
    };
  },

  hasPassedAlcoholPasscode() {
    return this._hasPassedAlcoholPasscode;
  },

  setPassedAlcoholPasscode() {
    this._hasPassedAlcoholPasscode = true;
  },

  clonePreCheckoutOrderedItems() {
    this._orderedItemsSavedCopy = [...this._orderedItems];
  },

  /**
   * _orderedItemsSavedCopy is used by the client to fall back to post-validation of an order -
   * Since the ordered items structure can change at validation.
   * Specifically, around virtual mod groups and replaceable menu items. On validation,
   * we restructure these 'virtual' ordered mods/items - thus the client won't recognize the
   * new (validated) selection structure.
   *
   * It is set once checkout is started, and used as the default when a user backs out
   * of checkout, then immediately cleared.
   */
  revertOrderedItems() {
    this._orderedItems = [...this._orderedItemsSavedCopy];
    this._orderedItemsSavedCopy = [];
    this.trigger(BackboneEvents.GCNOrderManager.RevertOrderedItems, this);

    useStore.getState().cart.fetch();
  },

  // Clear the state that can possibly be set during the checkout summary screen.
  // Possibly revert to cloned pre-checkout ordered items
  clearCheckout() {
    this.trigger(BackboneEvents.GCNOrderManager.AboutToClearCheckout, this);

    // refund stored value transactions before so we can still reference order for orders api v1
    _.forEach(this.getTransactions(), (transaction) => {
      // Best effort, we don't want these transactions to stick around anymore and affect the order
      // state. If the refund fails, we have a cron job on maitred that auto-refunds abandoned
      // stored value transactions.
      this.refundStoredValueTransaction(transaction);
    });

    // Clear state set during any of the checkout steps.
    this._invalidateOrder();
    this._clientId = StringHelper.newMongoId();
    this._tipTotal = null;
    this._couponCode = null;
    this._ecommPaymentMethod = null;
  },

  clearOrder(silent) {
    this.clearCheckout();
    this.clearOrderLeadTime();
    this._orderedItems = [];
    this._orderedItemsSavedCopy = [];
    this._hasPassedAlcoholPasscode = false;
    if (!silent) {
      Log.info('OrderDidChange::clearOrder');
      this.trigger(BackboneEvents.GCNOrderManager.OrderDidChange, this, true);
    }
    useStore.getState().cart.fetch();
  },

  clearSession(silent) {
    this.eventRepo = new OrderEventRepo();
    this.comboUpsellPromptCount = 0;

    this.clearOrder(silent);
    this.clearAllFulfillmentData(silent);
    this._clearOrderDestinationFields();
    useStore.getState().cart.fetch();
  },

  commitEvents(cb) {
    // best effort
    if (this._order) {
      const clientEvents = this.eventRepo.getEvents();

      // In Orders API v1 we don't get an orderId until after we successfully send the order.
      // So if the session got abandoned or the order failed to send, we won't have it and this
      // call will fail.
      if (
        !this._order.id &&
        !gcn.location.useOrdersApiV2() &&
        (this.eventRepo.hasEvent(OrderClientEventName.SessionAbandon) ||
          this.eventRepo.hasEvent(OrderClientEventName.OrderSendError))
      ) {
        if (cb) {
          cb();
        }
        return;
      }

      // There may be other cases this call fails because we don't have an orderId yet. That would
      // mean that some of our event logic is off. Let's keep this call anyway as a way to find
      // out if some edge case is not being handled.
      (async () => {
        try {
          await gcn.maitred.updateOrder({ clientEvents });
        } catch (err) {
          Log.error('Failed to commit events', err);
        } finally {
          if (cb) {
            cb();
          }
        }
      })();
    } else {
      if (cb) {
        cb();
      }
    }
  },

  getClientOrderId() {
    return this._clientId;
  },

  incrementComboUpsellPromptCount() {
    this.comboUpsellPromptCount++;
  },

  comboUpsellCountWithinCap() {
    if (gcn.menu.settings.get('comboUpsell')?.maxPromptCount > 0) {
      if (this.comboUpsellPromptCount < gcn.menu.settings.get('comboUpsell')?.maxPromptCount) {
        return true;
      }

      return false;
    }

    // if the setting is unset or equals 0, we assume there's no limit
    return true;
  },

  /**
   * @param {SessionStartAction} action
   */
  setSessionStartAction(action) {
    switch (action) {
      case SessionStartAction.StartDelphiOptOut:
        this._userDidRejectConsent = true;
        break;

      case SessionStartAction.NoAction:
      case SessionStartAction.Swipe:
      case SessionStartAction.Start:
      case SessionStartAction.StartDelphiConsent:
      default:
        this._userDidRejectConsent = false;
        break;
    }
  },

  /**
   * @param {OrderPaymentDestination} destination
   */
  setPaymentDestination(destination) {
    this._paymentDestination = destination;
  },

  hasPaymentDestination() {
    return !!this._paymentDestination;
  },

  clearPaymentDestination() {
    this._paymentDestination = null;
  },

  payingAtCashier() {
    return this._paymentDestination === OrderPaymentDestination.Cashier;
  },

  setFulfillmentMethod(fulfillmentMethod) {
    Log.info('Setting fulfillmentMethod to', fulfillmentMethod);
    this._fulfillmentMethod = fulfillmentMethod;

    try {
      if (!fulfillmentMethod) {
        Sentry.addBreadcrumb({
          category: 'fulfillment-method',
          message: 'setFulfillmentMethod',
          level: 'info',
          data: {
            oldFulfillmentMethod: this._fulfillmentMethod,
            newFulfillmentMethod: fulfillmentMethod,
            trace: Error().stack,
          },
        });
      }
    } catch (err) {
      Log.error(err);
      // silently fail because Error().stack might not exist in all browsers
    }

    this.trigger(BackboneEvents.GCNOrderManager.FulfillmentDidChange, this);

    useStore.getState().cart.fetch();
  },

  getFulfillmentMethod() {
    try {
      if (!this._fulfillmentMethod) {
        Sentry.addBreadcrumb({
          category: 'fulfillment-method',
          message: 'getFulfillmentMethod',
          level: 'info',
          data: {
            fulfillmentMethod: this._fulfillmentMethod,
            ...(!this._fulfillmentMethod && { trace: Error().stack }),
          },
        });
      }
    } catch (err) {
      Log.error(err);
      // silently fail because Error().stack might not exist in all browsers
    }
    return this._fulfillmentMethod;
  },

  clearFulfillmentMethod(silently) {
    try {
      Sentry.addBreadcrumb({
        category: 'fulfillment-method',
        message: 'clearFulfillmentMethod',
        level: 'info',
        data: {
          fulfillmentMethod: this._fulfillmentMethod,
          trace: Error().stack,
        },
      });
    } catch (err) {
      Log.error(err);
      // silently fail because Error().stack might not exist in all browsers
    }
    this._fulfillmentMethod = null;
    if (!silently) {
      useStore.getState().cart.fetch();
    }
    Log.info('Cleared fulfillmentMethod');
  },

  setRoomNumber(roomNumber) {
    this._roomNumber = roomNumber;
    this.trigger(BackboneEvents.GCNOrderManager.FulfillmentDidChange, this);
    useStore.getState().cart.fetch();
  },

  getRoomNumber() {
    return this._roomNumber;
  },

  setOutpost(outpost) {
    this._outpost = outpost;
    this.trigger(BackboneEvents.GCNOrderManager.FulfillmentDidChange, this);
    useStore.getState().cart.fetch();
  },

  getOutpost() {
    return this._outpost;
  },

  getOutpostName() {
    return this._outpost?.name || null;
  },

  clearOutpost(silently) {
    this._outpost = null;
    this._roomNumber = null;
    if (!silently) {
      useStore.getState().cart.fetch();
    }
  },

  setDeliveryAddress(address) {
    this._deliveryAddress = address;
    useStore.getState().cart.fetch();
  },

  clearDeliveryAddress(silently) {
    this._deliveryAddress = null;
    if (!silently) {
      useStore.getState().cart.fetch();
    }
  },

  getDeliveryAddress() {
    return this._deliveryAddress;
  },

  orderRequiresUpdate() {
    this._requireOrderUpdate = true;
  },

  setDeliveryInstructions(deliveryInstructions) {
    this._requireOrderUpdate = this._requireOrderUpdate || !!deliveryInstructions;
    this._deliveryInstructions = deliveryInstructions;
  },

  // Clear the state that can possibly be set during the pre-checkout destination flow.
  _clearOrderDestinationFields() {
    this.clearPaymentDestination();
    this._guestName = null;
    this._tableNumber = null;
    this._guestPhoneNumber = null;
    this._guestEmail = null;
    this._guestConsentedToMarketing = null;
    this._vehicleDescriptionJson = null;
    this._deliveryAddress = null;
    this._deliveryInstructions = null;
    this._receiptTextSent = false;
  },

  getTableNumber() {
    return this._tableNumber;
  },

  setTableNumber(tableNumber) {
    this._tableNumber = tableNumber;
  },

  clearTableNumber() {
    this._tableNumber = null;
  },

  clearAllFulfillmentData(silently) {
    this.clearFulfillmentMethod(silently);
    this.clearOrderTime(silently);
    this.clearOutpost(silently);
    this.clearTableNumber();
    this.clearDeliveryAddress(silently);
    /**
     * If we don't check this then our logged in guest doesn't have their name added.
     * We probably want to rethink this sequencing and only clear these if they are not part
     * of web customer identifiers.
     */
    if (!window.webEnabled) {
      if (window.isFlash && this.getCustomer()) {
        // In flash environments, if the user is logged in then we don't want to clear their data.
        // Otherwise, they will be forced to provide it again.
        return;
      }

      this.clearGuestName();
      this.clearGuestEmail();
      this.clearGuestVehicleDescription();

      useStore.getState().checkout.onCustomerIdentifiersUpdated({
        name: null,
        email: null,
        phone: this.getGuestPhoneNumber() ?? null,
        vehicleDescription: null,
      });
    }
  },

  setGuestName(guestName) {
    this._guestName = guestName;
  },

  clearGuestName() {
    this._guestName = null;
  },

  getGuestName() {
    return this._guestName || '';
  },

  setGuestPhoneNumber(guestPhoneNumber) {
    this._guestPhoneNumber = guestPhoneNumber;
  },

  getGuestPhoneNumber() {
    return this._guestPhoneNumber;
  },

  clearGuestPhoneNumber() {
    this._guestPhoneNumber = null;
  },

  setGuestEmail(guestEmail, requireOrderUpdate) {
    if (requireOrderUpdate) {
      this.orderRequiresUpdate();
    }
    this._guestEmail = guestEmail;
  },

  clearGuestEmail() {
    this._guestEmail = null;
  },

  getGuestEmail() {
    return this._guestEmail || '';
  },

  setGuestConsentedToMarketing(guestConsentedToMarketing) {
    this.orderRequiresUpdate();
    this._guestConsentedToMarketing = guestConsentedToMarketing;
  },

  getGuestConsentedToMarketing() {
    // If value is unset, default to true
    return typeof this._guestConsentedToMarketing === 'boolean'
      ? this._guestConsentedToMarketing
      : true;
  },

  setCustomer(customer) {
    this._customer = customer;
    this.setGuestEmail(customer.email);
    this.setGuestName(`${customer.firstName} ${customer.lastName}`);
    this.setGuestPhoneNumber(customer.phoneNumber);
    this.persistGuestData();
    this.trigger(BackboneEvents.GCNOrderManager.CustomerDoesExist, this);
  },

  getCustomer() {
    return this._customer;
  },

  setGuestVehicleDescription(vehicleDescription) {
    this._vehicleDescriptionJson = vehicleDescription;
  },

  getGuestVehicleDescription() {
    return this._vehicleDescriptionJson;
  },

  clearGuestVehicleDescription() {
    this._vehicleDescriptionJson = null;
  },

  setIsAsapOrder() {
    this._isAsapOrder = true;
    this._pickupAtIso = null;
    this.trigger(BackboneEvents.GCNOrderManager.FulfillmentDidChange, this);
    useStore.getState().cart.fetch();
  },

  getIsAsapOrder() {
    return !!this._isAsapOrder;
  },

  setPickupAtIso(pickupAtIso) {
    this._pickupAtIso = pickupAtIso;
    this._isAsapOrder = false;
    this.trigger(BackboneEvents.GCNOrderManager.FulfillmentDidChange, this);
    useStore.getState().cart.fetch();
  },

  getPickupAtIso() {
    // Flash ASAP orders will not have _pickupAtIso set, but will have a pickupAt
    // if the order was validated
    if (this._order?.get('pickupAt')) {
      return TimeHelper.stringWithTimezone(
        this._order.get('pickupAt'),
        gcn.location.get('timezone'),
      );
    }
    return this._pickupAtIso;
  },

  clearOrderTime(silently) {
    this._pickupAtIso = null;
    this._isAsapOrder = false;
    if (!silently) {
      useStore.getState().cart.fetch();
    }
  },

  setOrderFromPayload(orderPayload, restoreGiftCardTransaction) {
    this.setOrderFromJSON(orderPayload.order, restoreGiftCardTransaction);
    gcn.loyaltyManager.restoreLoyaltyAuthData(orderPayload.loyaltyAuthData);
  },

  setOrderFromJSON(orderJSON, restoreGiftCardTransaction) {
    this._orderedItems = [];
    const { order } = OrderHelper.orderFromJson(orderJSON, this._orderedItems);
    this._order = order;

    const clientStates = [OrderClientState.ClientReceived, OrderClientState.Payment];
    if (restoreGiftCardTransaction && _.contains(clientStates, order.get('clientState'))) {
      const storedValueTransactions = _.filter(order.transactions, (transaction) => {
        return CardSchemeIdHelper.isStoredValue(transaction.get('cardSchemeId'));
      });
      this.setTransactions(storedValueTransactions);
    }

    // Make sure the client id matches that of the restored order
    this._clientId = order.get('clientId');
    useStore.getState().cart.fetch();

    // We have the up-to-date order, so any transactions that may have succeeded, but timed-out,
    // will be available allowing us to get the correct grand total
    gcn.maitred.resetPaymentTimedOutProperties();
  },

  getTransactions() {
    return this._transactions;
  },

  setStoredValueCards(storedValueCards) {
    this._storedValueCards = storedValueCards.map((card) => {
      card.cardName = card.cardName || str(Strings.GIFT_CARD);
      return card;
    });
  },

  getTransactionsByLastFour() {
    const transactionsByLastFour = {};
    this.getTransactions().forEach((transaction) => {
      if (!transactionsByLastFour[transaction.getLastFour()]) {
        transactionsByLastFour[transaction.getLastFour()] = [];
      }
      transactionsByLastFour[transaction.getLastFour()].push(transaction);
    });
    return transactionsByLastFour;
  },

  getStoredValueCards() {
    return this._storedValueCards;
  },

  getUnusedStoredValueCards() {
    const transactionsByLastFour = gcn.orderManager.getTransactionsByLastFour();
    return this.getStoredValueCards().filter((card) => {
      return !transactionsByLastFour[card.lastFour]?.length;
    });
  },

  getStoredValueCardsAndRemainingBalance() {
    const transactionsByLastFour = this.getTransactionsByLastFour();

    return this.getStoredValueCards().map((card) => {
      const cardTransactions = transactionsByLastFour[card.lastFour] || [];
      // TODO remove so we don't have dupes?
      // delete transactionsByLastFour[card.lastFour];
      if (_.isUndefined(card.balance)) {
        return card;
      }

      const remainingBalance = cardTransactions.reduce((balance, transaction) => {
        return balance - transaction.get('amount');
      }, card.balance);

      card.remainingBalance = remainingBalance;
      return card;
    });
  },

  getTransactionsTotal() {
    return this.getTransactions().reduce((total, transaction) => {
      return total + transaction.get('amount');
    }, 0);
  },

  setBalanceForStoredValueCard(cardNumber, balance) {
    // this should only be set from api balance inquiries
    // do not decrement after charging
    if (!cardNumber) {
      return;
    }
    const lastFour = cardNumber.slice(-4);
    let shouldUpdateCards = false;
    const cards = this.getStoredValueCards().map((card) => {
      if (lastFour === card.lastFour) {
        card.balance = balance;
        shouldUpdateCards = true;
      }
      return card;
    });

    if (!shouldUpdateCards) {
      return;
    }

    this.setStoredValueCards(cards);
  },

  addStoredValueCard(
    cardNumber,
    cardEntryMethod = CardEntryMethod.Unknown,
    cardName = str(Strings.GIFT_CARD),
  ) {
    let lastFour = '????';
    if (_.isString(cardNumber) && cardNumber.length) {
      lastFour = cardNumber.slice(-4);
    }
    this.setStoredValueCards([
      ...this.getStoredValueCards(),
      {
        cardName,
        lastFour,
        cardEntryMethod,
      },
    ]);
  },

  setTransactionsFromJson(successfulTransactionsJson) {
    const successfulTransactions = successfulTransactionsJson.map((transactionJson) => {
      return new GCNTransaction(transactionJson);
    });
    const successfulSaleTransactionsWithoutRefunds =
      GCNTransaction.getSuccessfulSaleTransactionsWithoutRefunds(successfulTransactions);
    this.setTransactions(successfulSaleTransactionsWithoutRefunds);
  },

  setTransactions(transactions) {
    this._transactions = transactions;
    this.trigger(BackboneEvents.GCNOrderManager.TransactionsDidSet, this);
  },

  _invalidateOrder() {
    this._order = null;
    this._transactions = [];
    this._isCreatingOrder = false;
    this._isValidatingOrder = false;
    this._unconfirmedPriceChanges = null;
  },

  getTipTotal() {
    if (this._order) {
      return this._order.get('tipTotal') || 0;
    }
    return 0;
  },

  getServiceChargeTotal() {
    if (this._order) {
      return this._order.get('serviceChargeTotal') || 0;
    }
    return 0;
  },

  getTippableTotal() {
    if (this._order) {
      const tippableTotal = this._order.get('subTotal');
      if (!this._order.get('wasValidated')) {
        return tippableTotal;
      }

      const outstandingAmount = gcn.orderManager.getGrandTotal();
      const isFullyUnpaid = outstandingAmount > 0 && outstandingAmount === this._order.get('total');
      return isFullyUnpaid ? tippableTotal : 0;
    }
    return 0;
  },

  // Set the total tip depending on the selected value. Removing tip sends a 0 tip value
  setTipTotal(tipTotal) {
    const self = this;
    this._tipTotal = tipTotal;
    gcn.maitred.validateTip(this.getOrderPayload(), (err, data) => {
      if (err) {
        self.trigger(BackboneEvents.GCNOrderManager.TipDidFailValidation, err);
        return;
      }
      self.setOrderFromJSON(data.order);
      self.trigger(BackboneEvents.GCNOrderManager.TipDidValidate);
    });
  },

  getCouponCode() {
    return this._couponCode;
  },

  setCouponCode(couponCode) {
    gcn.maitred.applyCoupon(couponCode, this.getOrderPayload(), (err, data) => {
      if (err) {
        switch (err.code) {
          case ErrorCode.POSCouponNotFound:
            this.trigger(
              BackboneEvents.GCNOrderManager.CouponDidFailValidation,
              Errors.stringFromErrorCode(err.code),
            );
            return;
          // These error codes should display the message from the server as it will usually
          // specify the reason (e.g. min spend).
          case ErrorCode.POSCouponUnmetParameters:
          case ErrorCode.POSCouponNotAccepted:
          case ErrorCode.POSCouponMinSpendUnmet:
          case ErrorCode.POSDiscountNotAccepted:
            this.trigger(
              BackboneEvents.GCNOrderManager.CouponDidFailValidation,
              err.message || Errors.stringFromErrorCode(err.code),
            );
            return;
          default:
            this.trigger(BackboneEvents.GCNOrderManager.OrderDidFailRevalidation);
            return;
        }
      }

      this._couponCode = couponCode;
      this.setOrderFromJSON(data.order);
      this.trigger(BackboneEvents.GCNOrderManager.CouponDidValidate);
      useStore.getState().loyalty.onCouponUpdated();
    });
  },

  removeCouponCode(callback) {
    const self = this;
    this._couponCode = null;
    gcn.menuView.showSpinner(localizeStr(Strings.REMOVING_COUPON));
    gcn.maitred.removeCoupon(this.getOrderPayload(), (err, data) => {
      if (err) {
        self.trigger(BackboneEvents.GCNOrderManager.OrderDidFailRevalidation);
        return;
      }
      self.setOrderFromJSON(data.order);
      self.trigger(BackboneEvents.GCNOrderManager.CouponDidValidate);
      useStore.getState().loyalty.onCouponUpdated();
      if (callback) {
        callback();
      }
    });
  },

  refundStoredValueTransaction(transaction, callback) {
    const refundClientId = StringHelper.newMongoId();
    if (!gcn.location.useOrdersApiV2()) {
      this.refundGiftCardTransactionOrdersApiV1(transaction, refundClientId, !!callback);
      if (callback) {
        callback();
      }
      // best effort; we are clearing the session and don't need to mutate the order state since
      // this transactions belongs to the old order
      return;
    }

    // full refunds only
    const amount = transaction.get('amount');

    gcn.maitred.giftCardRefund(
      amount,
      undefined,
      undefined,
      transaction.id,
      refundClientId,
      (err, data) => {
        // best effort; we are clearing the session and don't need to mutate the order state since
        // this transactions belongs to the old order
        if (!callback) {
          return;
        }

        if (err) {
          callback(err);
          return;
        }
        this.setOrderFromJSON(data.order);
        this.setTransactionsFromJson(data.successfulTransactions);
        useStore.getState().checkout.onStoredValueUpdated();

        callback(null, data);
      },
    );
  },

  refundGiftCardTransactionOrdersApiV1(transaction, refundClientId, bestEffort) {
    // don't wait for response from our api server for ux purposes
    // best effort between gcn and maitred, maitred will cleanup bad responses
    const orderPayload = this.getOrderPayload();

    // actually transactionI9nId, and not the rewardI9nId, they need to be the same
    const rewardI9nId = transaction.get('i9nId');
    // full refunds only
    const amount = transaction.get('amount');
    const transactionId = transaction.id;

    gcn.maitred.giftCardRefund(
      amount,
      orderPayload,
      rewardI9nId,
      transactionId,
      refundClientId,
      (err, data) => {
        if (bestEffort) {
          return;
        }

        if (err) {
          this.setTransactionsFromJson([]);
          return;
        }
        if (data.order) {
          const order = this.getOrder();
          if (order && order.get('clientId') === data.order.clientId) {
            this.setOrderFromJSON(data.order);
          }
        }
        this.setTransactionsFromJson([]);
      },
    );
  },

  setEcommPaymentMethod(paymentMethod) {
    this._ecommTransactionClientId = StringHelper.newMongoId();
    this._ecommPaymentMethod = paymentMethod;
  },

  clearEcommPaymentMethod() {
    this._ecommPaymentMethod = null;
  },

  hasEcommPaymentMethod() {
    return !!this._ecommPaymentMethod;
  },

  sendOrderEmailReceipt(validatedEmail, guestConsentedToMarketing) {
    const self = this;
    const order = this.getOrder();
    if (!order) {
      self.trigger(BackboneEvents.GCNOrderManager.EmailReceiptFailedSend, 'No order to send.');
      return;
    }
    gcn.maitred.sendEmailReceipt(order, validatedEmail, guestConsentedToMarketing, (err) => {
      if (err) {
        self.trigger(BackboneEvents.GCNOrderManager.EmailReceiptFailedSend);
        return;
      }
      self.trigger(BackboneEvents.GCNOrderManager.EmailReceiptDidSend);
    });
  },

  sendOrderReceiptText(phoneNumber) {
    if (this._receiptTextSent) {
      return;
    }
    const self = this;
    const order = this.getOrder();
    if (!order) {
      self.trigger(BackboneEvents.GCNOrderManager.TextReceiptFailedSend, 'No order to send.');
      return;
    }
    gcn.maitred.sendOrderReceiptText(order, phoneNumber, (err) => {
      if (err) {
        self.trigger(BackboneEvents.GCNOrderManager.TextReceiptFailedSend);
        return;
      }
      self._receiptTextSent = true;
      self.trigger(BackboneEvents.GCNOrderManager.TextReceiptDidSend);
    });
  },

  persistGuestData() {
    LocalStorage.setItem('guestEmail', this._guestEmail);
    LocalStorage.setItem('guestName', this._guestName);
    if (this._guestPhoneNumber) {
      LocalStorage.setItem('guestPhoneNumber', this._guestPhoneNumber);
    }
  },

  persistGuestDataAndId() {
    this.persistGuestData();
    gcn.guestManager.persistGuestId();
  },

  getPersistedGuestData() {
    const name = LocalStorage.getItem('guestName');
    const email = window.prefilledGuestEmail || LocalStorage.getItem('guestEmail');
    const phone = LocalStorage.getItem('guestPhoneNumber');
    if (email || name || phone) {
      return {
        ...(name && { name }),
        ...(email && { email }),
        ...(phone && { phone }),
      };
    }
    return null;
  },

  orderNeedsCreation() {
    return !this._order;
  },

  orderHasBeenValidated() {
    return !!this._order?.get('wasValidated');
  },

  addToOrder(
    orderedItem,
    quantity,
    { skipTracking = false, reason = '', recommendationDisplayLocationDescription = '' } = {},
  ) {
    this._invalidateOrder();
    const maxAlcoholicItemCountPerOrder = gcn.location.get('maxAlcoholicItemCountPerOrder');
    if (
      maxAlcoholicItemCountPerOrder &&
      this.getCurrentAlcoholBeverageCount(orderedItem, quantity) > maxAlcoholicItemCountPerOrder
    ) {
      // exit this flow if maxAlcoholicItemCountPerOrder is reached or exceeded
      gcn.menuView.showSimpleAlert(
        'Unable to add the item to the order because it exceeds the maximum alcoholic beverage per order.',
      );
      return;
    }

    OrderHelper.addToOrder(this._orderedItems, orderedItem, quantity, {
      skipTracking,
      reason,
      recommendationDisplayLocationDescription,
    });

    this._reselectIntegrationForOrderedItems();
    Log.info('OrderDidChange::addToOrder', orderedItem.item.id);
    this._orderLeadTime = GcnOrderLeadTimeService.getOrderLeadTime(this._orderedItems);
    this.trigger(BackboneEvents.GCNOrderManager.OrderDidChange, this, true);

    if (!this.includeUtensils) {
      // we set `this.includeUtensils` if either of the two conditions are met
      // a) customer is on FLASH and `askToIncludeUtensilsOnFlashOrders` is enabled
      // b) customer is not on FLASH and `askToIncludeUtensilsOnKioskOrders` is enabled
      const skipIncludeUtensils = gcn.location.shouldSkipIncludeUtensils(this._fulfillmentMethod);
      if (
        window.isFlash &&
        (gcn.menu.settings.get('askToIncludeUtensilsOnFlashOrders') || skipIncludeUtensils)
      ) {
        this.setIncludeUtensils(false);
      } else if (
        !window.isFlash &&
        (gcn.menu.settings.get('askToIncludeUtensilsOnKioskOrders') || skipIncludeUtensils)
      ) {
        this.setIncludeUtensils(false);
      }
    }

    useStore.getState().cart.fetch();
  },

  _canStoreOrder() {
    return _.every([window.isFlash, LocalStorage.canStoreJson()]);
  },

  clearStoredOrder() {
    LocalStorage.removeItem('lastLocationId');
    LocalStorage.removeItem('orderedItems');
    LocalStorage.removeItem('orderExpires');
  },

  storeOrder() {
    if (!this._canStoreOrder()) {
      return;
    }

    const expiryDate = new Date(new Date().getTime() + 86400 * 1000); // valid for 24h
    LocalStorage.setItem('orderExpires', expiryDate.getTime());
    LocalStorage.setItem('lastLocationId', gcn.location.id);

    // _orderedItemsSavedCopy is a copy of the ordered items pre-checkout. See comment on
    // revertOrderedItems above for further elaboration.
    // storeOrder is called upon successful validation. This will ensure if a user refreshes
    // the page after checkout & validation, we can fallback to the correct pre-validation version.
    const itemsToStore = this._orderedItemsSavedCopy?.length
      ? this._orderedItemsSavedCopy
      : this.getOrderedItems();
    const orderedItems = _.map(itemsToStore, (orderedItem) => {
      return orderedItem.toJSON();
    });
    LocalStorage.setItem('orderedItems', JSON.stringify(orderedItems));
  },

  restoreOrder() {
    if (!this._canStoreOrder()) {
      return;
    }

    // don't remember orders from different locations
    const lastLocationId = LocalStorage.getItem('lastLocationId');
    if (lastLocationId !== gcn.location.id) {
      this.clearStoredOrder();
      return;
    }

    // don't remember expired orders
    const orderExpires = LocalStorage.getItem('orderExpires');
    if (!orderExpires) {
      this.clearStoredOrder();
      return;
    }

    if (new Date(parseInt(orderExpires, 10)) < new Date()) {
      Log.info('clearing expired stored order.');
      // expired
      this.clearStoredOrder();
      return;
    }

    // readd items from stored cart
    const orderedItemsJson = LocalStorage.getJsonItem('orderedItems');

    const order = new GCNOrder({
      orderedItems: orderedItemsJson,
    });
    _.each(order.orderedItems, (orderedItem) => {
      const quantity = orderedItem.get('priceOption').quantity;
      gcn.orderManager.addToOrder(orderedItem, quantity, { skipTracking: true });
    });
    this.removeOutdatedOrderedItems();
  },

  setIncludeUtensils(includeUtensils) {
    this.includeUtensils = includeUtensils;
  },

  hasOrderedItemWithId(itemId) {
    return _.any(this._orderedItems, (orderedItem) => {
      return orderedItem.id === itemId;
    });
  },

  getOrderedItemWithIdWithSelections(itemId, selectionsString) {
    return _.find(this._orderedItems, (orderedItem) => {
      return (
        orderedItem.id === itemId && orderedItem.getSelectionsInStringForm() === selectionsString
      );
    });
  },

  getOrderSize() {
    return this._orderedItems.reduce((count, orderedItem) => {
      return count + orderedItem.orderedPO.get('quantity');
    }, 0);
  },

  getOrderedItems() {
    return [...this._orderedItems];
  },

  hasOnlyRetailOrderedItems() {
    return _.all(this.getOrderedItems(), (orderedItem) => {
      return orderedItem.item.isRetail();
    });
  },

  getSubTotal() {
    if (this._order) {
      return this._order.get('subTotal');
    }
    let subTotal = 0;
    _.each(this._orderedItems, (orderedItem) => {
      subTotal += orderedItem.getTotal();
    });
    return subTotal;
  },

  getTaxTotal() {
    return this._order.get('taxTotal');
  },

  // Returns a list of reward objects that have been applied to the current order.
  getAppliedRewards() {
    return this._order?._appliedRewards || [];
  },

  getAppliedCompCards() {
    return this._order?._appliedCompCards || [];
  },

  // Find a reward on the order that matches origReward, otherwise returns undefined.
  findAppliedReward(origReward) {
    return _.find(this.getAppliedRewards(), (reward) => {
      return reward.get('rewardI9nId') === origReward.get('rewardI9nId');
    });
  },

  getDiscountNames() {
    return this._order.getDiscountNames();
  },

  getDiscountNamesWithAmounts() {
    return this._order.getDiscountNamesWithAmounts();
  },

  getDiscountTotal() {
    if (this._order.has('discountTotal')) {
      return this._order.get('discountTotal');
    }
    return 0;
  },

  getNetSales() {
    if (!this._order) {
      return null;
    }

    return this._order.get('subTotal') - this._order.get('discountTotal');
  },

  getGrandTotal() {
    if (!this._order) {
      return null;
    }
    let total = this._order.get('total');

    total -= this.getTransactionsTotal();

    if (gcn.loyaltyManager.getLoyaltyTransaction()) {
      total -= gcn.loyaltyManager.getLoyaltyTransaction().get('amount');
    }
    _.each(this._order.transactions, (transaction) => {
      total -= transaction.get('amount');
    });

    if (_.isUndefined(total) || _.isNaN(total)) {
      // debug for https://getbite.atlassian.net/browse/BITE-682
      Sentry.captureException(new Error('undefined or NaN total'), {
        extra: {
          total,
          isUndefinedTotal: _.isUndefined(total),
          isNaNTotal: _.isNaN(total),
          order: this._order?.toJSON(),
          transactions: this.getTransactions()?.map((transaction) => {
            return transaction?.toJSON();
          }),
          loyaltyTransaction: gcn.loyaltyManager.getLoyaltyTransaction()?.toJSON(),
        },
      });
    }

    return total;
  },

  removeFromOrder(orderedItem, reason) {
    this._invalidateOrder();

    const menuItemId = orderedItem.item.id;
    const menuItemName = orderedItem.item.displayName();
    this.eventRepo.trackMenuItemRemoveFromCart(menuItemId, menuItemName, reason);
    Analytics.trackEvent({
      eventName: Analytics.EventName.CartMenuItemRemoved,
      eventData: {
        itemName: menuItemName,
        ...(reason && { reason }),
      },
    });

    this._orderedItems = this._orderedItems.filter((item) => item._uid !== orderedItem._uid);
    this._reselectIntegrationForOrderedItems();
    Log.info('OrderDidChange::removeFromOrder', orderedItem.item.id);
    this._orderLeadTime = GcnOrderLeadTimeService.getOrderLeadTime(this._orderedItems);
    this.trigger(BackboneEvents.GCNOrderManager.OrderDidChange, this, true);
  },

  removeFromOrderV2(uid) {
    const orderedItem = this._orderedItems.find((item) => item._uid === uid);
    if (!orderedItem) {
      Log.error('OrderManager::removeFromOrderV2: Ordered item not found: ', uid);
      throw new Error(`Ordered item not found with uid: ${uid}`);
    }
    this.removeFromOrder(orderedItem);
  },

  editItem(uid) {
    const orderedItem = this._orderedItems.find((item) => item._uid === uid);
    if (!orderedItem) {
      Log.error('OrderManager::editItem: Ordered item not found: ', uid);
      throw new Error(`Ordered item not found with uid: ${uid}`);
    }
    const itemEditView = new GCNMenuItemEditView({ model: orderedItem });
    this.listenToOnce(itemEditView, BackboneEvents.GCNMenuItemOrderView.DonePressed, () => {
      gcn.menuView.dismissStablePopup();
      useStore.getState().cart.fetch();
      Analytics.trackEvent({
        eventName: Analytics.EventName.CartMenuItemEditComplete,
        eventData: {
          itemId: orderedItem._id,
          itemName: orderedItem.name,
          itemPosId: orderedItem.posId,
        },
      });
    });
    gcn.menuView.showStablePopup(itemEditView, 'menu-item-edit-view');
  },

  replaceInOrder(oldOrderedItem, newOrderedItem) {
    this._invalidateOrder();
    const index = _.indexOf(this._orderedItems, oldOrderedItem);
    if (index !== -1) {
      this._orderedItems[index] = newOrderedItem;
      this._reselectIntegrationForOrderedItems();
      newOrderedItem.clearSelectedAddonsForOtherPriceOptions();
    } else {
      Log.error('Item not found', oldOrderedItem);
    }
    Log.info('OrderDidChange::replaceInOrder', newOrderedItem.item.id);
    this.trigger(BackboneEvents.GCNOrderManager.OrderDidChange, this, false);
  },

  findOrderedItemWithPOSId(posId) {
    return _.find(this._orderedItems, (orderedItem) => {
      return orderedItem.item.posId() === posId;
    });
  },

  getOrderId() {
    return this._order.id;
  },

  /**
   * @todo DELETE once all locations use Orders API v2
   */
  getPosOrderId() {
    const { order } = this.getOrderPayload();
    const integrationsData = order.dataForVendors;
    for (let i = 0; i < integrationsData.length; i++) {
      const i9nData = integrationsData[i];
      /** @type IntegrationSystem */
      const system = i9nData.system;
      const i9nSchema = I9nSchemaBySystem[system];
      if (
        // i9nSchema may be undefined if this is an non-integrated location
        i9nSchema &&
        i9nSchema.type === 'pos' &&
        i9nSchema.posOrderIdKey &&
        i9nData.validationData &&
        i9nData.validationData[i9nSchema.posOrderIdKey]
      ) {
        return i9nData.validationData[i9nSchema.posOrderIdKey];
      }
    }
    return null;
  },

  // v2 orders api
  getCloseOrderPayload() {
    const payload = {};
    if (gcn.loyaltyManager.hasLoyaltyAuthData()) {
      payload.loyaltyAuthData = gcn.loyaltyManager.getLoyaltyAuthData();
    }
    if (gcn.loyaltyManager.getCompCardAuthData()) {
      payload.compCardAuthData = gcn.loyaltyManager.getCompCardAuthData();
    }

    Sentry.addBreadcrumb({
      category: 'close-order-debug',
      message: 'getCloseOrderPayload',
      level: 'info',
      data: {
        payload,
      },
    });
    return payload;
  },

  // v2 orders api
  getOrderUpdatePayload() {
    if (!this._requireOrderUpdate) {
      return null;
    }

    return {
      order: {
        // this can be set through ecomm payment flow
        ...(this._guestEmail && { guestEmail: this._guestEmail }),
        // this can be set through ecomm payment, email receipt, text when ready, simple
        // loyalty signup, and customer identifier flows
        // only send the value if it's a boolean because if the user never encountered the consent
        // checkbox during some flow, then it doesn't make sense to say that they consented
        ...(typeof this._guestConsentedToMarketing === 'boolean' && {
          guestConsentedToMarketing: this._guestConsentedToMarketing,
        }),
        ...(this._deliveryInstructions && { deliveryInstructions: this._deliveryInstructions }),
        // Checkout v2
        ...(this._guestName && { guestName: this._guestName }),
        ...(this._guestPhoneNumber && { guestPhoneNumber: this._guestPhoneNumber }),
        ...(this._vehicleDescriptionJson && { guestVehicle: this._vehicleDescriptionJson }),
      },
    };
  },

  // v2 orders api
  getEcommPaymentPayload() {
    /**
     * Stripe payment methods are one time usage - if it fails the first time they will need
     * to enter a new payment method or the details again
     */
    const payload = {
      orderId: this.getOrderId(),
      amount: this.getGrandTotal(),
      clientId: this._ecommTransactionClientId,
    };
    if (gcn.loyaltyManager.hasLoyaltyAuthData()) {
      payload.loyaltyAuthData = gcn.loyaltyManager.getLoyaltyAuthData();
    }
    if (gcn.loyaltyManager.getCompCardAuthData()) {
      payload.compCardAuthData = gcn.loyaltyManager.getCompCardAuthData();
    }

    switch (gcn.location.getPaymentSystem()) {
      case IntegrationSystem.Stripe:
        payload.stripePaymentMethod = this._ecommPaymentMethod;
        break;
      case IntegrationSystem.FreedomPayFlash:
        payload.freedomPayPaymentMethod = this._ecommPaymentMethod;
        break;
      default:
        throw new Error('Payment integration cannot send payment method');
    }
    return payload;
  },

  getPreReadCardPayload() {
    const payload = {
      clientId: StringHelper.newMongoId(),
    };
    return payload;
  },

  getKioskApiPaymentPayload() {
    const payload = {
      orderId: this.getOrderId(),
      amount: this.getGrandTotal(),
      clientId: StringHelper.newMongoId(),
    };
    return payload;
  },

  getKioskApiPaymentCancelPayload() {
    const payload = {
      orderId: this.getOrderId(),
    };
    return payload;
  },

  // v2 orders api
  getCreateOrderPayload() {
    let orderJson = null;

    if (!this._order) {
      const now = Date.now();
      const newOrderJson = {
        ...this.formatOrderPayload(),
        menuStructureId: gcn.menu.structure.id,
        createdAt: now,
        sessionDuration:
          gcn.sessionWasStartedAt !== 0 ? now - gcn.sessionWasStartedAt : TimeHelper.SECOND * 100,
        clientId: this.getClientOrderId(),
        fulfillmentMethod: this.getFulfillmentMethod(),
        clientEvents: this.eventRepo.getEvents(),
      };

      orderJson = newOrderJson;
    } else {
      orderJson = {
        ...this._order.attributes,
      };
      Log.debug('internal order', this._order);
    }

    const usesDelphi = gcn.location.get('usesDelphi') && !window.isFlash;
    orderJson = {
      guestId: gcn.guestManager.getGuestId(),
      selectedLanguage: gcn.getLanguage(),
      ...orderJson,
      ...(this._paymentDestination && { paymentDestination: this._paymentDestination }),
      ...(this._tableNumber && { tableNumber: this._tableNumber }),
      ...(this._guestName && { guestName: this._guestName }),
      ...(this._guestPhoneNumber && { guestPhoneNumber: this._guestPhoneNumber }),
      ...(this._guestEmail && { guestEmail: this._guestEmail }),
      // check if it's a boolean instead of truthy because user could revoke consent
      ...(typeof this._guestConsentedToMarketing === 'boolean' && {
        guestConsentedToMarketing: this._guestConsentedToMarketing,
      }),
      ...(this._vehicleDescriptionJson && { guestVehicle: this._vehicleDescriptionJson }),
      ...(this._pickupAtIso && { pickupAt: new Date(this._pickupAtIso).valueOf() }),
      ...(this._customer && { customerId: this._customer._id }),
      ...(this._outpost && { outpostId: this._outpost._id }),
      ...(this._outpost && this._roomNumber && { outpostRoomNumber: this._roomNumber }),
      ...(this._deliveryAddress && { deliveryAddress: this._deliveryAddress }),
      ...(usesDelphi && { userDidRejectConsent: !!this._userDidRejectConsent }),
      // check if it's a boolean instead of truthy because includeUtensils can only be set if
      // `askToIncludeUtensilsOnKioskOrders` or `askToIncludeUtensilsOnFlashOrders` is set
      ...(typeof this.includeUtensils === 'boolean' && {
        includeUtensils: this.includeUtensils,
      }),
    };

    if (!orderJson.fulfillmentMethod) {
      Sentry.addBreadcrumb({
        category: 'fulfillment-method',
        level: 'warning',
        message: 'No fulfillment method on create order payload',
      });
    }

    if (gcn.loyaltyManager.hasLoyaltyAuthData()) {
      orderJson.hasLoyaltyProgram = true;
    }

    // This needs to replicate the getOrderPayload method
    return {
      order: orderJson,
    };
  },

  // v2 orders api
  getTipPayload() {
    return {
      order: {
        tipTotal: this._tipTotal,
      },
    };
  },

  isOrderClosed() {
    return !!this._order?.isClosed();
  },

  getOrderPayload() {
    let orderJSON = null;

    if (this._order) {
      orderJSON = _.extend({}, this._order.attributes);
      Log.debug('internal order', this._order);
      // Set post-validation fields.
      if (this.getTransactions().length) {
        orderJSON.transactions = this.getTransactions().map((transaction) => {
          return transaction.toJSON();
        });
      }
      if (this._tipTotal !== null) {
        orderJSON.tipTotal = this._tipTotal;
      }
    } else {
      const now = Date.now();
      const fulfillmentMethod = this.getFulfillmentMethod();
      orderJSON = this.formatOrderPayload();
      orderJSON.fulfillmentMethod = fulfillmentMethod;
      orderJSON.createdAt = now;
      orderJSON.sessionDuration = now - gcn.sessionWasStartedAt;
      orderJSON.clientId = this.getClientOrderId();
      orderJSON.menuStructureId = gcn.menu.structure.id;

      if (!this.orderNeedsCreation()) {
        this._order = new GCNOrder(orderJSON);
      }
    }

    orderJSON.clientEvents = this.eventRepo.getEvents();
    orderJSON.guestId = gcn.guestManager.getGuestId();
    orderJSON.selectedLanguage = gcn.getLanguage();

    if (this._paymentDestination) {
      orderJSON.paymentDestination = this._paymentDestination;
    }
    if (this._tableNumber) {
      orderJSON.tableNumber = this._tableNumber;
    }
    if (this._guestName) {
      orderJSON.guestName = this._guestName;
    }
    if (this._guestPhoneNumber) {
      const guestPhoneNumber = this._guestPhoneNumber.replace(/[^0-9]+/g, '');
      orderJSON.guestPhoneNumber = guestPhoneNumber;
    }
    if (this._guestEmail) {
      orderJSON.guestEmail = this._guestEmail;
    }
    // check if it's a boolean instead of truthy because user could revoke consent
    if (typeof this._guestConsentedToMarketing === 'boolean') {
      orderJSON.guestConsentedToMarketing = this._guestConsentedToMarketing;
    }
    if (this._vehicleDescriptionJson) {
      orderJSON.guestVehicle = this._vehicleDescriptionJson;
    }
    if (this._userDidRejectConsent) {
      orderJSON.userDidRejectConsent = this._userDidRejectConsent;
    }
    if (this._pickupAtIso) {
      orderJSON.pickupAt = new Date(this._pickupAtIso).valueOf();
    }
    if (this._customer) {
      orderJSON.customerId = this._customer._id;
    }
    if (this._outpost) {
      orderJSON.outpostId = this._outpost._id;
      if (this._roomNumber) {
        orderJSON.outpostRoomNumber = this._roomNumber;
      }
    }
    if (this._deliveryAddress) {
      orderJSON.deliveryAddress = this._deliveryAddress;
    }
    if (typeof this.includeUtensils === 'boolean') {
      orderJSON.includeUtensils = this.includeUtensils;
    }

    const orderPayload = {
      order: orderJSON,
    };
    /**
     * Stripe payment methods are one time usage - if it fails the first time they will need
     * to enter a new payment method or the details again
     */
    if (this._ecommPaymentMethod) {
      orderPayload.ecommPaymentMethod = this._ecommPaymentMethod;
    }

    if (gcn.loyaltyManager.hasLoyaltyAuthData()) {
      orderPayload.loyaltyAuthData = gcn.loyaltyManager.getLoyaltyAuthData();
      orderPayload.order.hasLoyaltyProgram = true;
    }
    if (gcn.loyaltyManager.getCompCardAuthData()) {
      orderPayload.compCardAuthData = gcn.loyaltyManager.getCompCardAuthData();
    }

    if (!orderPayload.order.fulfillmentMethod) {
      Sentry.addBreadcrumb({
        level: 'warning',
        category: 'fulfillment-method',
        message: 'No fulfillment method on get order payload',
      });
    }

    return orderPayload;
  },

  getOrder() {
    return this._order;
  },

  getCheckoutSession() {
    // Temporarily, send both the new and the old format
    const orderPayload = this.getOrderPayload();
    if (orderPayload.loyaltyAuthData) {
      orderPayload.order.loyaltyAuthData = orderPayload.loyaltyAuthData;
    }
    if (orderPayload.compCardAuthData) {
      orderPayload.order.compCardAuthData = orderPayload.compCardAuthData;
    }

    return {
      orderPayload: orderPayload.order,
      orderPayload2: orderPayload,
      ...(!window.isFlash && {
        printPayload: OrderHelper.getPrintPayload(gcn.orderManager.getOrder()),
      }),
      skipPaymentStep: this.payingAtCashier() || !gcn.location.takesPayment(),
      remainingBalance: this.getGrandTotal(),
    };
  },

  // Removes multiple items from the cart.
  removeOutdatedOrderedItems() {
    const removedOrderedItems = [];
    for (let i = _.size(this._orderedItems) - 1; i >= 0; i--) {
      const orderedItem = this._orderedItems[i];
      if (!gcn.menu.getMenuItemWithId(orderedItem.item.id)) {
        this.removeFromOrder(orderedItem, 'outdated');
        removedOrderedItems.push(orderedItem.item.displayName());
      }
    }

    useStore.getState().cart.fetch();

    return removedOrderedItems;
  },

  // Removes multiple items from the cart and displays error message
  removeOutdatedOrderedItemsAndShowError() {
    const removedItemNames = this.removeOutdatedOrderedItems();

    if (!removedItemNames.length) {
      return;
    }
    const uniqueItemNames = [];
    removedItemNames.forEach((removedItemName) => {
      if (!uniqueItemNames.includes(removedItemName)) {
        uniqueItemNames.push(removedItemName);
      }
    });
    if (uniqueItemNames.length <= 3) {
      gcn.menuView.showSimpleAlert(
        `Sorry! <strong>${uniqueItemNames.join(', ')}</strong> ${
          uniqueItemNames.length === 1 ? 'is' : 'are'
        } no longer available. ${
          uniqueItemNames.length === 1 ? 'It has' : 'They have'
        } been removed from your order.`,
      );
    } else {
      gcn.menuView.showSimpleAlert(
        `Sorry! <strong>${uniqueItemNames.length}</strong> items are no longer available. They have been removed from your order.`,
      );
    }
    Log.info('OrderDidChange::filteredFromCart');
    this.trigger(BackboneEvents.GCNOrderManager.OrderDidChange, this, true);
  },

  _onValidateOrderSuccess(data) {
    const previousTotal = this.getSubTotal();
    const subTotalChange = data.order.subTotal - previousTotal;
    this.setOrderFromJSON(data.order);

    if (this.shouldShowOrderLeadTimeWarningPopup()) {
      const orderLeadTime = gcn.orderManager.getOrderLeadTime();
      const readyTime = TimeHelper.millisecondsToFriendlyDescription(orderLeadTime);
      gcn.menuView.showSimpleAlert(
        `${localizeStr(Strings.LEAD_TIME_LONG)} (Ready in ${readyTime})`,
      );
    }

    if (
      (subTotalChange > 0 &&
        subTotalChange >= (gcn.menu.settings.get('minOrderSubTotalIncreaseForWarning') || 0)) ||
      (subTotalChange < 0 &&
        Math.abs(subTotalChange) >=
          (gcn.menu.settings.get('minOrderSubTotalDecreaseForWarning') || 0))
    ) {
      this._unconfirmedPriceChanges = true;
      this._previousUnconfirmedPriceChange = previousTotal;
      this.trigger(BackboneEvents.GCNOrderManager.OrderDidValidateWithChanges, this);
    } else {
      this.trigger(BackboneEvents.GCNOrderManager.OrderDidValidate, this);
    }
  },

  createOrder() {
    if (this._isCreatingOrder) {
      return;
    }

    this._isCreatingOrder = true;
    const timeout = gcn.location.get('orderValidateTimeout');
    gcn.maitred.createOrder(this.getOrderPayload(), timeout, (err, data) => {
      // Bail if the order has since changed
      if (!this._isCreatingOrder) {
        return;
      }

      this._isCreatingOrder = false;
      if (err) {
        Log.debug('err creating', err);
        gcn.sendDeviceLog(BiteLogDeviceEvent.FailedOrderCreation, err.code);
        this.trigger(BackboneEvents.GCNOrderManager.OrderDidFailCreation, err);
        return;
      }

      Log.debug('orderJSON created', data);

      // immediately create order and recommendation references if using Orders API v2
      // In v1 we don't get a reference to the order on validation
      if (gcn.location.useOrdersApiV2()) {
        GcnRecoTracker.createOrderReferences(gcn.maitred, data.order._id);
      }

      if (data.order.guestId && data.order.guestId !== gcn.guestManager.getGuestId()) {
        gcn.guestManager.setGuestId(data.order.guestId);
      }

      if (gcn.location.useOrdersApiV2()) {
        this.setOrderFromJSON(data.order);
        this.validateOrder(data.order._id);
      } else {
        // In V1, validation is performed at the same time as creation
        this._onValidateOrderSuccess(data);
      }
    });
  },

  validateOrder(orderId) {
    if (this._isValidatingOrder) {
      return;
    }

    this._isValidatingOrder = true;
    gcn.maitred
      .validateOrder(orderId)
      .then((data) => {
        if (!this._isValidatingOrder) {
          return;
        }
        this._isValidatingOrder = false;

        Log.debug('orderJSON validated', data);

        this._onValidateOrderSuccess(data);
      })
      .catch((err) => {
        if (!this._isValidatingOrder) {
          return;
        }
        this._isValidatingOrder = false;

        Log.debug('err validating', err);

        gcn.sendDeviceLog(BiteLogDeviceEvent.FailedOrderValidation, err.code);
        this.trigger(BackboneEvents.GCNOrderManager.OrderDidFailValidation, err);
      });
  },

  getOrderLeadTime() {
    if (this._order) {
      return this._order.get('orderedItemsLeadTime');
    }
    const orderLeadTime = this._orderLeadTime;
    if (FulfillmentMethodHelper.isDelivery(this._fulfillmentMethod)) {
      const { deliveryTimeEstimate = 0 } = gcn.location.getDiningOption(this._fulfillmentMethod);
      return orderLeadTime + TimeHelper.minsToMs(deliveryTimeEstimate);
    }
    return orderLeadTime;
  },

  // Only show warning for future orders; this logic is identical to v2 checkout
  shouldShowOrderLeadTimeWarningPopup() {
    const now = Date.now();
    const orderLeadTime = gcn.orderManager.getOrderLeadTime();
    const pickupAtIso = gcn.orderManager.getPickupAtIso();
    const pickupTime = pickupAtIso ? TimeHelper.timestampFromIsoString(pickupAtIso) : 0;
    return orderLeadTime > 0 && pickupTime > 0 && now + orderLeadTime > pickupTime;
  },

  shouldShowOrderLeadTimeWarningInCart() {
    const now = Date.now();
    const orderLeadTime = gcn.orderManager.getOrderLeadTime();
    const pickupAtIso = gcn.orderManager.getPickupAtIso();
    // Don't show the warning for ASAP orders that are going to take under 15 minutes
    const pickupTime = pickupAtIso
      ? TimeHelper.timestampFromIsoString(pickupAtIso)
      : now + TimeHelper.MINUTE * 15;
    return orderLeadTime > 0 && now + orderLeadTime > pickupTime;
  },

  clearOrderLeadTime() {
    this._orderLeadTime = null;
  },

  confirmPriceChanges() {
    this._unconfirmedPriceChanges = false;
    this.trigger(BackboneEvents.GCNOrderManager.OrderDidValidate, this);
  },

  hasUnconfirmedPriceChanges() {
    return this._unconfirmedPriceChanges;
  },

  getUnconfirmedPriceChanges() {
    return this._previousUnconfirmedPriceChange;
  },

  /**
   * @returns {{ price: number; name: string }[]}
   */
  getPriceChanges() {
    const priceChanges = [];
    this.getOrderedItems().forEach((orderedItem) => {
      priceChanges.push(...GCNOrderedItem.getPriceChanges(orderedItem.attributes));
    });
    return priceChanges;
  },

  shouldShowSubtotalChangeAlert() {
    return (
      this._unconfirmedPriceChanges && this.getSubTotal() > this._previousUnconfirmedPriceChange
    );
  },

  getSubtotalChangeContent() {
    const priceChanges = gcn.orderManager.getPriceChanges();
    const $content = $('<div>');
    if (priceChanges.length) {
      const subTotal = GcnHelper.stringFromTotal(gcn.orderManager.getSubTotal());
      const $correctionsList = $('<ul class="price-corrections">');
      priceChanges.forEach((priceChange) => {
        const newPriceStr = GcnHelper.stringFromTotal(priceChange.unitPrice);
        $correctionsList.append(
          `<li>The price of ${priceChange.name} changed and is now $${newPriceStr}</li>`,
        );
      });
      $content.append($correctionsList);
      $content.append(
        `<p>${localizeStr(Strings.TOTAL_CHANGED_SUBTOTAL_LEADING_TEXT)} $${subTotal}${localizeStr(
          Strings.TOTAL_CHANGED_TRAILING_TEXT,
        )}</p>`,
      );
    } else {
      const grandTotal = GcnHelper.stringFromTotal(gcn.orderManager.getGrandTotal());
      $content.append(
        `<p>${localizeStr(Strings.TOTAL_CHANGED_LEADING_TEXT)} $${grandTotal}${localizeStr(
          Strings.TOTAL_CHANGED_TRAILING_TEXT,
        )}</p>`,
      );
    }
    return $content;
  },

  revertPriceChanges() {
    const self = this;
    this._orderedItems = this.getOrderedItems().map((orderedItem) => {
      const orderedItemJSON = orderedItem.attributes;
      const originalPrice = orderedItemJSON.priceOption.originalPrice;
      const originalUnitPrice = orderedItemJSON.priceOption.originalUnitPrice;
      if (originalPrice) {
        orderedItemJSON.priceOption.price = originalPrice;
        orderedItemJSON.priceOption.unitPrice = originalUnitPrice;
        delete orderedItemJSON.priceOption.originalPrice;
        delete orderedItemJSON.priceOption.originalUnitPrice;
        return new GCNOrderedItem(orderedItemJSON);
      }
      return orderedItem;
    });
    Log.info('OrderDidChange::revertPriceChanges');
    self.trigger(BackboneEvents.GCNOrderManager.OrderDidChange, self, true);
  },

  getCurrentAlcoholBeverageCount(newOrderedItem = null, quantity) {
    const maxAlcoholicItemCountPerOrder = gcn.location.get('maxAlcoholicItemCountPerOrder');

    if (!this.hasAlcoholicContents(newOrderedItem) || !maxAlcoholicItemCountPerOrder) {
      return false;
    }

    let alcoholicBeverageCount = 0;
    if (
      newOrderedItem &&
      newOrderedItem.item.get('category') === MenuItemCategory.AlcoholicBeverage
    ) {
      // we default the quantity to 1 if `quantity` argument is not provided
      // and the quantity of the ordered item is not set in the orderedPO
      alcoholicBeverageCount += quantity || newOrderedItem.orderedPO.get('quantity') || 1;
    }

    this._orderedItems.forEach((orderedItem) => {
      if (orderedItem.item.get('category') === MenuItemCategory.AlcoholicBeverage) {
        alcoholicBeverageCount += orderedItem.orderedPO.get('quantity');
      }
    });
    return alcoholicBeverageCount;
  },

  hasAlcoholicContents(newOrderedItem = null) {
    const aggregatedOrderedItems = newOrderedItem
      ? [newOrderedItem, ...this._orderedItems]
      : this._orderedItems;
    return _.some(aggregatedOrderedItems, (orderedItem) => {
      return orderedItem.item.get('category') === MenuItemCategory.AlcoholicBeverage;
    });
  },

  // To be called when an order is successfully sent.
  performOrderCompletedTasks(paymentStartedTime) {
    // Session tracking updates.
    const sessionData = {
      numberOfItemsOrdered: this.getOrderSize(),
      orderSubTotal: this.getSubTotal(),
      reason: 'completedOrder',
      orderTaxTotal: this._order.get('taxTotal'),
      orderGrandTotal: this._order.get('total'),
    };
    if (paymentStartedTime) {
      sessionData.paymentStepDuration = Date.now() - paymentStartedTime;
    }
    gcn.updateSessionData(sessionData);
    this.clearStoredOrder();
  },

  getOrderedItemsByVendorId() {
    return GCNOrder.groupItemsByVendorId(this._orderedItems);
  },

  _reselectIntegrationForOrderedItems() {
    const orderedItemsByIntegrationId = {};
    const sharedOrderedItems = [];
    _.each(this._orderedItems, (orderedItem) => {
      const integrationIds = orderedItem.item.get('integrationIds') || [];

      // Locations that were once synced with a POS but then got unlinked (maybe for demo
      // or testing), will have items with an integrationId that won't match an existing
      // integration. We only need the right vendor in that case.
      if (!integrationIds.length || !gcn.location.hasPosI9n()) {
        const vendorId = orderedItem.item.get('vendorId');
        const vendor = gcn.menu.vendors.find((v) => {
          return v.id === vendorId.toString();
        });
        orderedItem.setVendor(vendor);
      } else if (integrationIds.length === 1) {
        const integrationId = integrationIds[0];
        // We need to enforce a vendor ID on all ordered items even if there is only one
        const vendor = gcn.menu.getVendorForIntegrationId(integrationId);
        orderedItem.setIntegrationId(integrationId, vendor);
        if (!orderedItemsByIntegrationId[integrationId]) {
          orderedItemsByIntegrationId[integrationId] = [];
        }
        orderedItemsByIntegrationId[integrationId].push(orderedItem);
      } else {
        sharedOrderedItems.push(orderedItem);
      }
    });

    // Pick the integration for each shared ordered item based on how many
    // ordered items there are in each integration.
    _.each(sharedOrderedItems, (orderedItem) => {
      const integrationIds = orderedItem.item.get('integrationIds');
      const sortedIntegrationIds = _.sortBy(integrationIds, (integrationId) => {
        return _.size(orderedItemsByIntegrationId[integrationId]);
      });
      const integrationId = _.last(sortedIntegrationIds);
      const vendor = gcn.menu.getVendorForIntegrationId(integrationId);
      orderedItem.setIntegrationId(integrationId, vendor);
    });

    // Uncomment to debug the logic of associating shared items with vendors
    // _.each(gcn.orderManager._orderedItems, function(oi) {
    //   console.log(oi.item.displayName(),
    //     oi.has('vendor') ? oi.get('vendor').name : 'no-vendor');
    // });
  },

  getCheckinUrl() {
    if (this._order.get('checkinToken')) {
      // orders api v1
      return `${gcn.flashResolvedHost}/${gcn.location.get('urlSlug')}/checkin/${this._order.get(
        'checkinToken',
      )}`;
    }
    if (this._order.get('isCheckinOrder')) {
      // orders api v2
      return `${gcn.flashResolvedHost}/${gcn.location.get(
        'urlSlug',
      )}/orders/${this.getOrderId()}/checkin`;
    }
    return null;
  },
});

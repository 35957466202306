import * as Sentry from '@sentry/browser';
import Backbone from 'backbone';
import React from 'react';
import ReactDOM from 'react-dom/client';
import _ from 'underscore';

import { ErrorCode, Log, Strings, Validators } from '@biteinc/common';
import type { SavedFulfillmentInfo } from '@biteinc/core-react';
import { TimeHelper } from '@biteinc/core-react';
import {
  CustomerIdentifier,
  CustomerIdentifierInputMethod,
  FulfillmentMethod,
  FulfillmentMethodHelper,
  LoyaltyInMenuMode,
  MenuCoverPromoLinkType,
  OrderClientEventName,
} from '@biteinc/enums';

import { LoyaltyAccountModal } from '~/components/loyalty/loyalty-account';
import { GcnCustomerIdentifierHelper } from '~/helpers';

import { LOYALTY_VIEW_EXIT } from '../../helpers/custom_events';
import { BackboneEvents } from './backbone-events';
import type { GcnError } from './gcn_bridge_interface';
import type { ApiError } from './gcn_maitred_request_manager';
import { localizeStr } from './localization/localization';
import Analytics from './utils/analytics';
import Errors from './utils/errors';
import getQueryParam from './utils/get_query_param';
import LocalStorage from './utils/local_storage';
import TrackingStepCounter from './utils/tracking_step_counter';
import GcnDeliveryAddressView from './views/gcn_delivery_address_view';
import { GCNFulfillmentMethodPickerView } from './views/gcn_fulfillment_method_picker_view';
import { GcnFutureOrderPickerView } from './views/gcn_future_order_picker_view';
import GcnFutureOrderTimeCalendarView from './views/gcn_future_order_time_calendar_view';
import { GCNGuestEmailEntryView } from './views/gcn_guest_email_entry_view';
import { GCNGuestNameEntryView } from './views/gcn_guest_name_entry_view';
import { GCNOutpostPickerView } from './views/gcn_outpost_picker_view';
import { GCNRoomNumberEntryView } from './views/gcn_room_number_entry_view';
import { GCNTableNumberEntryView } from './views/gcn_table_number_entry_view';
import { GCNTextWhenReadyView } from './views/gcn_text_when_ready_view';
import { GCNVehicleDescriptionEntryView } from './views/gcn_vehicle_description_entry_view';
import type GcnView from './views/gcn_view_ts';

type FlowDirection = 'back' | 'forward';
type Step = Readonly<{
  unset?: () => void;
  getView?: (flowDirection: FlowDirection) => Promise<GcnView>;
  showView?: () => void;
  isCustomerIdentifier?: boolean;
  className?: string;
}>;

interface CustomerIdentifierOptions {
  isTracker?: boolean;
  numDigits?: number;
  isCustomerIdentifier?: boolean;
  customerIdentifierIsOptional?: boolean;
}

export default class GcnOpeningSequenceManager extends Backbone.Model {
  private prevSteps: Step[] = [];

  private currentStep: Step;

  private customerIdentifierTasks: Step[];

  private readonly fulfillmentTrackingStepCounter: TrackingStepCounter;

  private ignoreAsapOption: boolean = false;

  private root: ReactDOM.Root;

  constructor(
    private readonly isStartupPhase: boolean = false,
    private successCallback?: Function,
  ) {
    super();

    this.fulfillmentTrackingStepCounter = new TrackingStepCounter(() => {
      gcn.orderManager.eventRepo.track(OrderClientEventName.FulfillmentOpeningStart);
      Analytics.track(Analytics.EventName.FulfillmentAtOpeningStart);
    });
  }

  private endFlow(err?: GcnError): void {
    Log.info('OSM endFlow');
    const successCallback = this.successCallback;
    this.successCallback = undefined;

    if (err) {
      // Sometimes no future order slots will be available,
      //  ex. Future dining option exists but next day slots is disabled
      // and the end of the day has passed. Gracefully show the closed screen.
      if (Errors.isNoFutureOrderSlotsError(err.code)) {
        if (gcn.location.getDiningOptions().length > 1) {
          gcn.orderManager.clearAllFulfillmentData();
          GcnOpeningSequenceManager.clearStoredFulfillmentData();
          gcn.menuView.showSimpleAlert(localizeStr(Strings.FUTURE_ORDER_NO_AVAILABLE_SLOTS), () => {
            gcn.goHome();
          });
          return;
        }
        gcn.menuView.showClosedScreen();
        return;
      }
      Log.error(err);
      Sentry.captureMessage(err.message, {
        extra: {
          error: err,
        },
      });
      // If we go home without an alert here we risk entering an infinite loop
      gcn.menuView.showSimpleAlert(localizeStr(Strings.GENERIC_ERROR), () => {
        gcn.goHome();
      });
      return;
    }

    Log.info('OSM endFlow - stepCount', this.fulfillmentTrackingStepCounter.getStepCount());
    if (this.fulfillmentTrackingStepCounter.getStepCount()) {
      gcn.orderManager.eventRepo.track(OrderClientEventName.FulfillmentOpeningEnd);
      Analytics.trackEvent({
        eventName: Analytics.EventName.FulfillmentAtOpeningComplete,
        eventData: {
          stepCount: this.fulfillmentTrackingStepCounter.getStepCount(),
        },
      });
    }

    GcnOpeningSequenceManager.persistFulfillmentInfo();

    if (this.isStartupPhase) {
      // restore orders for flash
      gcn.orderManager.restoreOrder();

      GcnOpeningSequenceManager.processActivatedMenuCoverPromo();
    }

    gcn.guestManager.fetchGuestRecommendations();

    if (successCallback) {
      successCallback();
    }
  }

  private static processActivatedMenuCoverPromo(): boolean {
    Log.info('OSM promo', gcn.activatedMenuCoverPromo);
    if (!gcn.activatedMenuCoverPromo) {
      return false;
    }

    const { linkType, linkTarget } = gcn.activatedMenuCoverPromo;
    switch (linkType) {
      case MenuCoverPromoLinkType.MenuItem: {
        gcn.activatedMenuCoverPromo = null;
        const menuItem = gcn.menu.getMenuItemWithId(linkTarget);
        const menuSection = menuItem && gcn.menu.getMenuSectionThatContainsItemId(linkTarget);
        if (menuSection) {
          gcn.notifyUserDidTapMenuItem(menuSection.id, menuItem, 'menu-cover-promo');
          return true;
        }
        break;
      }
      case MenuCoverPromoLinkType.MenuPage: {
        // Don't delete the activatedMenuCoverPromo in this case so we can keep it around
        // when an item from this page gets purchased.
        const currentMenuPageId = gcn.menuView.getMenuPageId();
        if (currentMenuPageId !== linkTarget) {
          gcn.menuView.setMenuPageId(linkTarget, false /* shouldTrack */, true /* scrollToTop */);
        }
        break;
      }
      case MenuCoverPromoLinkType.MenuSection: {
        // Don't delete the activatedMenuCoverPromo in this case so we can keep it around
        // when an item from this section gets purchased.
        const menuSection = gcn.menu.getMenuSectionWithId(linkTarget);
        const currentMenuPageId = gcn.menuView.getMenuPageId();
        const menuPage =
          menuSection && gcn.menu.structure.getMenuPageThatContainsSectionWithId(menuSection.id);
        if (menuSection && menuPage) {
          if (currentMenuPageId !== menuPage.id) {
            gcn.menuView.setMenuPageId(
              menuPage.id,
              false /* shouldTrack */,
              true /* scrollToTop */,
            );
          }
          gcn.menuView.scrollToSectionWithId(menuSection.id);
          return true;
        }
        break;
      }
      case MenuCoverPromoLinkType.NoLink:
        gcn.activatedMenuCoverPromo = null;
        break;
    }
    return false;
  }

  private static persistFulfillmentInfo(): void {
    Log.info('OSM persistFulfillmentInfo');
    const pickupAtIso = gcn.orderManager.getPickupAtIso();
    const deliveryAddress = gcn.orderManager.getDeliveryAddress();
    const outpost = gcn.orderManager.getOutpost();
    const roomNumber = gcn.orderManager.getRoomNumber();
    const validUntil = pickupAtIso
      ? TimeHelper.timestampFromIsoString(pickupAtIso)
      : Date.now() + TimeHelper.DAY;
    const needsToSelectOrderTime = !pickupAtIso && !gcn.orderManager.getIsAsapOrder();
    const fulfillmentInfo: SavedFulfillmentInfo = {
      fulfillmentMethod: gcn.orderManager.getFulfillmentMethod()!,
      validUntil,
      ...(pickupAtIso && { pickupAtIso }),
      ...(deliveryAddress && { deliveryAddress }),
      ...(roomNumber && { roomNumber }),
      ...(outpost && { outpostId: outpost._id }),
      ...(needsToSelectOrderTime && { needsToSelectOrderTime }),
    };
    LocalStorage.setJsonItem('fulfillmentInfo', fulfillmentInfo);
    /**
     * Store the delivery address separately so that if the guest enters the address and hits edit
     * we to make a quick change, they don't have to re-enter the entire thing
     */
    if (deliveryAddress) {
      LocalStorage.setJsonItem('deliveryAddress', deliveryAddress);
    }
  }

  static clearStoredFulfillmentData(): void {
    LocalStorage.removeItem('fulfillmentInfo');
    LocalStorage.removeItem('deliveryAddress');
  }

  private restoreFulfillmentInfo(): boolean {
    if (!window.isFlash || !this.isStartupPhase || !window.webEnabled) {
      return false;
    }

    const fulfillmentInfo = LocalStorage.getJsonItem<SavedFulfillmentInfo>('fulfillmentInfo');
    if (!fulfillmentInfo) {
      return false;
    }
    if (fulfillmentInfo.validUntil < Date.now()) {
      return false;
    }

    const diningOptions = gcn.location.getDiningOptions();
    const diningOption = diningOptions.find((opt) => {
      return opt.fulfillmentMethod === fulfillmentInfo.fulfillmentMethod;
    });
    // Bail if we can't find this dining option
    if (!diningOption) {
      return false;
    }

    // Bail if we are supposed to be doing delivery but there's no address
    if (
      FulfillmentMethodHelper.isDelivery(diningOption.fulfillmentMethod) &&
      !fulfillmentInfo.deliveryAddress
    ) {
      return false;
    }

    // Bail if we are supposed to be have an outpost
    if (FulfillmentMethodHelper.isAnOutpost(diningOption.fulfillmentMethod)) {
      if (!fulfillmentInfo.outpostId) {
        return false;
      }
      if (fulfillmentInfo.roomNumber) {
        const roomNumberValidationRegex = new RegExp(Validators.roomNumberRegexString());
        if (roomNumberValidationRegex.test(fulfillmentInfo.roomNumber)) {
          gcn.orderManager.setRoomNumber(fulfillmentInfo.roomNumber);
        }
      }
      const hasOutpost = diningOption.outposts?.some((outpost) => {
        return outpost._id === fulfillmentInfo.outpostId;
      });
      if (!hasOutpost) {
        return false;
      }
    }

    const isAsapOrder = !fulfillmentInfo.pickupAtIso && !fulfillmentInfo.needsToSelectOrderTime;
    // Bail if ASAP orders are disabled but we don't have a future time picked out
    if (isAsapOrder && diningOption.futureOrdersEnabled && !diningOption.asapOrdersEnabled) {
      return false;
    }
    // Bail if future orders are disabled but we have a future time picked out
    const isFutureOrderOrder = !!fulfillmentInfo.pickupAtIso;
    if (isFutureOrderOrder && !diningOption.futureOrdersEnabled) {
      return false;
    }

    gcn.orderManager.clearAllFulfillmentData();
    this.setFulfillmentMethod(diningOption.fulfillmentMethod);
    if (FulfillmentMethodHelper.isDelivery(diningOption.fulfillmentMethod)) {
      gcn.orderManager.setDeliveryAddress(fulfillmentInfo.deliveryAddress!);
    }
    if (isFutureOrderOrder) {
      gcn.orderManager.setPickupAtIso(fulfillmentInfo.pickupAtIso);
    } else if (isAsapOrder) {
      gcn.orderManager.setIsAsapOrder();
    }

    return true;
  }

  private shouldShowCustomerIdentifierViewForSimpleDiningOption(): {
    showCustomerIdentifier: boolean;
    skipSequence: boolean;
  } {
    const locationDiningOptions = gcn.location.get('diningOptions');
    const locationWithSimpleDiningOption =
      locationDiningOptions.length === 2 &&
      locationDiningOptions.some(
        (option) => option.fulfillmentMethod === FulfillmentMethod.KIOSK_DINE_IN,
      ) &&
      locationDiningOptions.some(
        (option) => option.fulfillmentMethod === FulfillmentMethod.KIOSK_TO_GO,
      );

    let sameCustomerIdentifierOptions = false;
    if (locationWithSimpleDiningOption) {
      // are the two fulfillment method with the same customerIdentifierOptions?
      const customerIdentifierOptions1 = gcn.location
        .getCustomerIdentifierOptions(locationDiningOptions[0].fulfillmentMethod)
        ?.filter((option) => {
          return GcnCustomerIdentifierHelper.shouldAskAtTheBeginning(option);
        })
        ?.map((option) => {
          return option.customerIdentifier;
        });
      const customerIdentifierOptions2 = gcn.location
        .getCustomerIdentifierOptions(locationDiningOptions[1].fulfillmentMethod)
        ?.filter((option) => {
          return GcnCustomerIdentifierHelper.shouldAskAtTheBeginning(option);
        })
        ?.map((option) => {
          return option.customerIdentifier;
        });

      if (
        !customerIdentifierOptions1 ||
        !customerIdentifierOptions2 ||
        customerIdentifierOptions1?.length !== customerIdentifierOptions2?.length
      ) {
        // if customerIdentifierOptions are different
        // we should show the customerIdentifier View
        return {
          showCustomerIdentifier: false,
          skipSequence: false,
        };
      }

      sameCustomerIdentifierOptions = customerIdentifierOptions1?.every(
        (option, index) => option === customerIdentifierOptions2[index],
      );
    }

    // don't show customerIdentifier View
    // if order manager has fulfillment method (selected from cart-topbar)
    // and the diningOption toggle is the simple one (dine-in and to-go only)
    // and the customerIdentifierOptions are different
    const showCustomerIdentifier =
      !!gcn.orderManager.getFulfillmentMethod() &&
      locationWithSimpleDiningOption &&
      !sameCustomerIdentifierOptions;

    // skip the sequence
    // if order manager has fulfillment method (selected from cart-topbar)
    // and the diningOption toggle is the simple one (dine-in and to-go only)
    // and the customerIdentifierOptions are the same
    const skipSequence =
      !!gcn.orderManager.getFulfillmentMethod() &&
      locationWithSimpleDiningOption &&
      sameCustomerIdentifierOptions;
    return {
      showCustomerIdentifier,
      skipSequence,
    };
  }

  private continueOpeningSequence(): void {
    if (this.isStartupPhase && this.restoreFulfillmentInfo()) {
      Log.info('OSM isStartupPhase & restoreFulfillmentInfo fulfillmentNextStep');
      void this.goToNextStep(this.getFulfillmentNextStep());
      return;
    }

    const diningOptions = gcn.location.getDiningOptions();
    const firstDiningOption = diningOptions[0];

    if (diningOptions.length === 1 && !firstDiningOption.forceGuestToSelectIfSingleDiningOption) {
      // Auto choose first if only one dining option and it isn't forced
      Log.info('OSM single dining option not forced', firstDiningOption);
      gcn.orderManager.clearAllFulfillmentData();
      this.setFulfillmentMethod(diningOptions[0].fulfillmentMethod);
      void this.goToNextStep(this.getFulfillmentNextStep());
      return;
    }

    const showCustomerIdentifierOptionView =
      this.shouldShowCustomerIdentifierViewForSimpleDiningOption();
    if (showCustomerIdentifierOptionView.showCustomerIdentifier) {
      Log.info('Dining Option toggled with different Customer Identifier Option');
      void this.goToNextStep(this.getFulfillmentNextStep());
      return;
    }

    if (showCustomerIdentifierOptionView.skipSequence) {
      Log.info('Dining Option toggled with same Customer Identifier Option');
      return;
    }

    void this.goToNextStep({
      getView: (flowDirection: FlowDirection) => {
        Log.info('OSM getting fulfillment view');
        return this.generateFulfillmentView(flowDirection);
      },
      unset: gcn.orderManager.clearFulfillmentMethod,
    });
  }

  start(): void {
    this.customerIdentifierTasks = [];

    Log.info('OSM.isStartupPhase', this.isStartupPhase);

    if (gcn.isRefunderShown()) {
      // The refunder is currently shown to the user, so this isn't a normal order session.
      // The user will not need to provide any identifying information, or choose the fulfillment
      // method, so we can stop the opening sequence.
      // Once the user closes the refunder, the session will restart.
      Log.info('OSM refunderIsShown');
      return;
    }

    if (gcn.location.showClosedScreen()) {
      // Location is closed
      // Do not allow the opening sequence to continue
      Log.info('OSM showClosedScreen');
      return;
    }

    if (getQueryParam('forcedMenuStructureId')) {
      Log.info('OSM forcedMenuStructureId');
      // Do not let user choose fulfillment time or option when forced-menu-structure is provided
      // because either of those could change the displayed structure
      gcn.orderManager.clearAllFulfillmentData();
      this.setUpCustomerIdentifiers();
      void this.goToNextStep(this.getNextCustomerIdentifierStep());
      return;
    }

    if (
      gcn.location.shouldShowLoyaltyInTheMenu() &&
      gcn.menu.settings.get('loyaltyInMenuMode') ===
        LoyaltyInMenuMode.PromptToLogInAtTheBeginning &&
      this.isStartupPhase
    ) {
      Log.info('OSM hasLoyaltyAtBeginningOfOrder');
      void this.goToNextStep(this.getLoyaltyModalStep());
      return;
    }

    this.continueOpeningSequence();
  }

  startFutureOrderPicker(ignoreAsapOption: boolean = false): void {
    gcn.orderManager.clearOrderTime();
    this.ignoreAsapOption = ignoreAsapOption;

    // called on order validation if there is a timeslot or throttling validation error thrown,
    // so we avoid creating customer identifier tasks again.
    this.customerIdentifierTasks = [];
    void this.goToNextStep(this.getFutureOrderStep());
  }

  private async goToNextStep(nextStep: Step): Promise<void> {
    // Dismiss previous modal if there was a Step
    if (this.currentStep) {
      gcn.menuView.dismissDiningOptionModalPopup();
    }

    if (nextStep.showView) {
      nextStep.showView();
      return;
    }

    // Customer identifiers return null on final Step to indicate end of flow
    // But possible reload remains needs to be checked
    if (!nextStep.getView) {
      this.possibleReload();
      // Announce "This is the main menu" when:
      // 1. Screen reader is active.
      // 2. Previous steps were shown but have now been manually dismissed.
      if (gcn.screenReaderIsActive && this.fulfillmentTrackingStepCounter.getStepCount() > 0) {
        gcn.showNativeToast('This is the main menu.');
      }
      return;
    }

    this.fulfillmentTrackingStepCounter.addStep();

    // Keep track of prev and current steps
    if (this.currentStep) {
      this.prevSteps.push(this.currentStep);
    }
    this.currentStep = nextStep;
    try {
      const view = await nextStep.getView('forward'); // views can be rendered asynchronously
      gcn.menuView.showDiningOptionModalPopup(view, nextStep.className);
    } catch (e) {
      this.endFlow(e);
    }
  }

  private async goToPrevStep(): Promise<void> {
    if (this.prevSteps.length) {
      gcn.menuView.dismissDiningOptionModalPopup();

      // Customer Identifiers don't render one after the other, so put back into the
      // remaining identifier steps when going back in sequence
      if (this.currentStep.isCustomerIdentifier) {
        this.customerIdentifierTasks.unshift(this.currentStep);
      }

      this.currentStep = this.prevSteps.pop()!;
      if (this.currentStep.unset) {
        Log.info('OSM unsetting');
        this.currentStep.unset();
      }

      const view = await this.currentStep.getView!('back');
      gcn.menuView.showDiningOptionModalPopup(view, this.currentStep.className);
    } else if (gcn.location.get('orgDomain')) {
      window.location.href = `https://${gcn.location.get('orgDomain')}`;
    } else {
      // TODO: remove when we have resolved goHome loop in flash
      Log.info('Go Home: OSM prev step');
      gcn.goHome();
    }
  }

  // STEP GETTERS: Return Step format for the different screens
  private getNextCustomerIdentifierStep(): Step {
    const nextCustomerIdentifierStep = this.customerIdentifierTasks.shift();
    if (nextCustomerIdentifierStep) {
      return nextCustomerIdentifierStep;
    }
    // done with customer identifiers
    return {};
  }

  private getOutpostStep(): Step {
    return {
      getView: this.generateOutpostView.bind(this),
      unset: gcn.orderManager.clearOutpost,
    };
  }

  private getLoyaltyModalStep(): Step {
    return {
      showView: () => {
        document.addEventListener(
          LOYALTY_VIEW_EXIT,
          () => {
            this.continueOpeningSequence();
          },
          { once: true },
        );
        this.root = ReactDOM.createRoot(document.getElementById('react-portal')!);
        this.root.render(<LoyaltyAccountModal withDispatch={true} />);
      },
    };
  }

  private getRoomNumberStep(): Step {
    return {
      getView: this.generateRoomNumberView.bind(this),
      unset: this.clearOrderTime,
      className: 'room-number-step-view',
    };
  }

  private getFutureOrderStep(): Step {
    return {
      getView: this.generateFutureOrderView.bind(this),
      unset: this.clearOrderTime,
      className: 'future-order-step-view',
    };
  }

  // NAVIGATION LOGIC: logic for navigating after the labelled Step
  private getFulfillmentNextStep(): Step {
    this.setUpCustomerIdentifiers();
    const fulfillmentMethod = gcn.orderManager.getFulfillmentMethod()!;
    Log.info('OSM getFulfillmentNextStep with fulfillmentMethod', fulfillmentMethod);
    if (FulfillmentMethodHelper.isAnOutpost(fulfillmentMethod)) {
      return this.getOutpostStep();
    }
    if (this.isStartupPhase && gcn.orderManager.getIsAsapOrder()) {
      return this.getNextCustomerIdentifierStep();
    }
    if (gcn.location.getDiningOption(fulfillmentMethod)!.futureOrdersEnabled) {
      return this.getFutureOrderStep();
    }
    gcn.orderManager.setIsAsapOrder();
    return this.getNextCustomerIdentifierStep();
  }

  private getOutpostNextStep(isRoomNumberRequired: boolean = false): Step {
    if (isRoomNumberRequired) {
      return this.getRoomNumberStep();
    }

    const fulfillmentMethod = gcn.orderManager.getFulfillmentMethod()!;
    if (this.isStartupPhase && gcn.orderManager.getIsAsapOrder()) {
      return this.getNextCustomerIdentifierStep();
    }
    if (gcn.location.getDiningOption(fulfillmentMethod)!.futureOrdersEnabled) {
      return this.getFutureOrderStep();
    }
    gcn.orderManager.setIsAsapOrder();
    return this.getNextCustomerIdentifierStep();
  }

  // FULFILLMENT METHOD PICKER
  private setFulfillmentMethod(fulfillmentMethod: FulfillmentMethod): void {
    Log.info('OSM setFulfillmentMethod', fulfillmentMethod);
    gcn.orderManager.setFulfillmentMethod(fulfillmentMethod);
  }

  private generateFulfillmentView(flowDirection: FlowDirection): Promise<GcnView> {
    // Don't show the cancel button if no fulfillment method is set
    // Don't show the cancel button if we came back to the picker
    const showCancelButton =
      flowDirection === 'forward' && !!gcn.orderManager.getFulfillmentMethod();

    const pickerView = new GCNFulfillmentMethodPickerView({ showCancelButton });

    const Events = BackboneEvents.GCNFulfillmentMethodPickerView;
    this.listenToOnce(pickerView, Events.DidCancelPickingFulfillmentMethod, () => {
      pickerView.destroy();

      Log.info('OSM Backed out of picking', pickerView._id);
      gcn.menuView.dismissDiningOptionModalPopup();
    });
    this.listenToOnce(pickerView, Events.DidPickFulfillmentMethod, (fulfillmentMethod) => {
      pickerView.destroy();

      // Now that something was picked we want to wipe away everything
      gcn.orderManager.clearAllFulfillmentData();

      Log.info('OSM did pick fulfillment method', fulfillmentMethod, pickerView._id);
      gcn.orderManager.setFulfillmentMethod(fulfillmentMethod);
      void this.goToNextStep(this.getFulfillmentNextStep());
    });

    return new Promise((resolve) => {
      resolve(pickerView);
    });
  }

  // OUTPOST LOCATION PICKER
  private generateOutpostView(): Promise<GcnView> {
    const outpostPickerView = new GCNOutpostPickerView();
    this.listenTo(outpostPickerView, BackboneEvents.GCNOutpostPickerView.BackedOut, () => {
      void this.goToPrevStep();
    });
    this.listenTo(
      outpostPickerView,
      BackboneEvents.GCNOutpostPickerView.DidPickOutpost,
      (outpost) => {
        gcn.orderManager.setOutpost(outpost);
        const isRoomNumberRequired = !!outpost?.requireRoomNumber;
        void this.goToNextStep(this.getOutpostNextStep(isRoomNumberRequired));
      },
    );
    return new Promise((resolve) => {
      resolve(outpostPickerView);
    });
  }

  // FUTURE ORDER DAY AND TIME PICKER
  private clearOrderTime(): void {
    gcn.orderManager.clearOrderTime();
  }

  private setPickupAtIso(timeSlot: string): void {
    gcn.orderManager.setPickupAtIso(timeSlot);
  }

  private generateRoomNumberView(): Promise<GcnView> {
    const roomNumberEntryView = new GCNRoomNumberEntryView();
    this.listenTo(roomNumberEntryView, BackboneEvents.GCNOutpostPickerView.BackedOut, () => {
      void this.goToPrevStep();
    });
    this.listenTo(
      roomNumberEntryView,
      BackboneEvents.GCNOutpostPickerView.DidEnterRoom,
      (roomNumber) => {
        gcn.orderManager.setRoomNumber(roomNumber);
        void this.goToNextStep(this.getOutpostNextStep());
      },
    );
    return new Promise((resolve) => {
      resolve(roomNumberEntryView);
    });
  }

  private generateFutureOrderView(): Promise<GcnView> {
    return new Promise((resolve, reject) => {
      const fulfillmentMethod = gcn.orderManager.getFulfillmentMethod()!;
      if (!gcn.location.getDiningOption(fulfillmentMethod)!.futureOrdersEnabled) {
        gcn.orderManager.setIsAsapOrder();
        void this.goToNextStep(this.getNextCustomerIdentifierStep());
        return;
      }

      const outpostId = gcn.orderManager.getOutpost()?._id;
      const roomNumber = gcn.orderManager.getRoomNumber();
      const leadTime = gcn.orderManager.getOrderLeadTime();
      gcn.maitred.fetchFutureOrderSlots(
        fulfillmentMethod,
        outpostId,
        leadTime,
        (err, slotsByDay) => {
          if (err) {
            reject(err);
            return;
          }
          if (!_.size(slotsByDay!)) {
            const fulfillmentDetails = roomNumber
              ? `${fulfillmentMethod} ${outpostId} ${roomNumber}`
              : `${fulfillmentMethod} ${outpostId}`;
            const err: ApiError = {
              ...new Error(`Empty future order slots at ${gcn.location.id} ${fulfillmentDetails}`),
              code: ErrorCode.FutureOrderNoSlotsAvailable,
            };
            reject(err);
            return;
          }

          // This could happen if we read the future order time from storage
          // Just need to validate it
          if (this.isStartupPhase) {
            const restoredPickupAtIso = gcn.orderManager.getPickupAtIso();
            const selectedFutureOrderTimeIsValid = _.any(Object.values(slotsByDay!), (slots) => {
              return _.any(slots, ({ timestamp, available }) => {
                return available && timestamp === restoredPickupAtIso;
              });
            });
            if (selectedFutureOrderTimeIsValid) {
              void this.goToNextStep(this.getNextCustomerIdentifierStep());
              return;
            }
          }

          const futureOrderPickerView = window.isFlash
            ? new GcnFutureOrderTimeCalendarView(
                slotsByDay,
                this.ignoreAsapOption,
                fulfillmentMethod,
              )
            : new GcnFutureOrderPickerView(slotsByDay, this.ignoreAsapOption, fulfillmentMethod);
          this.listenTo(
            futureOrderPickerView,
            BackboneEvents.GcnFutureOrderPickerView.DidPickAsapOrder,
            () => {
              gcn.orderManager.setIsAsapOrder();
              // Simply dismiss the view and proceed as normal.
              // Only if a future time was selected does special action need to be taken.
              void this.goToNextStep(this.getNextCustomerIdentifierStep());
            },
          );
          this.listenTo(
            futureOrderPickerView,
            BackboneEvents.GcnFutureOrderPickerView.DidPickFutureOrder,
            (timeSlot: string) => {
              this.setPickupAtIso(timeSlot);
              // Apply the designated time to the order.
              void this.goToNextStep(this.getNextCustomerIdentifierStep());
            },
          );
          this.listenTo(
            futureOrderPickerView,
            // only called from outpost future time picker selector
            BackboneEvents.GcnFutureOrderPickerView.BackedOut,
            () => {
              void this.goToPrevStep();
            },
          );
          resolve(futureOrderPickerView);
        },
      );
    });
  }

  // CUSTOMER IDENTIFIER TASKS
  private setUpCustomerIdentifiers(): void {
    this.customerIdentifierTasks = GcnOpeningSequenceManager.generateCustomerIdentifiersTasks(
      !!this.isStartupPhase,
      true,
      this.createCustomerIdentifierTask.bind(this),
    );
  }

  private createCustomerIdentifierTask(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    InputMethodViewClass: any,
    viewOptions: CustomerIdentifierOptions | null,
    successEvent: string,
    backedOutEvent: string,
    unsetter: () => void,
    isBeginning: boolean,
  ): Step {
    return {
      getView: (): Promise<GcnView> => {
        const inputMethodView = new InputMethodViewClass(viewOptions) as GcnView;
        this.listenTo(inputMethodView, successEvent, () => {
          void this.goToNextStep(this.getNextCustomerIdentifierStep());
        });
        this.listenTo(inputMethodView, backedOutEvent, () => {
          gcn.orderManager.eventRepo.track(
            isBeginning
              ? OrderClientEventName.FulfillmentOpeningCancel
              : OrderClientEventName.FulfillmentCheckoutCancel,
          );
          Analytics.track(
            isBeginning
              ? Analytics.EventName.FulfillmentAtOpeningBackOut
              : Analytics.EventName.FulfillmentAtCheckoutBackOut,
          );

          void this.goToPrevStep();
        });
        return new Promise<GcnView>((resolve) => {
          resolve(inputMethodView);
        });
      },
      unset: unsetter,
      isCustomerIdentifier: true,
    };
  }

  // Order type must be set before this is called. Set up all the customer identifier prompts for
  // the chosen order type request them in succession. If `isBeginning` is set, only prompt the
  // customer identifiers that are marked as "Ask at beginning".
  // If there are no customer identifiers that match the criteria, this returns immediately.
  static generateCustomerIdentifiersTasks(
    isStartupPhase: boolean,
    isBeginning: boolean,
    customerIdentifierTaskCreator: (
      InputMethodViewClass: typeof GcnView,
      viewOptions: CustomerIdentifierOptions | null,
      successEvent: string,
      backedOutEvent: string,
      unsetter: () => void,
      isBeginning: boolean,
    ) => Step,
  ): Step[] {
    const customerIdentifierTasks: Step[] = [];
    const customerIdentifierOptions = gcn.location.getCustomerIdentifierOptions(
      gcn.orderManager.getFulfillmentMethod()!,
    );
    customerIdentifierOptions?.forEach((customerIdentifierOption) => {
      Log.info('Customer Identifier Options', customerIdentifierOption);
      const askAtBeginning =
        GcnCustomerIdentifierHelper.shouldAskAtTheBeginning(customerIdentifierOption);
      if (isBeginning !== askAtBeginning) {
        return;
      }
      switch (customerIdentifierOption.customerIdentifier) {
        case CustomerIdentifier.GuestName:
          if (window.prefilledGuestName) {
            gcn.orderManager.setGuestName(window.prefilledGuestName);
            break;
          }
          /**
           * We collect this info in the checkout for web
           */
          if (window.isFlash) {
            return;
          }
          customerIdentifierTasks.push(
            customerIdentifierTaskCreator(
              GCNGuestNameEntryView,
              null,
              BackboneEvents.GCNGuestNameEntryView.DidEnterName,
              BackboneEvents.GCNGuestNameEntryView.BackedOut,
              gcn.orderManager.clearGuestName,
              isBeginning,
            ),
          );
          break;
        case CustomerIdentifier.TableNumber:
          {
            if (customerIdentifierOption.autoFillFromUrl) {
              /**
               * /:urlSlug/:tableNumber?
               */
              const [, , prefilledTableNumber] = window.location.pathname.split('/');
              const regex = new RegExp(`^[\\d]{1,${customerIdentifierOption.numberOfDigits}}$`);

              if (regex.test(prefilledTableNumber)) {
                gcn.orderManager.setTableNumber(prefilledTableNumber);
                break;
              }
            }
            const trackerInputMethod = CustomerIdentifierInputMethod.TableTrackerPicker;
            const isTracker = customerIdentifierOption.inputMethod === trackerInputMethod;
            const numDigits = customerIdentifierOption.numberOfDigits;
            customerIdentifierTasks.push(
              customerIdentifierTaskCreator(
                GCNTableNumberEntryView,
                { isTracker, numDigits },
                BackboneEvents.GCNTableNumberEntryView.DidEnterTableNumber,
                BackboneEvents.GCNTableNumberEntryView.BackedOut,
                gcn.orderManager.clearTableNumber,
                isBeginning,
              ),
            );
          }
          break;
        case CustomerIdentifier.VehicleDescription:
          /**
           * We collect this info in the checkout for web
           */
          if (window.isFlash) {
            return;
          }
          customerIdentifierTasks.push(
            customerIdentifierTaskCreator(
              GCNVehicleDescriptionEntryView,
              null,
              BackboneEvents.GCNVehicleDescriptionEntryView.DidEnterVehicleDescription,
              BackboneEvents.GCNVehicleDescriptionEntryView.BackedOut,
              gcn.orderManager.clearGuestVehicleDescription,
              isBeginning,
            ),
          );
          break;
        case CustomerIdentifier.Address:
          // Don't ask for address if we already have it from a previous session
          if (isStartupPhase && isBeginning && gcn.orderManager.getDeliveryAddress()) {
            return;
          }

          customerIdentifierTasks.push(
            customerIdentifierTaskCreator(
              GcnDeliveryAddressView,
              null,
              GcnDeliveryAddressView.Events.DidEnterDeliveryAddress,
              GcnDeliveryAddressView.Events.BackedOut,
              gcn.orderManager.clearDeliveryAddress,
              isBeginning,
            ),
          );
          break;
        case CustomerIdentifier.PhoneNumber:
          if (isStartupPhase && isBeginning && gcn.orderManager.getGuestPhoneNumber()) {
            return;
          }

          /**
           * We collect this info in the checkout for web
           */
          if (window.isFlash) {
            return;
          }

          customerIdentifierTasks.push(
            customerIdentifierTaskCreator(
              GCNTextWhenReadyView,
              {
                isCustomerIdentifier: true,
                customerIdentifierIsOptional:
                  GcnCustomerIdentifierHelper.identifierIsOptional(customerIdentifierOption),
              },
              BackboneEvents.GCNTextWhenReadyView.CompletedFlow,
              BackboneEvents.GCNTextWhenReadyView.BackedOut,
              gcn.orderManager.clearGuestPhoneNumber,
              isBeginning,
            ),
          );
          break;
        case CustomerIdentifier.GuestEmail:
          /**
           * We collect this info in the checkout for web
           */
          if (window.isFlash) {
            return;
          }

          customerIdentifierTasks.push(
            customerIdentifierTaskCreator(
              GCNGuestEmailEntryView,
              {
                customerIdentifierIsOptional:
                  GcnCustomerIdentifierHelper.identifierIsOptional(customerIdentifierOption),
              },
              BackboneEvents.GCNTextWhenReadyView.CompletedFlow,
              BackboneEvents.GCNTextWhenReadyView.BackedOut,
              gcn.orderManager.clearGuestEmail,
              isBeginning,
            ),
          );
          break;
      }
    });
    Log.info('customerIdentifierTasks', customerIdentifierTasks);
    return customerIdentifierTasks;
  }

  private possibleReload(): void {
    const fulfillmentMethod = gcn.orderManager.getFulfillmentMethod()!;
    const timeSlot = gcn.orderManager.getPickupAtIso();

    const currentMenuFulfillmentMethods = gcn.menu.structure.get('fulfillmentMethods');
    if (
      (!timeSlot && currentMenuFulfillmentMethods.includes(fulfillmentMethod)) ||
      getQueryParam('forcedMenuStructureId')
    ) {
      this.endFlow();
    } else {
      this.fulfillmentTrackingStepCounter.addStep();
      this.reloadMenuForNewFulfillmentMethodOrTime(fulfillmentMethod, timeSlot);
    }
  }

  private reloadMenuForNewFulfillmentMethodOrTime(
    fulfillmentMethod: FulfillmentMethod,
    timeSlot: string | null,
  ): void {
    Log.info('OSM reloadMenuForNewFulfillmentMethodOrTime', timeSlot);
    const time = timeSlot ? new Date(timeSlot).valueOf() : 0;
    const outpostId = gcn.orderManager.getOutpost()?._id;

    gcn.menuView.showSpinner(localizeStr(Strings.LOADING_MENU));
    // this should never be called if a forced-menu-structure was provided, so assume undefined
    gcn.maitred.fetchMenu(time, fulfillmentMethod, outpostId, undefined, (err, response) => {
      gcn.menuView.dismissSpinner();
      if (err) {
        gcn.menuView.showSimpleAlert(localizeStr(Strings.FUTURE_ORDER_MENU_FETCH_ERROR), () => {
          this.endFlow();
          const openAgain = new GcnOpeningSequenceManager();
          openAgain.start();
        });
        return;
      }

      gcn.setLocation(response.location);
      gcn.setMenu(response.menu);
      gcn.orderManager.removeOutdatedOrderedItemsAndShowError();
      this.endFlow();
    });
  }
}

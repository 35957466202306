import async from 'async';
import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom/client';
import _ from 'underscore';

import { ErrorCode, Log, Strings } from '@biteinc/common';
import { TimeHelper } from '@biteinc/core-react';
import {
  LocationPaymentType,
  LocationTipsLevel,
  LoyaltyAuthEntryMethod,
  OrderClientEventName,
} from '@biteinc/enums';

import { BackboneEvents } from '~/app/js/backbone-events';
import { localizeStr, str } from '~/app/js/localization/localization';
import { LoyaltyAccountModal } from '~/components/loyalty/loyalty-account';
import GcnGiftCardHelper from '~/helpers/gcn_gift_card_helper';
import StoredValueHelper from '~/helpers/stored_value_helper';

import { useStore } from '../../../../stores';
import GcnHardwareManager from '../../gcn_hardware_manager';
import GcnHelper from '../../gcn_helper';
import GcnHtml from '../../gcn_html';
import GcnOpeningSequenceManager from '../../gcn_opening_sequence_manager';
import { GCNRouterHelper } from '../../gcn_router_helper';
import GcnKiosk from '../../models/gcn_kiosk';
import { GCNOrder } from '../../models/gcn_order';
import Analytics from '../../utils/analytics';
import Errors from '../../utils/errors';
import { GCNAlertView } from '../gcn_alert_view';
import { GCNCouponView } from '../gcn_coupon_view';
import { GCNGiftCardTerminalView } from '../gcn_gift_card_terminal_view';
import { GCNLoyaltyBarcodeView } from '../gcn_loyalty_barcode_view';
import { GCNLoyaltyCardNumberView } from '../gcn_loyalty_card_number_view';
import { GCNLoyaltyManualAuthView } from '../gcn_loyalty_manual_auth_view';
import { GcnLoyaltyPaneView } from '../gcn_loyalty_pane_view';
import { GCNLoyaltyPhoneNumberView } from '../gcn_loyalty_phone_number_view';
import { GCNOrderedItemView } from '../gcn_ordered_item_view';
import { GCNSimpleLoyaltySignupView } from '../gcn_simple_loyalty_signup_view';
import { GcnStoredValuePaneView } from '../gcn_stored_value_pane_view';
import { GCNTipView } from '../gcn_tip_view';
import { GCNFullScreenFlowStepView } from './gcn_full_screen_flow_step_view';

export const GCNOrderSummaryStepView = GCNFullScreenFlowStepView.extend({
  className: 'full-screen-flow-step-view order-summary',
  stepType: GCNFullScreenFlowStepView.StepType.OrderSummary,
  _vendorHeaderTemplate: _.template(
    // prettier-ignore
    '<div class="vendor-header">' +
      '<div class="title"><%= title %></div>' +
      '<div class="name"><%= name %></div>' +
    '</div>',
  ),

  _noteWithIconTemplate: _.template(
    // prettier-ignore
    '<div class="note-with-icon">' +
      '<div class="contents">' +
        '<div class="icon"><%= iconText %></div>' +
        '<div class="label"><%= label %></div>' +
      '</div>' +
    '</div>',
  ),

  initialize(...args) {
    GCNFullScreenFlowStepView.prototype.initialize.apply(this, args);

    this.listenTo(
      gcn.loyaltyManager,
      BackboneEvents.GCNLoyaltyManager.DidUpdateAuthGuest,
      this._fetchRewards,
    );
    this.listenTo(
      gcn.loyaltyManager,
      BackboneEvents.GCNLoyaltyManager.DidApplyReward,
      this._appliedReward,
    );
    this.listenTo(
      gcn.loyaltyManager,
      BackboneEvents.GCNLoyaltyManager.DidFailApplyReward,
      this._applyRewardDidFail,
    );
    this.listenTo(
      gcn.loyaltyManager,
      BackboneEvents.GCNLoyaltyManager.DidRemoveReward,
      this._removedReward,
    );
    this.listenTo(
      gcn.loyaltyManager,
      BackboneEvents.GCNLoyaltyManager.DidFailRemoveReward,
      this._removeRewardDidFail,
    );
    this.listenTo(
      gcn.loyaltyManager,
      BackboneEvents.GCNLoyaltyManager.DidFetchRewards,
      this._didFetchRewards,
    );
    this.listenTo(
      gcn.loyaltyManager,
      BackboneEvents.GCNLoyaltyManager.DidFailFetchRewards,
      this._didFailFetchRewards,
    );
    this.listenTo(
      gcn.orderManager,
      BackboneEvents.GCNOrderManager.TransactionsDidSet,
      this._updateGiftCardComponents,
    );
    this._restartInactivityTimer();
  },

  // Business logic initialization that should occur once rendering is complete.
  _postRenderInit() {
    if (gcn.orderManager.orderNeedsCreation() && !this._createRequested) {
      gcn.orderManager.createOrder();
      this._createRequested = true;
      this.listenTo(
        gcn.orderManager,
        BackboneEvents.GCNOrderManager.OrderDidValidate,
        this._orderDidValidate,
      );
      this.listenTo(
        gcn.orderManager,
        BackboneEvents.GCNOrderManager.OrderDidValidateWithChanges,
        this._orderDidValidateWithChanges,
      );
      this.listenTo(
        gcn.orderManager,
        BackboneEvents.GCNOrderManager.OrderDidFailValidation,
        this._orderDidFailValidation,
      );
      this.listenToOnce(
        gcn.orderManager,
        BackboneEvents.GCNOrderManager.OrderDidFailCreation,
        this._orderDidFailCreation,
      );
      this.listenTo(
        gcn.orderManager,
        BackboneEvents.GCNOrderManager.TipDidValidate,
        this._tipDidValidate,
      );
      this.listenTo(
        gcn.orderManager,
        BackboneEvents.GCNOrderManager.CouponDidValidate,
        this._couponDidValidate,
      );
      this.listenTo(
        gcn.orderManager,
        BackboneEvents.GCNOrderManager.CouponDidFailValidation,
        this._couponDidFailValidation,
      );
      this.listenTo(
        gcn.orderManager,
        BackboneEvents.GCNOrderManager.OrderDidFailRevalidation,
        this._orderDidFailRevalidation,
      );
    }
  },

  restartPartialTenderTimeout() {
    if (!this._partialTenderTimeout) {
      return;
    }
    this.startPartialTenderTimeout();
  },

  startPartialTenderTimeout() {
    this.cancelPartialTenderTimeout();

    this._partialTenderTimeout = setTimeout(() => {
      const alertText = localizeStr(Strings.PARTIAL_TENDER_TIMEOUT, [], function (string) {
        return string.split('\n').join('<br />');
      });
      const warningView = new GCNAlertView({
        text: alertText,
        okCallback() {
          gcn.menuView.dismissModalPopup();
        },
      });
      gcn.menuView.showModalPopup(warningView);
    }, 2 * TimeHelper.MINUTE);
  },

  cancelPartialTenderTimeout() {
    if (this._partialTenderTimeout) {
      clearTimeout(this._partialTenderTimeout);
      this._partialTenderTimeout = null;
    }
  },

  // TODO: Loyalty/Order Managers and Controller should handle all this stuff.
  handleScannerData(scannerData) {
    Log.info('GCNOrderSummaryStepView.handleScannerData', scannerData);

    // If not validated, or either scan source is already processed, ignore scans.
    if (
      !gcn.orderManager.orderHasBeenValidated() ||
      gcn.loyaltyManager.hasLoyaltyAuthData() ||
      gcn.orderManager.getCouponCode()
    ) {
      return;
    }

    // If coupon button exists and is not disabled/hidden, we set based on scan.
    // Loyalty rewards are handled in loyalty barcode view.
    if (this.$couponsButton && !this.$couponsButton.is(':hidden')) {
      gcn.menuView.dismissModalPopup();
      gcn.menuView.dismissStablePopup();
      gcn.orderManager.setCouponCode(scannerData);
      gcn.menuView.showSpinner(localizeStr(Strings.LOOKUP_COUPON));
    }
  },

  _appliedReward() {
    Analytics.track(Analytics.EventName.LoyaltyRedeemSuccess);
    this._rerender();

    if (gcn.loyaltyManager.canAutoApplyReward() && gcn.orderManager.getGrandTotal() === 0) {
      this.parent.nextStep(this);
    }
  },

  _applyRewardDidFail(err) {
    if (Errors.isApplyRewardError(err?.code) && err?.message) {
      const message = err.message;
      Analytics.trackEvent({
        eventName: Analytics.EventName.LoyaltyRedeemError,
        eventData: { message },
      });
      gcn.menuView.showSimpleAlert(message);
    } else {
      const message = Errors.stringFromErrorCode(err?.code, Strings.LOYALTY_APPLY_REWARD_FAILED);
      Analytics.trackEvent({
        eventName: Analytics.EventName.LoyaltyRedeemError,
        eventData: { message },
      });
      gcn.menuView.showSimpleAlert(message);
    }
  },

  _removedReward() {
    this._rerender();
  },

  _removeRewardDidFail() {
    gcn.menuView.showSimpleAlert('Failed to remove reward. Please try again.');
  },

  _fetchRewards() {
    gcn.loyaltyManager.fetchRewards();
    this._updateRewardsButton();
  },

  _didFetchRewards() {
    if (gcn.location.get('revalidateAfterFetchingRewards')) {
      gcn.menuView.showSpinner(localizeStr(Strings.SENDING_ORDER_VALIDATION));
      gcn.orderManager.validateOrder(gcn.orderManager.getOrder()?.id);
    }

    // Loyalty state can change the availability of other features.
    this._renderControls();
    this._renderNotices();
    this._renderLoyaltyPane();
    this._renderTotals();
  },

  _didFailFetchRewards(err) {
    const fetchErrors = [
      ErrorCode.LoyaltyNoMatchingUser,
      ErrorCode.LoyaltyRewardCannotBeApplied,
      ErrorCode.LoyaltyNonUniqueIdentifier,
    ];
    const fallbackString = Strings.LOYALTY_FETCH_REWARDS_FAILED;

    // For these errors prefer to show the error message if possible.
    if (err && _.contains(fetchErrors, err.code)) {
      if (err.code === ErrorCode.LoyaltyNonUniqueIdentifier) {
        gcn.menuView.showSimpleAlert(localizeStr(Strings.LOYALTY_FETCH_REWARDS_FAILED_NON_UNIQUE));
      } else if (err.message) {
        gcn.menuView.showSimpleAlert(err.message);
      } else {
        gcn.menuView.showSimpleAlert(Errors.stringFromErrorCode(err.code, fallbackString));
      }
    } else {
      gcn.menuView.showSimpleAlert(localizeStr(fallbackString));
    }

    gcn.loyaltyManager.clearLoyaltyAuthData();
    this._updateRewardsButton();
  },

  destroy(...args) {
    // We've chosen to clear the loyalty state entirely when a guest exits the checkout flow.
    // This is to reduce the window in which the guest auth is exposed for use. We also believe
    // that the scenario where a guest auths and then changes their mind about their order is
    // uncommon.
    if (!gcn.loyaltyManager.hadLoyaltyGuestNoOrder()) {
      gcn.loyaltyManager.clear();
    }

    this.cancelPartialTenderTimeout();

    GCNFullScreenFlowStepView.prototype.destroy.apply(this, args);
    this._clearInactivityTimer();

    const orderedItems = gcn.orderManager.getOrderedItems();

    // usual case where freeItemPromotions is not set we default to an empty array
    const freeItemPromotions = gcn.location.get('freeItemPromotions') || [];
    const freeItemPromotionMenuItemIds = freeItemPromotions.map((promotion) => {
      return promotion.menuItemId;
    });

    // we check that both `orderedItem` and `freeItemPromotionMenuItemIds` are populated as if one of them
    // are populated, there is no point in running the following;
    // either there is `orderedItem` but no `freeItemPromotionMenuItemIds`
    // or no `orderedItems` but has `freeItemPromotionMenuItemIds`
    if (orderedItems.length && freeItemPromotionMenuItemIds.length) {
      // there is orderedItems in the orderManager  we traverse through it
      // and remove all freeItemPromotions we find in it

      _.each(orderedItems, (orderedItem) => {
        // we are removing all orderedItem that shares the same freeItemPromotionMenuItemIds
        if (freeItemPromotionMenuItemIds.indexOf(orderedItem.item.id) !== -1) {
          gcn.orderManager.removeFromOrder(orderedItem, 'auto-add-promo');
          useStore.getState().cart.fetch();
        }
      });
    }
  },

  _restartInactivityTimer() {
    if (gcn.screenReaderIsActive) {
      return;
    }

    this._clearInactivityTimer();

    // In case we fail to clear the timeout, use this to ensure it doesn't affect a future session
    const expectedClientOrderId = gcn.orderManager.getClientOrderId();

    this._inactivityTimer = setTimeout(
      () => {
        if (expectedClientOrderId !== gcn.orderManager.getClientOrderId()) {
          // If the client IDs don't match, then this timeout is from a previous session
          return;
        }

        const alertText = localizeStr(Strings.NEED_MORE_TIME, [], function (string) {
          return string.split('\n').join('<br />');
        });
        const confirmView = new GCNAlertView({
          text: alertText,
          okCallback: () => {
            gcn.menuView.dismissModalPopup();
            this._checkPriceChangeAndPopup();
            this._restartInactivityTimer();
          },
          okText: localizeStr(Strings.YES),
          cancelCallback: () => {
            gcn.orderManager.eventRepo.trackSessionAbandon('order-summary-inactivity');
            Analytics.trackEvent({
              eventName: Analytics.EventName.MenuAbandoned,
              eventData: {
                reason: 'order-summary-inactivity',
              },
            });
            gcn.goHome();
          },
          cancelText: localizeStr(Strings.EXIT),
          timeout: 15 * 1000, // 15 seconds
        });
        gcn.menuView.showModalPopup(confirmView);
      },
      2 * 60 * 1000,
    );
  },

  _clearInactivityTimer() {
    clearTimeout(this._inactivityTimer);
    this._inactivityTimer = 0;
  },

  _cancelCallback() {
    gcn.orderManager.revertPriceChanges();
    gcn.menuView.dismissStablePopup();
    gcn.menuView.dismissModalPopup();
    this.parent.prevStep();
  },

  _checkPriceChangeAndPopup() {
    if (!gcn.orderManager.hasUnconfirmedPriceChanges()) {
      return;
    }
    if (gcn.orderManager.shouldShowSubtotalChangeAlert()) {
      const confirmCorrectionsView = new GCNAlertView({
        content: gcn.orderManager.getSubtotalChangeContent(),
        okCallback: () => {
          gcn.menuView.dismissModalPopup();
          this._rerender();
          gcn.orderManager.confirmPriceChanges();
        },
        cancelCallback: () => {
          this._cancelCallback();
        },
      });
      gcn.menuView.showModalPopup(confirmCorrectionsView);
    } else {
      this._rerender();
    }
  },

  _shouldForceShowTipView() {
    return (
      gcn.location.get('tipsLevel') === LocationTipsLevel.Forced &&
      !this._hasShownTipView &&
      !gcn.orderManager.payingAtCashier() && // If the guest is paying with cash it doesn't make sense for them to tip on the kiosk
      !gcn.screenReaderIsActive
    );
  },

  _orderDidValidate() {
    gcn.menuView.dismissSpinner();
    gcn.orderManager.eventRepo.track(OrderClientEventName.OrderSummaryStepOrderDidValidate);
    this._renderTotals();

    this._renderTipButtonText();

    if (gcn.loyaltyManager.hadLoyaltyGuestNoOrder()) {
      async.waterfall([
        (cb) => {
          // ensure we have latest rewards given order contents
          gcn.loyaltyManager.fetchRewards(cb);
        },
        (cb) => {
          const { rewards, unapplicableRewards } =
            gcn.loyaltyManager.getFilteredPreSelectedRewards();

          cb(undefined, { rewards, unapplicableRewards });
        },
        ({ rewards, unapplicableRewards }, cbOuter) => {
          return async.waterfall([
            (cbInner) => {
              if (!unapplicableRewards.length) {
                cbInner();
                return;
              }

              // If we have unapplicable rewards, show an alert so the uesr knows,
              // and remove them from the pre-selected rewards list.
              let unapplicableRewardNames = unapplicableRewards
                .map((reward) => {
                  return reward.displayName(true);
                })
                .join(', ');

              // truncate if too long
              if (unapplicableRewardNames.length >= 20) {
                unapplicableRewardNames = `${unapplicableRewardNames.substring(0, 20)}...`;
              }

              gcn.menuView.showSimpleAlert(
                str(Strings.LOYALTY_APPLY_REWARD_FAILED_SPECIFIC, [unapplicableRewardNames]),
                cbInner,
              );
            },
            ...rewards.map((reward) => {
              return (cb) => {
                gcn.loyaltyManager.applyRewardAsync(reward, (_err) => {
                  if (_err) {
                    // fail silently, in order to attempt applying remaining rewards.
                    // error message will be shown to guest from applyRewardAsync
                    gcn.loyaltyManager.removePreSelectedReward(reward);
                  }
                  cb();
                });
              };
            }),
            () => {
              cbOuter();
            },
          ]);
        },
        (cb) => {
          if (this._shouldForceShowTipView()) {
            this._showTipView();
          } else {
            this._enableControlButtons(true);
          }
          cb();
        },
      ]);
      return;
    }

    if (this._shouldForceShowTipView()) {
      this._showTipView();
    } else {
      this._enableControlButtons(true);
    }
  },

  _orderDidValidateWithChanges() {
    gcn.menuView.dismissSpinner();
    this._checkPriceChangeAndPopup();
  },

  _tipDidValidate() {
    gcn.orderManager.eventRepo.track(OrderClientEventName.OrderSummaryStepTipDidValidate);
    gcn.menuView.dismissSpinner();
    this._orderDidValidate();
    this._renderControls();
  },

  _couponDidValidate() {
    gcn.menuView.dismissSpinner();
    this._orderDidValidate();
    this._renderControls();
    this._renderNotices();
    this._renderLoyaltyPane();
  },

  _couponDidFailValidation(errorText) {
    gcn.menuView.dismissSpinner();
    gcn.menuView.showSimpleAlert(errorText || localizeStr(Strings.ERROR_COUPON_CONFIRMATION));
  },

  _orderDidFailRevalidation() {
    // protect against being unable to revalidate the order for new tax values
    gcn.menuView.dismissSpinner();
    this._cancelCallback();
  },

  _enableControlButtons(enable) {
    // Do not enable any buttons if we need to force-show the tip view
    const phiEnable = enable && !this._shouldForceShowTipView();
    gcn.orderManager.eventRepo.track(OrderClientEventName.OrderSummaryStepEnableControlButtons, {
      enable: phiEnable,
    });
    this.$prevButton?.toggleClass('disabled', !phiEnable);
    this.$giftCardButton?.toggleClass('disabled', !phiEnable);
    this.$compCardButton?.toggleClass('disabled', !phiEnable);
    this.$tipButton?.toggleClass('disabled', !phiEnable);
    this.$couponsButton?.toggleClass('disabled', !phiEnable);
    this.$rewardsButton?.toggleClass('disabled', !phiEnable);
    this.$redemptionCodeButton?.toggleClass('disabled', !phiEnable);
    this.$nextButton?.toggleClass('disabled', !phiEnable);
    this.$signupButton?.toggleClass('disabled', !phiEnable);
  },

  _orderDidFailCreationOrValidation(err) {
    // If we've been destroyed already, possibly by pressing cancel or
    // navigating away by the scrolling nav view, we don't have to confirm.
    if (!this.parent) {
      return;
    }

    let errorMessage = Errors.stringFromErrorCode(err.code, Strings.GENERIC_ERROR);

    // overwrite for the validation error if it's a POS validation error
    // or if it's a max alcoholic beverage limit exceeded error
    // or if it's an order total below min error because we save the threshold amount in the message
    if (
      Errors.isPosValidationCode(err.code) ||
      err.code === ErrorCode.MaxAlcoholicBeverageLimitPerOrderExceeded ||
      err.code === ErrorCode.OrderTotalBelowMin
    ) {
      errorMessage = err.message;
    }

    errorMessage = errorMessage.split('\n').join('<br />');
    const confirmView = new GCNAlertView({
      text: errorMessage,
      okCallback: () => {
        gcn.menuView.dismissModalPopup();
        this.parent.exitCheckoutFlow();
        GCNRouterHelper.navToMenu();

        // restart opening sequence
        if (Errors.isBadTimeSlotOrThrottlingError(err?.code)) {
          const openingSeqManager = new GcnOpeningSequenceManager();
          const selectedFulfillmentMethod = gcn.orderManager.getFulfillmentMethod();
          if (gcn.location.getDiningOption(selectedFulfillmentMethod).futureOrdersEnabled) {
            // If this was an asap option that's getting throttled, don't offer the asap option again
            const ignoreAsapOption = !gcn.orderManager.getPickupAtIso();
            openingSeqManager.startFutureOrderPicker(ignoreAsapOption);
          } else {
            openingSeqManager.start();
          }
        }
      },
    });
    gcn.menuView.showModalPopup(confirmView);
  },

  _orderDidFailCreation(err) {
    Log.warn('_orderDidFailCreation', err, this.parent);
    this._orderDidFailCreationOrValidation(err);
  },

  _orderDidFailValidation(err) {
    Log.warn('_orderDidFailValidation', err, this.parent);
    this._orderDidFailCreationOrValidation(err);
  },

  onEnterStep() {
    // Handle coupon and rewards scanning.
    gcn.pushScannerHandler(this);
  },

  onShowTransitionEnd() {
    this.parent.summaryStepTransitionEnd();
  },

  onLeaveStep() {
    gcn.popScannerHandler();
  },

  confirmStep() {
    this._clearInactivityTimer();
    return true;
  },

  _renderTotals() {
    this.restartPartialTenderTimeout();
    this.$stepBody
      .find('.tax')
      .html(
        `${localizeStr(Strings.TAXES)}: ${GcnHtml.htmlFromPrice(gcn.orderManager.getTaxTotal())}`,
      );
    this.$stepBody
      .find('.grand-total')
      .html(
        `${localizeStr(Strings.GRAND_TOTAL)}: ${GcnHtml.htmlFromPrice(
          gcn.orderManager.getGrandTotal(),
        )}`,
      );

    this._renderTipTotal();
    this._renderTotalSubscript();
    this._renderServiceChargeTotal();
    this._renderDiscountTotal();
    this._renderGiftCardTotal();
    this._renderLoyaltyPaymentTotal();
    this._renderReducedSubtotal();
    // Clear any loading UI.
    this.$stepBody.find('.tax').css('margin-right', 'initial');
    this.$stepBody.find('.grand-total').css('margin-right', 'initial');
  },

  // Renders the buttons/notices used to access/explain various summary screen features.
  // Can be called whenever state of the buttons needs to be refreshed due to state change.
  _renderControls() {
    this.restartPartialTenderTimeout();
    this.$stepBody.find('.controls').empty();
    this._renderTippingControls();
    this._renderCouponControls();
    this._renderLoyaltySignUpControls();

    // Since you can pay with a gift card after redeeming loyalty it should be rendered after
    this._renderLoyaltyControls();
    this._renderCompCardControls();
    this._renderGiftCardControls();
  },

  _updateGiftCardComponents() {
    if (this._hasStoredValueTransactions() && gcn.orderManager.getGrandTotal() !== 0) {
      this.startPartialTenderTimeout();
    } else {
      this.cancelPartialTenderTimeout();
    }
    this._renderStoredValuePane();
    this._renderControls();
    this._renderTotals();
    this._updateNextButton();
  },

  _readStoredValueFromTerminal(callback) {
    const giftCardTerminalView = new GCNGiftCardTerminalView({
      grandTotal: gcn.orderManager.getGrandTotal(),
      callback: () => {
        gcn.menuView.dismissModalPopup();
        callback();
      },
    });
    gcn.menuView.showModalPopup(giftCardTerminalView);
  },

  _createGiftCardButton() {
    this.$giftCardButton = $(
      `<div role="button" class="button change-gift-card">${localizeStr(
        Strings.USE_GIFT_CARD,
      )}</div>`,
    );
    this.$giftCardButton.onButtonTapOrHold('ossvStoredValue', () => {
      if (this.$giftCardButton.hasClass('disabled')) {
        return;
      }
      if (!gcn.location.useOrdersApiV2() && this._hasStoredValueTransactions()) {
        // orders api v1 can only have 1 gift card transaction
        return;
      }
      if (gcn.orderManager.getGrandTotal() === 0) {
        return;
      }
      this._restartInactivityTimer();

      const callback = () => {
        // Update all UI that corresponds to gift cards.
        this._renderControls();
        this._renderTotals();
        this._updateNextButton();

        // If fully paid and the gift card method allows completion of payment, proceed to next
        // step.
        if (
          StoredValueHelper.requiresNativeForStoredValue() &&
          gcn.orderManager.getGrandTotal() === 0
        ) {
          this.parent.nextStep(this);
        }
      };

      if (StoredValueHelper.requiresNativeForStoredValue()) {
        if (!GcnHardwareManager.hasReceiptPrinter()) {
          GcnGiftCardHelper.ensureGuestEmail(() => {
            gcn.menuView.dismissModalPopup();
            this._readStoredValueFromTerminal(callback);
          });
          return;
        }
        this._readStoredValueFromTerminal(callback);
      } else {
        GcnGiftCardHelper.showGiftCardView('stored-value', callback);
      }
    });
  },

  _renderGiftCardControls() {
    if (
      !gcn.location.hasStoredValueIntegration() ||
      StoredValueHelper.isStoredValueSecondaryPayment() ||
      gcn.screenReaderIsActive
    ) {
      return;
    }

    if (!gcn.location.useOrdersApiV2() && this._hasStoredValueTransactions()) {
      // orders api v1 can only have 1 gift card transaction
      return;
    }

    if (gcn.orderManager.getGrandTotal() === 0) {
      return;
    }

    const sessionStoredValueCards = gcn.orderManager.getStoredValueCards().filter((card) => {
      return !!card.authSessionToken;
    });
    if (sessionStoredValueCards.length && !gcn.orderManager.getUnusedStoredValueCards().length) {
      // hide button if stored value session token cards all used
      return;
    }

    this._createGiftCardButton();
    this.$stepBody.find('.controls').append(this.$giftCardButton);
  },

  _renderGiftCardButton() {
    if (
      !gcn.location.hasStoredValueIntegration() ||
      !StoredValueHelper.isStoredValueSecondaryPayment() ||
      gcn.screenReaderIsActive
    ) {
      return;
    }

    this._createGiftCardButton();
    this._insertSecondaryPaymentButton(this.$giftCardButton);
  },

  _renderCompCardControls() {
    if (!gcn.location.hasCompCardIntegration() || gcn.screenReaderIsActive) {
      return;
    }

    const hasAppliedCompCard = !!gcn.orderManager.getAppliedCompCards().length;
    // If the order is partially paid for and we don't have a comp card, don't show the comp card button
    if (
      !hasAppliedCompCard &&
      (gcn.orderManager.getNetSales() === 0 || this._hasStoredValueTransactions())
    ) {
      return;
    }

    this.$compCardButton = $(
      `<div role="button" class="button change-comp-card">${localizeStr(
        hasAppliedCompCard ? Strings.REMOVE_COMP_CARD : Strings.USE_COMP_CARD,
      )}</div>`,
    );
    this.$compCardButton.onButtonTapOrHold('ossvCompCard', () => {
      if (this.$compCardButton.hasClass('disabled')) {
        return;
      }
      this._restartInactivityTimer();

      if (hasAppliedCompCard) {
        gcn.loyaltyManager.removeCompCard();
      } else {
        GcnGiftCardHelper.showGiftCardView('comp-card', () => {
          // Update all UI that corresponds to comp cards.
          this._renderControls();
          this._renderTotals();
          this._updateNextButton();
        });
      }
    });
    this.$stepBody.find('.controls').append(this.$compCardButton);
  },

  _showTipView() {
    if (this._showingTipView) {
      return;
    }

    gcn.orderManager.eventRepo.track(OrderClientEventName.OrderSummaryStepShowTipView);

    // Disable the buttons because the user may click the checkout button as the popup is opening
    // which will cause the transaction amount to be different from the expected total, since the
    // tip was not included in the transaction
    this._enableControlButtons(false);

    const self = this;
    const tipView = new GCNTipView({
      successCallback() {
        self._orderDidValidate();
        gcn.menuView.dismissStablePopup();

        if (gcn.orderManager.getTipTotal()) {
          gcn.orderManager.eventRepo.track(OrderClientEventName.TipAdd, {
            amount: gcn.orderManager.getTipTotal(),
          });
        }
      },
    });
    gcn.menuView.showStablePopup(tipView, 'tip-view', () => {
      this._hasShownTipView = true;
      this._showingTipView = false;
      // It should now be safe for the user to checkout
      self._enableControlButtons(true);
    });

    this._showingTipView = true;
  },

  _renderTipButtonText() {
    if (!this.$tipButton) {
      return;
    }

    this.$tipButton.htmlOrText(
      gcn.orderManager.getTipTotal() ? localizeStr(Strings.EDIT_TIP) : localizeStr(Strings.ADD_TIP),
    );
  },

  _renderTippingControls() {
    if (
      !gcn.location.get('tipsLevel') ||
      this._hasStoredValueTransactions() ||
      gcn.screenReaderIsActive ||
      gcn.orderManager.payingAtCashier() // If the guest is paying with cash it doesn't make sense for them to tip on the kiosk
    ) {
      return;
    }

    this.$tipButton = $(
      `<span role="button" class="button add-tip">${localizeStr(Strings.ADD_TIP)}</span>`,
    );

    this._renderTipButtonText();

    this.$stepBody.find('.controls').append(this.$tipButton);
    const self = this;
    this.$tipButton.onButtonTapOrHold('ossvTip', () => {
      self.restartPartialTenderTimeout();
      if (self.$tipButton.hasClass('disabled')) {
        return;
      }
      self._restartInactivityTimer();

      gcn.orderManager.eventRepo.track(OrderClientEventName.TipEdit);

      self._showTipView();
    });
  },

  // When loyalty is authenticated, we may restrict other features that do not play well with it.
  _shouldShowLoyaltyPane() {
    return gcn.loyaltyManager.hasLoyaltyAuthData();
  },

  _shouldShowRewardsButton() {
    return (
      !this._shouldShowLoyaltyPane() ||
      (gcn.location.getLoyaltyIntegration().allowRedemptionCodeWithReward &&
        !gcn.loyaltyManager.getLoyaltyAuthData().tokenData)
    );
  },

  _hasStoredValueTransactions() {
    return gcn.orderManager.getTransactions().length > 0;
  },

  _updateRewardsButton() {
    this.restartPartialTenderTimeout();
    if (!this._shouldShowRewardsButton()) {
      this.$rewardsButton.hide();

      if (gcn.loyaltyManager.canShowLoyaltyRedemptionButton()) {
        this.$redemptionCodeButton.show();
      } else {
        this.$redemptionCodeButton.hide();
      }
    } else {
      this.$rewardsButton.show();
      this.$redemptionCodeButton.hide();
    }
  },

  _renderLoyaltySignUpControls() {
    if (!gcn.loyaltyManager.canShowLoyaltySignupOnOrderSummary()) {
      return;
    }
    const self = this;
    this.$signupButton = $('<span role="button" class="button loyalty-signup-button"></span>');
    this.$signupButton.htmlOrText(localizeStr(Strings.SIGNUP_LOYALTY));
    this.$stepBody.find('.controls').append(this.$signupButton);
    this.$signupButton.onButtonTapOrHold('ossvSignup', () => {
      self.restartPartialTenderTimeout();
      if (self.$signupButton.hasClass('disabled')) {
        return;
      }
      const loyaltySignUpView = new GCNSimpleLoyaltySignupView();
      gcn.menuView.showStablePopup(loyaltySignUpView, 'simple-loyalty-signup-view');
      this.listenTo(
        loyaltySignUpView,
        BackboneEvents.GCNSimpleLoyaltySignupView.SmsInvitationWasSent,
        () => {
          gcn.menuView.dismissStablePopup();
          self._renderControls();
          gcn.menuView.showSimpleAlert(localizeStr(Strings.SENT_LOYALTY_SIGNUP_SMS));
        },
      );
    });
  },

  _startLoyaltyLoginFlow() {
    if (this.$rewardsButton.hasClass('disabled')) {
      return;
    }

    // Lets short cut here - TODO - api routing to fetch for customer
    if (gcn.loyaltyManager.hasCustomerAuthData()) {
      const customerLoyaltyAuthData = gcn.loyaltyManager.getCustomerAuthData();
      Log.debug('submitting loyalty data', customerLoyaltyAuthData);
      gcn.loyaltyManager.submitLoyaltyAuth(
        customerLoyaltyAuthData.identifier,
        customerLoyaltyAuthData.authValue,
        customerLoyaltyAuthData.authEntryMethod,
        (err) => {
          // Fallback to manual entry on auth failure
          if (err) {
            gcn.loyaltyManager.clearCustomerAuthData();
            this._startLoyaltyLoginFlow();
          }
        },
      );
      return;
    }

    if (gcn.menu.settings.get('useLoyaltySignupV2InCheckout')) {
      this.root = ReactDOM.createRoot(document.getElementById('react-portal'));
      this.root.render(
        React.createElement(LoyaltyAccountModal, { withDispatch: true, dismissOnAuth: true }),
      );
      return;
    }

    if (gcn.loyaltyManager.loyaltyUsesBarcode()) {
      const loyaltyBarcodeView = new GCNLoyaltyBarcodeView({
        hideRedemptionCodeFallback: gcn.loyaltyManager.hasRedemptionCode(),
      });
      this.listenToOnce(
        loyaltyBarcodeView,
        BackboneEvents.GCNLoyaltyBarcodeView.DidUpdateAuthGuest,
        () => {
          gcn.menuView.dismissStablePopup();
        },
      );
      gcn.menuView.showStablePopup(loyaltyBarcodeView, 'loyalty-barcode-view');
    } else if (gcn.loyaltyManager.loyaltyUsesPhoneAuth()) {
      gcn.menuView.showStablePopup(
        new GCNLoyaltyPhoneNumberView({
          lookupUserMethod(phoneNumber) {
            if (gcn.loyaltyManager.loyaltyUsesTokenAuth()) {
              gcn.loyaltyManager.submitLoyaltyTokenData(
                phoneNumber,
                LoyaltyAuthEntryMethod.PhoneNumberManuallyEntered,
              );
            } else {
              gcn.menuView.showSpinner(localizeStr(Strings.LOOKING_UP_ACCOUNT));
              gcn.loyaltyManager.submitLoyaltyAuth(
                phoneNumber,
                null,
                LoyaltyAuthEntryMethod.PhoneNumberManuallyEntered,
                () => {
                  gcn.menuView.dismissSpinner();
                },
              );
            }
          },
        }),
        'loyalty-phone-number-view',
      );
    } else if (gcn.loyaltyManager.loyaltyUsesCardNumber()) {
      const cardNumberView = new GCNLoyaltyCardNumberView();
      gcn.menuView.showStablePopup(cardNumberView, 'loyalty-card-number-view');
    } else {
      const loyaltyManualAuthView = new GCNLoyaltyManualAuthView();
      this.listenToOnce(
        loyaltyManualAuthView,
        BackboneEvents.GCNLoyaltyManualAuthView.DidUpdateAuthGuest,
        () => {
          gcn.menuView.dismissStablePopup();
        },
      );
      gcn.menuView.showStablePopup(loyaltyManualAuthView, 'loyalty-manual-auth-view');
    }
  },

  _renderLoyaltyControls() {
    if (!gcn.loyaltyManager.supportsAuthedRewards()) {
      return;
    }

    // Loyalty is mutually exclusive from these other features.
    // Also, if no order is assigned yet, show this control.

    // getTransactions for stored value transactions
    if (gcn.orderManager.getOrder() && this._hasStoredValueTransactions()) {
      return;
    }
    // Loyalty is sometimes mutually exclusive from coupons
    if (
      gcn.orderManager.getOrder() &&
      gcn.orderManager.getCouponCode() &&
      !gcn.loyaltyManager.areCouponsAndLoyaltyEarningCompatible()
    ) {
      return;
    }

    // If the order subTotal is somehow fully covered (coupons, comp cards),
    // then there is no room for rewards
    if (gcn.orderManager.getNetSales() === 0) {
      return;
    }

    this.$rewardsButton = $('<span role="button" class="button loyalty-auth-button"></span>');
    this.$stepBody.find('.controls').append(this.$rewardsButton);

    this.$redemptionCodeButton = $(
      '<span role="button" class="button loyalty-manual-redemption-button"></span>',
    );
    this.$stepBody.find('.controls').append(this.$redemptionCodeButton);

    this._updateRewardsButton();

    if (gcn.loyaltyManager.loyaltyProvidesPayment()) {
      this.$rewardsButton.htmlOrText(localizeStr(Strings.LOYALTY_PAY_WITH_APP));
    } else {
      this.$rewardsButton.htmlOrText(localizeStr(Strings.GET_REWARDS));
    }

    this.$redemptionCodeButton.htmlOrText(localizeStr(Strings.LOYALTY_REDEMPTION_CODE));

    const self = this;

    this.$rewardsButton.onButtonTapOrHold('ossvRewards', () => {
      self.restartPartialTenderTimeout();
      this._startLoyaltyLoginFlow();
    });

    // The redemption code is only shown for punchh loyalty
    // We want to hide the phone fallback because the redemption code button
    // will only be shown after the user has already authed using their phone number.
    // So, we don't need to show the phone fallback again.
    this.$redemptionCodeButton.onButtonTapOrHold('ossvRedemption', () => {
      gcn.menuView.dismissStablePopup();
      const loyaltyBarcodeView = new GCNLoyaltyBarcodeView({
        hidePhoneFallback: true,
      });
      this.listenToOnce(
        loyaltyBarcodeView,
        BackboneEvents.GCNLoyaltyBarcodeView.DidUpdateAuthGuest,
        () => {
          gcn.menuView.dismissStablePopup();
        },
      );
      gcn.menuView.showStablePopup(loyaltyBarcodeView, 'punchh-barcode-view');
    });
  },

  _renderNotices() {
    this._$notices.empty();

    const footnoteText = localizeStr(Strings.SUMMARY_STEP_FOOTNOTE);
    if (footnoteText) {
      const $footnote = $('<div class="footnote font-body"></div>');
      $footnote.html(footnoteText);
      this._$notices.append($footnote);
    }

    if (
      this._canScanCoupons() &&
      !gcn.orderManager.getCouponCode() &&
      !this._shouldShowLoyaltyPane() &&
      GcnKiosk.isScannerConnected()
    ) {
      const $scanCouponNote = $(
        this._noteWithIconTemplate({
          iconText: '',
          label: localizeStr(Strings.SCAN_COUPON_BELOW),
        }),
      );
      $scanCouponNote.addClass('scanner-prompt');
      this._$notices.append($scanCouponNote);
    }
  },

  _canScanCoupons() {
    return gcn.location.hasCouponProvider() && gcn.location.couponsHaveBarcode();
  },

  _renderCouponControls() {
    if (!gcn.location.hasCouponProvider()) {
      return;
    }

    if (
      this._shouldShowLoyaltyPane() &&
      !gcn.loyaltyManager.areCouponsAndLoyaltyEarningCompatible()
    ) {
      return;
    }

    // don't show buttons if a loyalty reward has been applied.
    // coupons and loyalty rewards are tightly coupled
    // if there are applied rewards and no coupon, then the applied reward must be from loyalty
    if (
      (gcn.orderManager.getOrder() &&
        _.size(gcn.orderManager.getAppliedRewards()) &&
        !gcn.orderManager.getCouponCode() &&
        !gcn.loyaltyManager.areCouponsAndLoyaltyEarningCompatible()) ||
      gcn.screenReaderIsActive
    ) {
      return;
    }

    const couponCode = gcn.orderManager.getCouponCode();
    // If the order is partially paid for and we don't have a coupon, don't show the coupon button
    if (
      !couponCode &&
      (gcn.orderManager.getNetSales() === 0 || this._hasStoredValueTransactions())
    ) {
      return;
    }

    this.$couponsButton = $('<span role="button" class="button add-coupon"></span>');
    this.$stepBody.find('.controls').append(this.$couponsButton);
    const couponButtonLabel = couponCode
      ? localizeStr(Strings.REMOVE_COUPON)
      : localizeStr(Strings.USE_COUPON);
    this.$stepBody.find('.controls .button.add-coupon').htmlOrText(couponButtonLabel);

    const self = this;
    this.$couponsButton.onButtonTapOrHold('ossvCoupons', () => {
      self.restartPartialTenderTimeout();
      if (this.$couponsButton.hasClass('disabled')) {
        return;
      }
      this._restartInactivityTimer();

      // remove coupon if it exists
      if (gcn.orderManager.getCouponCode()) {
        gcn.menuView.showSpinner(localizeStr(Strings.REMOVING_COUPON));
        gcn.orderManager.removeCouponCode(() => {
          this._updateNextButton();
        });
        return;
      }

      const couponView = new GCNCouponView({
        successCallback: () => {
          Log.info('Successfully applied coupon. Got a new order to validate.');
          this._orderDidValidate();
          gcn.menuView.dismissStablePopup();
          this._updateNextButton();
        },
      });
      gcn.menuView.showStablePopup(couponView, 'coupon-view');
    });
  },

  _renderGiftCardTotal() {
    // TODO this needlessly fires twice
    if (!gcn.location.hasStoredValueIntegration()) {
      return;
    }

    const $giftCardWrapper = this.$('.totals .gift-card');
    const transactions = gcn.orderManager.getTransactions();

    if (!transactions.length) {
      // hide
      $giftCardWrapper.slideUp({
        complete: () => {
          this.$('.gift-card-applied-amounts').empty();
        },
      });
    }

    const $giftCardAppliedAmounts = this.$('.gift-card-applied-amounts');
    this.$('.gift-card-applied-amounts').empty();

    const cardNameByLastFour = gcn.orderManager
      .getStoredValueCards()
      .reduce((nameByLastFour, card) => {
        return {
          ...nameByLastFour,
          [card.lastFour]: card.cardName,
        };
      }, {});
    transactions.forEach((transaction) => {
      const defaultCardName = str(Strings.GIFT_CARD);
      const lastFour = transaction.getLastFour();
      const cardName = cardNameByLastFour[lastFour];
      const amountStr = GcnHtml.htmlFromPrice(-transaction.get('amount'));
      const transactionText = $(
        `<div class="gift-card-applied-amount">${
          cardName || defaultCardName
        } (*${lastFour}): ${amountStr}</div>`,
      );
      $giftCardAppliedAmounts.append(transactionText);
    });

    $giftCardWrapper.hide();
    $giftCardWrapper.slideDown();
  },

  _renderLoyaltyPaymentTotal() {
    const transaction = gcn.loyaltyManager.getLoyaltyTransaction();
    const $loyaltyPaymentLine = this.$('.loyalty-payment');
    $loyaltyPaymentLine.hide();
    if (transaction) {
      const tenders = transaction.getNonDiscountTenders();
      if (tenders.length) {
        const tenderDescriptions = _.map(transaction.getNonDiscountTenders(), (tender) => {
          return `${tender.cardSchemeName}: ${GcnHtml.htmlFromPrice(-tender.amount)}`;
        });
        $loyaltyPaymentLine.html(tenderDescriptions.join('<br />'));
        $loyaltyPaymentLine.slideDown();
      }
    }
  },

  _renderReducedSubtotal() {
    if (!gcn.orderManager.hasUnconfirmedPriceChanges()) {
      return;
    }

    const $deductedPriceChangeLine = this.$('.deducted-total');
    $deductedPriceChangeLine.hide();
    // strike through the previous subtotal
    const $subTotalPrice = this.$stepBody.find('.sub-total .price');
    $subTotalPrice.html(`${GcnHtml.htmlFromPrice(gcn.orderManager.getUnconfirmedPriceChanges())}`);

    const subtotalIncreased =
      gcn.orderManager.getSubTotal() > gcn.orderManager.getUnconfirmedPriceChanges();
    $deductedPriceChangeLine.htmlOrText(
      `${localizeStr(
        subtotalIncreased ? Strings.SUBTOTAL_INCREASED : Strings.SUBTOTAL_REDUCED,
      )}: ${GcnHtml.htmlFromPrice(gcn.orderManager.getSubTotal())}</span>`,
    );
    // animate the change once the totals have rendered
    setTimeout(() => {
      $subTotalPrice.addClass('strikethrough');
      $deductedPriceChangeLine.slideDown();
      gcn.orderManager.confirmPriceChanges();
    }, 750);
  },

  _renderTipTotal() {
    const tipTotal = gcn.orderManager.getTipTotal();
    const $tipWrapper = this.$('.totals .tip');
    const $tipAppliedAmount = this.$('.tip-applied-amount');
    if (tipTotal) {
      $tipAppliedAmount.htmlOrText(
        `${localizeStr(Strings.TIP)}: ${GcnHtml.htmlFromPrice(tipTotal)}`,
      );
      $tipWrapper.hide();
      $tipWrapper.slideDown();
    } else {
      $tipWrapper.slideUp({
        complete() {
          $tipAppliedAmount.empty();
        },
      });
    }
  },

  _renderTotalSubscript() {
    const customString = localizeStr(Strings.TOTAL_SUBSCRIPT);

    // The point count is custom work for lennys that wants to display a message like:
    // You could have earned 'X' points today, Sign up for Loyalty!
    // Where X is the point count which is just the number of dollars floored.
    // They don't want to show this message if a guest is logged in.
    // I made this logic pretty simple for the time being,
    // but more complexity can be added if other clients want a message here.
    if (customString.includes('POTENTIAL_POINT_COUNT') && gcn.loyaltyManager.hasLoyaltyAuthData()) {
      return;
    }
    const totalStr = gcn.orderManager.getGrandTotal().toString();
    const text = customString.replace(
      'POTENTIAL_POINT_COUNT',
      totalStr.substring(0, totalStr.length - 2),
    );

    this.$('.totals .total-subscript').htmlOrText(text);
  },

  _renderServiceChargeTotal() {
    const serviceChargeTotal = gcn.orderManager.getServiceChargeTotal();
    const $serviceFeeWrapper = this.$('.totals .service-charge');
    const $serviceFeeAppliedAmount = this.$('.service-charge-applied-amount');
    if (serviceChargeTotal) {
      $serviceFeeAppliedAmount.htmlOrText(
        `${localizeStr(Strings.SERVICE_CHARGE)}: ${GcnHtml.htmlFromPrice(serviceChargeTotal)}`,
      );
      $serviceFeeWrapper.hide();
      $serviceFeeWrapper.slideDown();
    } else {
      $serviceFeeWrapper.slideUp({
        complete() {
          $serviceFeeAppliedAmount.empty();
        },
      });
    }
  },

  _renderDiscountTotal() {
    const $discountsWrapper = this.$('.discounts');
    const discountNamesArray = gcn.orderManager.getDiscountNames();
    const uniqueDiscountNames = [...new Set(discountNamesArray)];
    const discountNames = uniqueDiscountNames.join('<br />');
    const discountNamesWithAmounts = gcn.orderManager.getDiscountNamesWithAmounts();
    const discountTotal = gcn.orderManager.getDiscountTotal();
    $discountsWrapper.hide();

    const everyDiscountHasAName = discountNamesWithAmounts.every((discount) => discount.name);
    const totalDiscountAmount = discountNamesWithAmounts.reduce(
      (acc, discount) => acc + discount.amount,
      0,
    );
    if (
      discountNamesWithAmounts.length > 1 &&
      everyDiscountHasAName &&
      discountTotal === totalDiscountAmount
    ) {
      // If every discount has a name and their total equals the order's total discount amount, show them all
      $discountsWrapper.htmlOrText(
        `<span class="amount">${discountNamesWithAmounts
          .map((discount) => `${discount.name}: ${GcnHtml.htmlFromPrice(-discount.amount)}`)
          .join('<br />')}`,
      );
      $discountsWrapper.slideDown();
    } else if ((discountTotal >= 0 && discountNames) || discountTotal > 0) {
      // First condition to support Togos 0 dollar coupons (for tracking)
      // Second for discounts without any name (Hava Java buy two get one free)
      $discountsWrapper.htmlOrText(
        `<span class="amount">${
          discountNames || localizeStr(Strings.DISCOUNTS)
        } <br /> ${GcnHtml.htmlFromPrice(-gcn.orderManager.getDiscountTotal())}</span>`,
      );
      $discountsWrapper.slideDown();
    }
  },

  // Append the first (header) item into the step body.
  // Override this method to append custom content.
  __renderHeader() {
    this.$stepBody.append(
      // prettier-ignore
      `<div class="header-container">` +
        `<div id="order-summary-step-view-header" class="header bg-color-spot-1" role="heading" aria-level="1" tabindex="-1">` +
          (gcn.loyaltyManager.getAuthedGuestFriendlyName() ? localizeStr(Strings.ORDER_SUMMARY_WITH_NAME, [gcn.loyaltyManager.getAuthedGuestFriendlyName()]) : localizeStr(Strings.ORDER_SUMMARY)) +
        '</div>' +
      `</div>`,
    );
    if (gcn.screenReaderIsActive) {
      this.$stepBody.find('#order-summary-step-view-header').requestFocusAfterDelay();
    }
  },

  // Override this method to append custom content below the header, before
  // the item summary.
  __renderTopContent() {},

  _renderAlcoholCheck() {
    if (
      gcn.orderManager.hasAlcoholicContents() &&
      !gcn.menu.settings.get('requireManagerCodeForAlcohol')
    ) {
      const $noteWithIcon = $(
        this._noteWithIconTemplate({
          iconText: '21+',
          label: localizeStr(Strings.ID_CHECK_LABEL),
        }),
      );
      $noteWithIcon.addClass('alcohol-id-check');
      this.$stepBody.append($noteWithIcon);
    }
  },

  _renderItemsList() {
    this.$itemsList = $('<div class="items-list"></div>');
    this.$stepBody.append(this.$itemsList);
    const orderedItemsByVendorId = gcn.orderManager.getOrderedItemsByVendorId();
    if (gcn.menu.vendors.length > 1 && _.size(orderedItemsByVendorId) > 0) {
      _.each(orderedItemsByVendorId, (orderedItems, vendorId) => {
        let $vendorHeader;
        if (vendorId === GCNOrder.NoVendorId) {
          $vendorHeader = this._vendorHeaderTemplate({
            title: '',
            name: localizeStr(Strings.SELF_SERVE),
          });
        } else {
          const vendor = gcn.menu.getVendorWithId(vendorId);
          const pickUpText = vendor.hasStr('pickUpText') ? ` (${vendor.get('pickUpText')})` : '';
          $vendorHeader = this._vendorHeaderTemplate({
            title: localizeStr(Strings.PICK_UP_AT),
            name: vendor.get('name') + pickUpText,
          });
        }
        this.$itemsList.append($vendorHeader);
        this._renderOrderedItemsInList(orderedItems);
      });
    } else {
      this._renderOrderedItemsInList(gcn.orderManager.getOrderedItems());
    }
  },

  _renderOrderedItemsInList(orderedItems) {
    const self = this;
    _.each(orderedItems, (orderedItem) => {
      const orderedItemView = new GCNOrderedItemView({
        showPrice: true,
        model: orderedItem,
      });
      self.$itemsList.append(orderedItemView.render().$el);
    });
  },

  // Given a button, insert it to the left of the main payment button and style everything to fit.
  _insertSecondaryPaymentButton($button) {
    this._hasGiftCardButton = true;
    this.$buttonBar.toggleClass('multiple-payment-methods', true);
    this.$buttonsRight.prepend($button);
  },

  _getReadableTotalsDescription() {
    let summary = this.$nextButton.text();
    summary += ` ${str(Strings.SUBTOTAL)}: $${GcnHelper.stringFromTotal(
      gcn.orderManager.getSubTotal(),
    )}`;
    summary += ` ${str(Strings.TAXES)}: $${GcnHelper.stringFromTotal(
      gcn.orderManager.getTaxTotal(),
    )}`;
    summary += ` ${str(Strings.GRAND_TOTAL)}: $${GcnHelper.stringFromTotal(
      gcn.orderManager.getGrandTotal(),
    )}`;
    return summary;
  },

  _updateNextButton() {
    if (gcn.location.get('paymentType') === LocationPaymentType.CreditCard) {
      if (gcn.orderManager.payingAtCashier() || gcn.orderManager.getGrandTotal() === 0) {
        this.$payWithLabel?.hide();
        this.$giftCardButton?.hide();

        this.setNextButtonText(localizeStr(Strings.PLACE_ORDER));
      } else {
        this.$payWithLabel?.show();
        this.$giftCardButton?.show();

        if (gcn.menu.settings.get('currencyCode') === 'CAD') {
          this.setNextButtonText(localizeStr(Strings.PAY_WITH_DEBIT_CREDIT));
        } else if (this._hasGiftCardButton) {
          this.setNextButtonText(localizeStr(Strings.CREDIT_CARD));
        } else {
          this.setNextButtonText(localizeStr(Strings.PAY_WITH_CREDIT));
        }
      }
    } else {
      this.setNextButtonText(localizeStr(Strings.PLACE_ORDER));
    }
  },

  _renderLoyaltyPane() {
    if (!this._shouldShowLoyaltyPane()) {
      return;
    }

    if (this.$rewardsButton) {
      this.$rewardsButton.hide();

      if (gcn.loyaltyManager.canShowLoyaltyRedemptionButton()) {
        this.$redemptionCodeButton.show();
      }
    }

    const loyaltyPaneView = new GcnLoyaltyPaneView();
    this.$loyaltyContainer.html(loyaltyPaneView.render().$el);
  },

  _renderStoredValuePane() {
    this.restartPartialTenderTimeout();
    if (!this._hasStoredValueTransactions()) {
      this.$storedValueContainer.html('');
      return;
    }

    const storedValuePaneView = new GcnStoredValuePaneView();
    this.$storedValueContainer.html(storedValuePaneView.render().$el);
  },

  _rerender() {
    this.$stepBody.html('');
    this.render();
  },

  render(...args) {
    GCNFullScreenFlowStepView.prototype.render.apply(this, args);

    gcn.orderManager.eventRepo.track(OrderClientEventName.OrderSummaryStepViewRender);

    this.__renderHeader();
    this.__renderTopContent();
    this._renderAlcoholCheck();
    this._renderItemsList();

    this.$stepBody.append('<div class="divider"></div>');

    this._$notices = $('<div class="notices"></div>');
    this.$stepBody.append(this._$notices);
    this._renderNotices();

    this.$storedValueContainer = $('<div class="stored-value-container"></div>');
    this.$stepBody.append(this.$storedValueContainer);
    this.$loyaltyContainer = $('<div class="loyalty-container"></div>');
    this.$stepBody.append(this.$loyaltyContainer);
    this._renderLoyaltyPane();

    this.$stepBody.append(
      `<div class="order-details">
          <div class="controls"></div>
          <div class="totals">
            <div class="sub-total" role="heading" aria-level="1"></div>
            <div class="deducted-total"></div>
            <div class="discounts"></div>
            <div class="tax" role="heading" aria-level="1">
              <span class="amount"></span>
              <span class="spinner mini-loader"></span>
            </div>
            <div class="tip"><span class="tip-applied-amount"></span></div>
            <div class="service-charge"><span class="service-charge-applied-amount"></span></div>
            <div class="gift-card"><span class="gift-card-applied-amounts"></span></div>
            <div class="loyalty-payment"></div>
            <div class="grand-total" role="heading" aria-level="1">
              <span class="amount"></span>
              <span class="spinner mini-loader"></span>
            </div>
            <div class="total-subscript"></div>
          </div>
        </div>`,
    );

    this._renderControls();

    this.$stepBody
      .find('.sub-total')
      .htmlOrText(
        `${localizeStr(Strings.SUBTOTAL)}: ${GcnHtml.htmlFromPrice(
          gcn.orderManager.getSubTotal(),
        )}`,
      );

    this.$stepBody.find('.sub-total .price').toggleClass('strikethrough', false);
    this.$stepBody.find('.deducted-total').hide();
    if (gcn.orderManager.orderHasBeenValidated()) {
      this._renderTotals();
    } else {
      this.$stepBody.find('.tax .amount').htmlOrText(`${localizeStr(Strings.TAXES)}: `);
      this.$stepBody
        .find('.grand-total .amount')
        .htmlOrText(`${localizeStr(Strings.GRAND_TOTAL)}: `);
    }

    this._renderGiftCardButton();
    if (this.$buttonBar.hasClass('multiple-payment-methods')) {
      this.$payWithLabel = $(`<div class="label">${localizeStr(Strings.PAY_WITH)}:</div>`);
      this.$buttonsRight.prepend(this.$payWithLabel);
    }

    this.setPrevButtonText(localizeStr(Strings.CANCEL_CHECKOUT));
    this._updateNextButton();

    this._enableControlButtons(gcn.orderManager.orderHasBeenValidated());

    this._postRenderInit();

    return this;
  },
});

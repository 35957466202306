import { FTScroller } from 'ftscroller';
import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom/client';
import _ from 'underscore';

import { Strings } from '@biteinc/common';
import { Disclaimer, Footer } from '@biteinc/core-react';
import {
  BitePlatform,
  DeprecatedRecommendationSource,
  ItemHiddenStateOnMenu,
  KioskOrderingMode,
  MenuItemDisplayStyle,
  RecommendationsFirstLoadVisibility,
  RecommendationSource,
} from '@biteinc/enums';

import { BackboneEvents } from '~/app/js/backbone-events';
import { useStore } from '~/stores';

import GuestManager from '../guest_manager';
import { str } from '../localization/localization';
import { GCNMenuSection } from '../models/gcn_menu_section';
import Analytics from '../utils/analytics';
import { GCNBiteLogoView } from './gcn_bite_logo_view';
import { GCNMenuQuickNavView } from './gcn_menu_quick_nav_view';
import { GCNMenuSectionView } from './gcn_menu_section_view';
import { GCNView } from './gcn_view';

const farDistanceThreshold = 1024 * 4;
export const GCNMenuPageView = GCNView.extend({
  className: 'page-view',

  initialize(...args) {
    GCNView.prototype.initialize.apply(this, args);

    this.menuPage = null;
    this._sectionElements = [];

    this.subscriptions = [];

    this.subscriptions.push(
      useStore.subscribe((state, prevState) => {
        if (!_.isEqual(state.config.menuFilters, prevState.config.menuFilters)) {
          this.render();
        }
      }),
    );

    this.listenTo(gcn, BackboneEvents.GCNMenuAppView.CoverWasOpened, this._coverWasOpened);
    this.listenTo(gcn.guestManager, GuestManager.Events.GuestDidChange, this._guestDidChange);
    this.listenTo(gcn, BackboneEvents.GCNMenuAppView.MenuDidUpdate, this._menuDidUpdate);
    this.listenTo(
      gcn.loyaltyManager,
      BackboneEvents.GCNLoyaltyManager.DidFetchRewardsNoOrder,
      () => {
        this._menuDidUpdate();
      },
    );
  },

  _menuDidUpdate() {
    this.setMenuPage(gcn.menu.getFirstMenuPage());
  },

  setMenuPage(menuPage, scrollToTop) {
    const oldMenuPage = this.menuPage;
    this.menuPage = menuPage;

    if (oldMenuPage && oldMenuPage.id !== this.menuPage.id) {
      this.$el.toggleClass(`page-${oldMenuPage.id}`, false);
      this.$el.toggleClass(`page-${oldMenuPage.getSlugName()}`, false);
    }
    this.$el.toggleClass(`page-${this.menuPage.id}`, true);
    this.$el.toggleClass(`page-${this.menuPage.getSlugName()}`, true);

    this.render();

    if (scrollToTop || (oldMenuPage && oldMenuPage.id !== this.menuPage.id)) {
      this.scrollTo(this.$el.offset().top, false);
    }
  },

  setBackgroundImage(backgroundImage) {
    this.backgroundImage = backgroundImage;
    const css = backgroundImage ? `url(${backgroundImage})` : 'none';
    this.$container.css('background-image', css);
  },

  scrollToSectionWithId(menuSectionId) {
    const sectionView = this.sectionViewsById[menuSectionId];
    if (sectionView) {
      // Offset the scroll y for quick nav either if it's sticky or if we are scrolling to the
      // first section
      const offsetForQuickNav =
        this._quickNavView &&
        !this._quickNavView.headlessMode &&
        (this.menuPage.sections[0].id === menuSectionId ||
          gcn.menu.shouldUseStickyQuickNavigation());
      const quickNavHeightOffset = offsetForQuickNav
        ? -Math.ceil(this._quickNavView.$el.outerHeight())
        : 0;

      this.scrollTo(
        gcn.customScroller
          ? sectionView.$el.offset().top + gcn.customScroller.scrollTop
          : quickNavHeightOffset + this.$el.scrollTop() + sectionView.$el.offset().top,
        true,
        true,
      );
    }
  },

  scrollTo(y, animated, jumpIfFar) {
    const self = this;
    const offset = y - this.$el.offset().top - parseInt(this.$container.css('padding-top'), 10);
    const far = Math.abs(this.$el.scrollTop() - offset) > farDistanceThreshold;
    if (!animated || (jumpIfFar && far)) {
      if (gcn.customScroller) {
        gcn.customScroller.scrollTo(_, offset, 600);
      } else {
        this.$el.scrollTop(offset);
      }
    } else {
      if (gcn.customScroller) {
        gcn.customScroller.scrollTo(0, offset, 600);
      } else {
        this._currentlyScrolling = true;
        this.$el.animate(
          {
            scrollTop: offset,
          },
          'slow',
          () => {
            self._currentlyScrolling = false;
          },
        );
      }
    }
  },

  _coverWasOpened() {
    if (this._hasDelayedGuestContent) {
      setTimeout(() => {
        this.render();
      }, 350);
    }
  },

  _hasVisibleRecommendationsForGuest() {
    switch (gcn.menu.settings.get('recommendationsFirstLoadVisibility')) {
      case RecommendationsFirstLoadVisibility.Hidden:
        return false;
      case RecommendationsFirstLoadVisibility.OrderHistory:
        return gcn.guestManager.hasOrderedItems();
      case RecommendationsFirstLoadVisibility.PredictionsAndOrderHistory:
        return gcn.guestManager.hasRecos();
    }
    return false;
  },

  clearSession() {
    this._hasAnimatedPromoSection = false;
    this._hasDelayedGuestContent = false;
  },

  _guestDidChange() {
    this.render();
    if (!this._hasVisibleRecommendationsForGuest()) {
      this._hasAnimatedPromoSection = false;
      this._hasDelayedGuestContent = false;
    }
    // TODO this rerender causes our recommendation tracking to track twice on every first load
  },

  _renderGuestContent() {
    if (this._hasVisibleRecommendationsForGuest()) {
      if (!gcn.sessionWasStartedAt) {
        this._hasDelayedGuestContent = true;
        return;
      }

      this._renderPromoSection();
    }
  },

  _renderPromoSection() {
    // Get the recommended items.
    const recoItemIds = gcn.guestManager.getRecoItemIds();
    let promoItems = [];
    const selectedItemNameSet = {};
    const selectedItemPosIdSet = {};
    const maxPromoItemCount = $('body').width() > 800 ? 6 : 4;

    recoItemIds.forEach((itemId) => {
      if (
        gcn.menu.settings.get('recommendationsFirstLoadVisibility') ===
        RecommendationsFirstLoadVisibility.OrderHistory
      ) {
        const recoSource = gcn.guestManager.recoSourceForRecoItemId(itemId);
        if (
          recoSource === RecommendationSource.Prediction ||
          recoSource === DeprecatedRecommendationSource.Prediction
        ) {
          return;
        }
      }

      if (promoItems.length >= maxPromoItemCount) {
        return;
      }

      const item = gcn.menu.getMenuItemWithId(itemId);
      if (item) {
        const nameSlug = item.getSlugName();
        if (!selectedItemNameSet[nameSlug] && !selectedItemPosIdSet[item.get('posId')]) {
          promoItems.push(item);
          selectedItemNameSet[nameSlug] = true;
          if (item.has('posId')) {
            selectedItemPosIdSet[item.get('posId')] = true;
          }
        }
      }
    });

    let squaresOccupied = 0;
    let perRowCount = 0;
    const blankIndices = [];
    let remainingPromoItems = [];
    for (let i = 0; i < promoItems.length; i++) {
      const promoItem = promoItems[i];

      squaresOccupied++;
      if (MenuItemDisplayStyle.Wide === promoItem.get('displayStyle')) {
        if (perRowCount % 2 === 1) {
          blankIndices.push(i - 1);
          squaresOccupied++;
          perRowCount = 0;
        }
        squaresOccupied++;
      } else {
        perRowCount = (perRowCount + 1) % 2;
      }

      if (squaresOccupied >= maxPromoItemCount) {
        const cutOff = squaresOccupied > maxPromoItemCount ? i : i + 1;
        remainingPromoItems = promoItems.slice(cutOff);
        promoItems = promoItems.slice(0, cutOff);
        break;
      }
    }

    const limit = Math.min(blankIndices.length, remainingPromoItems.length);
    for (let j = 0; j < limit; j++) {
      const remainingPromoItem = remainingPromoItems[j];
      if (MenuItemDisplayStyle.Wide !== remainingPromoItem.get('displayStyle')) {
        promoItems.splice(blankIndices[j], 0, remainingPromoItem);
      }
    }

    if (promoItems.length) {
      const promoSection = GCNMenuSection.newPromoSection(
        GCNMenuSection.BITE_LIFT_SECTION_ID,
        promoItems,
        gcn.menu,
        str(Strings.RECOMMENDATIONS),
        gcn.loyaltyManager.getAuthedGuestFriendlyName()
          ? str(Strings.WELCOME_BACK_WITH_NAME, [gcn.loyaltyManager.getAuthedGuestFriendlyName()])
          : str(Strings.WELCOME_BACK),
      );
      gcn.menu.registerPromoSection(promoSection);
      const promoSectionView = this._renderSection(promoSection, true);

      if (!this._hasAnimatedPromoSection) {
        this._hasAnimatedPromoSection = true;

        promoSectionView.$el.hide();
        setTimeout(() => {
          // If we are already not at the top of the page, then we want to slide the section up so it
          // doesn't affect our scroll position
          if (this.$el.scrollTop() === 0) {
            promoSectionView.$el.slideDown(800, () => {
              // If we have a custom scroller, we need to update its dimensions after sliding down
              // the promos section
              if (gcn.customScroller && window.platform === BitePlatform.KioskSignageOsGarcon) {
                gcn.customScroller.updateDimensions();
              }
            });
          } else {
            promoSectionView.$el.slideUp(800, () => {
              // If we have a custom scroller, we need to update its dimensions after sliding up
              // the promos section
              if (gcn.customScroller && window.platform === BitePlatform.KioskSignageOsGarcon) {
                gcn.customScroller.updateDimensions();
              }
            });
          }
        }, 1);
      }
    }
  },

  _renderSection(section) {
    if (!section.hasArr('items')) {
      return null;
    }

    const sectionView = new GCNMenuSectionView({
      model: section,
      maxItemDisplayStyle: section.isPromoSection()
        ? MenuItemDisplayStyle.NameAndImage
        : MenuItemDisplayStyle.Wide,
    });
    const $sectionEl = sectionView.render().$el;
    this._sectionElements.push($sectionEl);
    if (section.isPromoSection()) {
      this.$container.prepend($sectionEl);
    } else {
      this.$container.append($sectionEl);
    }
    this.sectionViewsById[section.id] = sectionView;
    return sectionView;
  },

  // Safely returns the image URL as a string if available, otherwise returns null.
  _getSubPageImageUrl(subPage) {
    // Prefer the page image, but use the first section image if not provided.
    const subPageImages = subPage.get('images');
    if (subPageImages && subPageImages.length) {
      return subPageImages[0].url;
    }
    if (subPage.sections.length) {
      const images = subPage.sections[0].get('images');
      if (images && images.length) {
        return images[0].url;
      }
    }
    return null;
  },

  _renderSubPage(subPage) {
    const $div = $(
      `<div class="subpage-link bg-color-spot-1 shadow-z-1 subpage-${
        subPage.id
      } subpage-${subPage.getSlugName()}">` +
        `<div class="scrim">` +
        `<span class="name font-title">${subPage.displayName()}</span>` +
        `</div>` +
        `</div>`,
    );
    this.$container.append($div);

    // Set up the tap action, which navigates to the subpage.
    const self = this;
    $div.onTapInScrollableAreaWithCalibration('mpvSubPage', () => {
      Analytics.trackEvent({
        eventName: Analytics.EventName.MenuPageViewSubPageSelected,
        eventData: {
          subPageName: subPage.displayName(),
        },
      });
      self.trigger(BackboneEvents.GCNMenuPageView.DidSelectMenuPageId, subPage.id);
      return false;
    });

    // Fetch the image asynchronously.
    const headerUrl = this._getSubPageImageUrl(subPage);
    gcn.requestImageByUrl(headerUrl, (err, imgPath) => {
      $div.css('background-image', `url(${imgPath})`);
    });
  },

  setPageViewHeight() {
    const cartForcedOpenMode =
      gcn.menu.settings.get('kioskOrderingMode') === KioskOrderingMode.ScannerOnly ||
      gcn.screenReaderIsActive;
    const cartHasOrders = !!gcn.orderManager.getOrderedItems().length;

    if (cartForcedOpenMode && cartHasOrders) {
      const topNavOffset = document.querySelector('.top-nav-view')?.clientHeight || 0;
      const scrollingNavOffset = document.querySelector('.scrolling-nav-view')?.clientHeight || 0;
      const cartOffset = document.querySelector('.cart-view__collapsible-view')?.clientHeight || 0;

      // Take the height of the entire screen and then subtract
      // * Dynamic top bar height
      // * Dynamic bottom bar height
      // * Height of the cart container, includes ordered items and recommended items
      // * Scrolling Nav View if it exists
      this.$el.css(
        'height',
        `calc(100% - (var(--cart-top-bar-height) - var(--bottom-bar-height) + ${
          topNavOffset + scrollingNavOffset + cartOffset
        }px))`,
      );
    } else {
      this.$el.css('height', '100%');
    }
  },

  render() {
    const self = this;
    const $prevContainer = this.$container;
    const thisMenuHasSideNav = gcn.menuView?.menuHasSideNav();
    if (this.$container) {
      // Temporarily replace the container with a random div. We will render
      // everything in it and then replace the original container's content
      // with the contents of this temporary div. This way we can avoid the
      // page scrolling up if we were to this.$container.html('<div></div>');
      // See https://github.com/stasnikiforov/maitred/issues/723
      this.$container = $('<div></div>');
    } else {
      this.$container = $('<div class="background-container clearfix"></div>');
      this.$el.html(this.$container);
      if (this.backgroundImage) {
        this.$container.css('background-image', `url(${this.backgroundImage})`);
      }
    }
    this._sectionElements = [];

    if (this.menuPage) {
      // Render links to sub pages.
      _.each(this.menuPage.pages, this._renderSubPage.bind(this));

      // Render sections.
      this.sectionViewsById = {};

      // Render promoted items section first.
      if (gcn.menu.structure.pages[0].id === this.menuPage.id) {
        this._renderGuestContent();
      }

      for (let i = 0; i < this.menuPage.sections.length; i++) {
        const section = this.menuPage.sections[i];
        if (section.attributes.hideFromMenu) {
          continue;
        }
        this._renderSection(section);
      }
      // The previous call appends all the section elements to
      // this._sectionElements, which we can now use to create the section
      // quick nav control.
      if (this._quickNavView) {
        this.stopListening(this._quickNavView);
        this._quickNavView.$el.remove();
        this._quickNavView = null;
      }
      if (
        this.menuPage.get('showQuickNav') &&
        _.size(this.menuPage.sections) &&
        !gcn.screenReaderIsActive &&
        !window.isFlash
      ) {
        const sections = this.menuPage.sections.filter((section) => {
          return !section.attributes.hideFromMenu;
        });
        if (sections.length) {
          this._quickNavView = new GCNMenuQuickNavView({
            // Filter out hidden sections.
            sections,
            toScroll: this.$el,
            headlessMode: gcn.menu.structure.pages.length === 1,
            menuHasSideNav: thisMenuHasSideNav,
            containerWidth: this.$el.width(),
          });
          this.listenTo(
            this._quickNavView,
            BackboneEvents.GCNMenuQuickNavView.DidSelectMenuSection,
            (sectionId) => {
              self.scrollToSectionWithId(sectionId);
            },
          );
          this.$el.prepend(this._quickNavView.render().$el);
        }
      } else {
        // remove the margin-top that might be added because this section
        // doesn't contain quickNavView
        this.$el.css('margin-top', `0`);
      }

      if (this.menuPage.vendor) {
        // If any item in the sections contains hiddenState set to `ShownButUnavailable`,
        // and if the vendor has set `showVendorAsClosedIfNoMenuSectionsAreActive` to true,
        // we display a disclaimer that the vendor is closed.

        const vendorContainsMenuItemsShownButUnavailable = this.menuPage.sections.every(
          (section) => {
            return section.items.every((item) => {
              return item.get('hiddenState') === ItemHiddenStateOnMenu.ShownButUnavailable;
            });
          },
        );

        if (
          vendorContainsMenuItemsShownButUnavailable &&
          this.menuPage.vendor.get('showVendorAsClosedIfNoMenuSectionsAreActive')
        ) {
          const $vendorClosedDisclaimer = $(
            '<div class="closed-vendor-disclaimer-container"></div>',
          );
          this.$container.prepend($vendorClosedDisclaimer);
          const root = ReactDOM.createRoot($vendorClosedDisclaimer[0]);
          root.render(
            React.createElement(Disclaimer, {
              type: 'vendor-unavailable',
              text: str(Strings.VENDOR_UNAVAILABLE_MESSAGE),
            }),
          );
        }
      }
    }

    if (thisMenuHasSideNav) {
      this.$el.addClass('side-nav-menu');

      // Allow full device width if using side nav menu on Android
      if (window.platform === BitePlatform.KioskAndroid) {
        document.querySelector('meta[name=viewport]').setAttribute('content', 'user-scalable=no');
      }
    }

    // Show on First Page only
    if (gcn.menu.structure.pages[0].id === this.menuPage?.id && gcn.menu.getMenuDisclaimer()) {
      const $menuDisclaimer = $('<div class="menu-disclaimer-container"></div>');
      this.$container.prepend($menuDisclaimer);
      const root = ReactDOM.createRoot($menuDisclaimer[0]);
      root.render(
        React.createElement(Disclaimer, {
          type: 'menu-disclaimer',
          text: gcn.menu.getMenuDisclaimer(),
        }),
      );
    }

    if (gcn.menu && gcn.menu.has('settings')) {
      if (gcn.menu.settings.hasArr('footnotes')) {
        const footnotes = gcn.menu.settings.get('footnotes');
        _.each(footnotes, (text) => {
          self.$container.append(`<div class="footnote">${text}</div>`);
        });
      }
    }

    if (!window.webEnabled) {
      const $footer = $('<div class="footer-center"></div>');
      $footer.html(new GCNBiteLogoView().render().$el);
      this.$container.append($footer);
    }

    if (window.isFlash) {
      const flashPrefix = gcn.location.get('orgDomain') ? '' : `/${gcn.location.get('urlSlug')}`;
      if (window.webEnabled) {
        const $footerContainer = $('<div class="footer-container"></div>');
        this.$container.append($footerContainer);
        const root = ReactDOM.createRoot($footerContainer[0]);
        root.render(React.createElement(Footer, { org: gcn.org, flashPrefix }));
      } else {
        const policyLinks = [
          { title: 'Terms of Service', url: `${flashPrefix}/terms-of-service` },
          { title: 'Privacy Policy', url: `${flashPrefix}/privacy-policy` },
          {
            title: 'Online-Tracking Opt Out Guide',
            url: `${flashPrefix}/online-tracking-opt-out-guide`,
          },
          ...(gcn.org.thirdPartyPolicyLinks || []),
        ].map(({ title, url }) => {
          return `<a class="footer-link" href="${url}" target="_blank">${title}</a>`;
        });
        this.$container.append(`<div class="footer-center">${policyLinks.join('')}</div>`);
      }
    }

    if ($prevContainer) {
      $prevContainer.html(this.$container.children());
      this.$container = $prevContainer;
    }

    // Prevent scroll flicker caused by animated touch feedback.
    let scrollTimeout = null;
    this.$el.scroll(() => {
      self.$el.toggleClass('scrolling-disable-transitions', true);
      clearTimeout(scrollTimeout);
      scrollTimeout = setTimeout(() => {
        self.$el.toggleClass('scrolling-disable-transitions', false);
      }, 100);
    });

    this._lastVisibleSection = null;
    this.$el.scroll(() => {
      if (this._currentlyScrolling) {
        return;
      }
      // Calculate the visible section and notify.
      // TODO: Improve the algorithm so it always catches the last page, even if it's short.
      let visibleSection = null;
      let cumulativeHeight = 0;

      // Add some padding before to avoid triggering DidScrollSectionIntoView when section is not fully visible
      const scrollTop =
        this.$el.scrollTop() +
        parseInt(
          window.getComputedStyle(document.getElementsByClassName('column-container')[0])
            .paddingBottom,
        ) /
          3;
      this.menuPage.sections.forEach((section, i) => {
        if (section.attributes.hideFromMenu) {
          // Ignore hidden menu sections.
          return;
        }
        const $sectionEl = this.sectionViewsById[section.id].$el;
        if (cumulativeHeight > scrollTop) {
          return;
        }
        cumulativeHeight += $sectionEl.height();
        // Add an offset for the quick nav (if it's shown) but only once (i.e. on the first loop).
        // However, if the quick nav is sticky then it doesn't count towards the scrollTop value.
        const offsetForQuickNav =
          this._quickNavView &&
          !this._quickNavView.headlessMode &&
          i === 0 &&
          gcn.menu.shouldUseStickyQuickNavigation();
        if (offsetForQuickNav) {
          cumulativeHeight += Math.ceil(this._quickNavView.$el.outerHeight());
        }

        visibleSection = section;
      });
      if (visibleSection && visibleSection !== this._lastVisibleSection) {
        this.trigger(BackboneEvents.GCNMenuPageView.DidScrollSectionIntoView, visibleSection.id);
        this._lastVisibleSection = visibleSection;
      }
    });

    // override native scroller for samsung kiosk
    if (!gcn.customScroller && window.platform === BitePlatform.KioskSignageOsGarcon) {
      gcn.customScroller = new FTScroller(this.$el[0], {
        scrollbars: false,
        scrollingX: false,
        bouncing: false,
        scrollResponseBoundary: gcn.touchCalibrationConfig.scrollResponseBoundary,
        scrollBoundary: gcn.touchCalibrationConfig.scrollBoundary,
        maxFlingDuration: gcn.touchCalibrationConfig.maxFlingDuration,
      });

      gcn.customScroller.addEventListener('scroll', () => {
        if (this._currentlyScrolling) {
          return;
        }
        // Calculate the visible section and notify.
        // TODO: Improve the algorithm so it always catches the last page, even if it's short.
        let visibleSection = null;
        let cumulativeHeight = 0;

        // Add some padding before to avoid triggering DidScrollSectionIntoView when section is not fully visible
        const scrollTop =
          gcn.customScroller.scrollTop +
          parseInt(
            window.getComputedStyle(document.getElementsByClassName('column-container')[0])
              .paddingBottom,
          ) /
            3;
        this.menuPage.sections.forEach((section, i) => {
          const $sectionEl = this.sectionViewsById[section.id].$el;
          if (cumulativeHeight > scrollTop) {
            return;
          }
          cumulativeHeight += $sectionEl.height();
          // Add an offset for the quick nav (if it's shown) but only once (i.e. on the first loop).
          // However, if the quick nav is sticky then it doesn't count towards the scrollTop value.
          const offsetForQuickNav =
            this._quickNavView &&
            !this._quickNavView.headlessMode &&
            i === 0 &&
            !gcn.menu.shouldUseStickyQuickNavigation();

          if (offsetForQuickNav) {
            cumulativeHeight += Math.ceil(this._quickNavView.$el.outerHeight());
          }

          visibleSection = section;
        });

        if (visibleSection && visibleSection !== this._lastVisibleSection) {
          this.trigger(BackboneEvents.GCNMenuPageView.DidScrollSectionIntoView, visibleSection.id);
          this._lastVisibleSection = visibleSection;
        }
      });
    }

    this.setPageViewHeight();

    return this;
  },
});

import Handlebars from 'handlebars';
import _ from 'underscore';

import type { LocalizableString } from '@biteinc/common';
import { I9nSchemaBySystem, Log, PrintJob, Strings } from '@biteinc/common';
import type { OrderedPriceOption } from '@biteinc/core-react';
import { StringHelper, TimeHelper } from '@biteinc/core-react';
import {
  EpsonBarcodeFormat,
  EpsonBarcodeFormatHelper,
  FulfillmentMethodHelper,
  Gateway,
  GuestReceiptTextSize,
  IntegrationSystem,
  LanguageCode,
  MenuItemSaleUnitHelper,
  OrderClientState,
  PrinterBarcodeType,
  PrinterDestination,
} from '@biteinc/enums';

import type { GcnMenu, KioskMenuSettings } from '~/types';
import type { GcnAddonSet } from '~/types/gcn_menu';
import type { GcnOrder } from '~/types/gcn_order';
import type { GcnOrderedItem, GcnOrderedPriceOption } from '~/types/gcn_ordered_item';

import { GcnFulfillmentHelper } from '../../helpers';
import type { GcnError } from './gcn_bridge_interface';
import GcnHardwareManager from './gcn_hardware_manager';
import GcnHtml from './gcn_html';
import OrderHelper from './gcn_order_helper';
import { str } from './localization/localization';
import type GcnLocation from './models/gcn_location';
import { GCNOrder } from './models/gcn_order';
import Errors from './utils/errors';

function stringForOrder(order: GcnOrder, localizableString: LocalizableString): string {
  const receiptLocales = order.transactions?.map((transaction) => {
    return transaction.get('receiptLocale');
  });

  if (receiptLocales?.includes(LanguageCode.FR_CA)) {
    return str(localizableString, [], LanguageCode.FR_CA).toUpperCase();
  }

  return str(localizableString).toUpperCase();
}

function fulfillmentMethodStr(order: GcnOrder): string {
  return FulfillmentMethodHelper.isDineIn(order.get('fulfillmentMethod'))
    ? str(Strings.DINE_IN)
    : str(Strings.TO_GO);
}

type Options = {
  showPrices?: boolean; // show prices for ordered items if true
  largePrint?: boolean; // use Font B, size 3 to print ordered items
};

const PUNCHH_QR_CODE_SIZE = 6;

class GcnPrintJob extends PrintJob {
  private printBottomFulfillmentTemplate = `{{#if OUTPOST_NAME}}<br />Pick Up At: <B>{{{OUTPOST_NAME}}}<B>
    {{#if OUTPOST_INSTRUCTIONS_TEXT}}<br />{{{OUTPOST_INSTRUCTIONS_TEXT}}}
    {{/if}}{{/if}}`;

  constructor(private printerDestination: PrinterDestination) {
    super(GcnHardwareManager.getPrinterSpecForDestination(printerDestination)?.series);
  }

  toJSON(): PrintJob.Payload {
    return {
      destination: this.printerDestination,
      commands: this.getCommands(),
    };
  }

  getFormattedDateTimeString(timestamp: number | undefined): string {
    if (!timestamp) {
      return '';
    }
    return TimeHelper.format(timestamp, 'ddd MMM DD, YYYY h:mma');
  }

  addTemplatedContent(
    location: GcnLocation,
    order: GcnOrder,
    templateString: string,
    settings: KioskMenuSettings,
  ): this {
    // we perform all template related work in here
    // we convert `templateString` to DOM nodes and then add it to the print job via `processNode`

    const orderNumber = GcnFulfillmentHelper.getOrderNumber(location, order);
    const outpost = order.get('outpost');
    const vehicleDescription = GcnFulfillmentHelper.getVehicleDescription(
      order.get('guestVehicle'),
    );
    const { fullName, firstName, lastName } = StringHelper.getFirstAndLastNameFromFullName(
      order.get('guestName'),
    );

    const outpostName: string = outpost?.roomNumber
      ? `${outpost?.name} ${outpost.roomNumber}`
      : outpost?.name || '';
    const templateContent = {
      BRAND_NAME: settings.brandName || location.get('orgName'),
      GUEST_EMAIL: order.get('guestEmail'),
      GUEST_NAME: fullName,
      GUEST_FIRST_NAME: firstName,
      GUEST_LAST_NAME: lastName,
      GUEST_PHONE_NUMBER: order.get('guestPhoneNumber'),
      LOCATION_NAME: location.get('name'),
      LOCATION_PHONE_NUMBER: StringHelper.toFormattedPhoneNumber(location.get('phoneNumber') || ''),
      ORG_NAME: location.get('orgName'),
      ORDER_NUMBER: orderNumber,
      ORDER_PICKUP_TIME: this.getFormattedDateTimeString(order.get('pickupAt')),
      ORDER_TOTAL: GcnHtml.stringFromPrice(order.get('total')),
      ORDERED_AT: this.getFormattedDateTimeString(order.get('createdAt')),
      OUTPOST_NAME: outpostName,
      OUTPOST_INSTRUCTIONS_TEXT: outpost?.pickupInstructions,
      TABLE_NUMBER: order.get('tableNumber'),
      VEHICLE_DESCRIPTION: vehicleDescription,
    };

    const html = Handlebars.compile(templateString)(templateContent);

    const dom = new window.DOMParser().parseFromString(html, 'text/html');
    dom.body?.childNodes?.forEach((node: Node) => {
      this.processNode(node, 1, false);
    });
    return this;
  }

  addReceiptImage(base64ImageData: string): this {
    const imageWidth = 10;
    const imageHeight = 10;
    this.addNewLine();
    this.addImage(base64ImageData, imageWidth, imageHeight);
    this.addNewLine();

    return this;
  }

  addLocationHeader(
    order: GcnOrder,
    location: GcnLocation,
    settings: KioskMenuSettings,
    receiptHeaderBase64ImageData?: string,
  ): this {
    this.align(PrintJob.Epos2Align.EPOS2_ALIGN_CENTER);
    if (location.get('receiptHeaderText')) {
      this.addTemplatedContent(location, order, location.get('receiptHeaderText')!, settings);
    } else {
      this.addText(location.get('orgName'));
      this.addNewLine();
      this.setTextSize(2);
      this.addText(location.get('name'));
      this.setTextSize(1);
    }
    this.addNewLine();
    this.addNewLine();

    if (receiptHeaderBase64ImageData) {
      this.addReceiptImage(receiptHeaderBase64ImageData);
      this.addNewLine();
      this.addNewLine();
    }
    return this;
  }

  addReceiptHeader(location: GcnLocation, order: GcnOrder, settings: KioskMenuSettings): this {
    this.align(PrintJob.Epos2Align.EPOS2_ALIGN_CENTER);
    let dateLine = TimeHelper.format(order.get('createdAt'), 'ddd MMM DD, YYYY');
    const timeStr = TimeHelper.format(order.get('createdAt'), 'h:mma');
    for (let i = 0; i < 42 - dateLine.length - timeStr.length; i++) {
      dateLine += ' ';
    }
    this.addText(dateLine + timeStr);

    this.addNewLine();
    this.addNewLine();

    const diningOption = location.getDiningOption(order.get('fulfillmentMethod'));
    const orderNumber = GcnFulfillmentHelper.getOrderNumber(location, order);
    const { fulfillmentInstructions, customerIdentifierLabel, customerIdentifierValue } =
      GcnFulfillmentHelper.getFulfillmentInfo(location, order, orderNumber, diningOption);

    const topFulfillmentTemplate =
      diningOption?.printerReceiptFulfillmentTemplate ||
      `${customerIdentifierLabel}:\n\n<s3>${customerIdentifierValue}</s3>\n\n${fulfillmentInstructions.join(
        '\n',
      )}\n`;
    const headerFulfillmentTemplate = `${topFulfillmentTemplate}\n${this.printBottomFulfillmentTemplate}`;

    this.addTemplatedContent(location, order, headerFulfillmentTemplate, settings);
    this.addNewLine();
    this.addNewLine();
    this.align(PrintJob.Epos2Align.EPOS2_ALIGN_LEFT);
    return this;
  }

  addIncludeUtensilsBody(order: GcnOrder): this {
    // we add customer's include utensils decision if order has `includeUtensils` set
    if (order.get('includeUtensils') !== undefined) {
      const includeUtensilsDecision = order.get('includeUtensils')
        ? str(Strings.INCLUDE_UTENSILS)
        : str(Strings.DONT_INCLUDE_UTENSILS);

      this.addNewLine();
      this.addText(includeUtensilsDecision);
      this.addNewLine();
    }
    return this;
  }

  private addOrderedItems(
    menu: GcnMenu,
    location: GcnLocation,
    order: GcnOrder,
    orderedItems: GcnOrderedItem[],
    options: Options,
  ): this {
    const alignments = [
      PrintJob.Epos2Align.EPOS2_ALIGN_LEFT,
      PrintJob.Epos2Align.EPOS2_ALIGN_RIGHT,
    ];

    const orderedItemByI9nComboId = GCNOrder.groupItemsByI9nComboId(orderedItems) as Record<
      string,
      GcnOrderedItem[]
    >;
    const alreadyPrintedComboIds = new Set<string>();
    const printI9nComboByI9nComboId = new Map<
      string,
      {
        id: string;
        name: string;
        price: number;
        items: GcnOrderedItem[];
      }
    >();

    Object.entries(orderedItemByI9nComboId).forEach(([i9nComboId, comboItems]) => {
      const firstItem = comboItems[0];

      printI9nComboByI9nComboId.set(i9nComboId, {
        id: i9nComboId,
        name: firstItem.get('i9nComboName')!,
        price: firstItem.get('i9nComboPrice')!,
        items: comboItems,
      });
    });

    orderedItems.forEach((orderedItem) => {
      const i9nComboId = orderedItem.get('i9nComboId');

      // Not affected by a combo, just add it
      if (!i9nComboId) {
        this.addOrderedItem(menu, location, order, orderedItem, alignments, options);
        return;
      }

      // Already included in a combo, skip it
      if (alreadyPrintedComboIds.has(i9nComboId)) {
        return;
      }

      // First time finding an item in this combo, add the combo item
      const comboItem = printI9nComboByI9nComboId.get(i9nComboId)!;
      this.addI9nComboItem(menu, location, order, comboItem, alignments, options);
      alreadyPrintedComboIds.add(i9nComboId);
    });
    return this;
  }

  addOrderBody(menu: GcnMenu, location: GcnLocation, order: GcnOrder, options: Options): this {
    this.addText(stringForOrder(order, Strings.RECEIPT_ORDER));
    this.addNewLine();
    this.addText('===============');
    this.addNewLine();

    const orderedItemsByVendorId = GCNOrder.groupItemsByVendorId(order.orderedItems) as Record<
      string,
      GcnOrderedItem[]
    >;
    if (menu.vendors.length > 1 && _.size(orderedItemsByVendorId) > 0) {
      _.each(orderedItemsByVendorId, (orderedItems, vendorId) => {
        if (vendorId === GCNOrder.NoVendorId) {
          this.addNewLine();
          this.addText(stringForOrder(order, Strings.RECEIPT_SELF_SERVE));
          this.addNewLine();
        } else {
          const vendor = menu.getVendorWithId(vendorId);
          this.addVendorHeader(vendor?.get('pickUpText'));
        }

        this.addOrderedItems(menu, location, order, orderedItems, options);
      });
    } else {
      this.addOrderedItems(menu, location, order, order.orderedItems, options);
    }

    return this;
  }

  /**
   * @description adds print commands for total at the bottom of the order
   * (sub, discounts, tax, tips, etc) as well as information about the
   * payment that was used to complete the order and any refunds that might
   * be associated with it.
   */
  addOrderTotals(order: GcnOrder): this {
    const columnCount = 2;
    const alignments = [
      PrintJob.Epos2Align.EPOS2_ALIGN_LEFT,
      PrintJob.Epos2Align.EPOS2_ALIGN_RIGHT,
    ];

    this.addRepeatedText('_');
    this.addNewLine();
    this.addNewLine();
    this.startNewTableCommand(columnCount, ['  '], alignments);
    this.addTextRow([
      [stringForOrder(order, Strings.RECEIPT_SUB_TOTAL)],
      [this.displayPrice(order.get('subTotal'))],
    ]);
    if ((order.get('discountTotal') || 0) > 0) {
      order.getDiscountNamesWithAmounts().forEach(({ name, amount }) => {
        this.addTextRow([
          [name || stringForOrder(order, Strings.RECEIPT_DISCOUNTS)],
          [`-${this.displayPrice(amount)}`],
        ]);
      });
    }
    this.addEmptyRow();
    if (order.has('taxTotal') && gcn.menu.settings.get('includeTaxTotalOnReceipts')) {
      this.addTextRow([
        [stringForOrder(order, Strings.RECEIPT_TAX_TOTAL)],
        [this.displayPrice(order.get('taxTotal'))],
      ]);
    }
    if ((order.get('tipTotal') || 0) > 0) {
      this.addTextRow([
        [stringForOrder(order, Strings.RECEIPT_TIP_THANKS)],
        [this.displayPrice(order.get('tipTotal')!)],
      ]);
    }
    if ((order.get('serviceChargeTotal') || 0) > 0) {
      this.addTextRow([
        [stringForOrder(order, Strings.RECEIPT_SERVICE_CHARGE)],
        [this.displayPrice(order.get('serviceChargeTotal')!)],
      ]);
    }
    this.finishTableCommand();

    if (order.has('total')) {
      this.addTextStyle(-1, 0, 1, PrintJob.Epos2Color.EPOS2_COLOR_1);
      this.startNewTableCommand(columnCount, ['  '], alignments);
      this.addTextRow([
        [stringForOrder(order, Strings.RECEIPT_ORDER_TOTAL)],
        [this.displayPrice(order.get('total'))],
      ]);
      this.addEmptyRow();

      order.transactions?.forEach((transaction) => {
        const tenders = transaction.getNonDiscountTenders();
        tenders.forEach((tender) => {
          this.addTextRow([[tender.cardSchemeName], [this.displayPrice(tender.amount)]]);
        });

        const lastFour = transaction.getLastFour();
        if (lastFour) {
          let entryMethod = '';
          if (transaction.hasKnownCardEntryMethod()) {
            entryMethod = ` (${stringForOrder(order, transaction.getCardEntryMethodString())})`;
          }
          this.addTextRow([
            [
              `   ${stringForOrder(
                order,
                Strings.RECEIPT_CARD_NUMBER,
              )}: *${lastFour}${entryMethod}`,
            ],
            [''],
          ]);
        }
        if (transaction.get('authCode')) {
          this.addTextRow([
            [
              `   ${stringForOrder(order, Strings.RECEIPT_AUTHORIZATION)}: ${transaction.get(
                'authCode',
              )}`,
            ],
            [''],
          ]);
        }
        this.addTextRow([
          [
            `   ${stringForOrder(order, Strings.RECEIPT_REFERENCE)}: ${transaction.get(
              'clientId',
            )}`,
          ],
          [''],
        ]);
        this.addEmptyRow();

        const unrefundedAmount = order.getUnrefundedAmountForTransaction(transaction);
        const refunds = order.get('refunds')?.filter((refund) => {
          return refund.transactionId === transaction.id;
        });
        refunds?.forEach((refund) => {
          // TODO: Use location's timezone
          const refundTime = TimeHelper.format(refund.createdAt, 'MM/DD/YY h:mma');
          this.addTextRow([
            [`${stringForOrder(order, Strings.RECEIPT_REFUNDED)} (${refundTime}):`],
            [`-${this.displayPrice(refund.total)}`],
          ]);
        });

        if (refunds?.length) {
          this.addTextRow([
            [`${stringForOrder(order, Strings.RECEIPT_REMAINING_BALANCE)}: `],
            [this.displayPrice(unrefundedAmount)],
          ]);
          this.addEmptyRow();
        }
      });

      this.finishTableCommand();
      this.addTextStyle(-1, 0, 0, PrintJob.Epos2Color.EPOS2_COLOR_1);
    }
    return this;
  }

  // RPOS Check ID: 15 characters, prepended with 001, padded with 0's for a total of 15 chars
  // Therefore paddedCheckId should be 12 characters.
  private formatCode128PosCheckId(paddedCheckId: string): string {
    return `001${paddedCheckId}`;
  }

  // For reference on check digit calculation see:
  // https://www.gs1.org/services/how-calculate-check-digit-manually
  getCheckDigit(barcodeString: string): number {
    const multipliers = [3, 1];
    let sum = 0;
    for (let i = barcodeString.length - 1; i >= 0; i--) {
      const multiplier = multipliers[i % 2];
      sum += multiplier * parseInt(barcodeString[i], 10);
    }
    return Math.ceil(sum / 10) * 10 - sum;
  }

  addOrderNumberBarcode(location: GcnLocation, order: GcnOrder): this {
    if (!location.get('printOrderNumberBarcode')) {
      return this;
    }

    let posCheckId = null;
    const posSystemsThatSupportBarcodePrinting: IntegrationSystem.Pos[] = [
      IntegrationSystem.Omnivore,
      IntegrationSystem.Infor,
      IntegrationSystem.NcrRadiant,
    ];
    posSystemsThatSupportBarcodePrinting.forEach((system) => {
      const i9nSchema = I9nSchemaBySystem[system];
      _.each(order.getI9nDatasForSystem(system), (i9nData) => {
        if (i9nData.sendData) {
          posCheckId = i9nData.sendData[i9nSchema.posCheckIdKey];
        }
      });
    });

    if (posCheckId) {
      const barcodeFormat = location.has('orderNumberBarcodeFormat')
        ? location.get('orderNumberBarcodeFormat')!
        : EpsonBarcodeFormat.EAN8;
      let totalBarcodeLength = 12;
      switch (barcodeFormat) {
        case EpsonBarcodeFormat.EAN8:
        case EpsonBarcodeFormat.JAN8:
          totalBarcodeLength = 7;
          break;
        case EpsonBarcodeFormat.UPC_E:
          totalBarcodeLength = 6;
          break;
        case EpsonBarcodeFormat.CODE128:
        case EpsonBarcodeFormat.UPC_A:
          totalBarcodeLength = 12;
          break;
        case EpsonBarcodeFormat.EAN13:
        case EpsonBarcodeFormat.JAN13:
          totalBarcodeLength = 13;
          break;
      }

      let barcodeString = `${posCheckId}`;
      // Pad it to total length with leading zeros
      const paddingLength = totalBarcodeLength - barcodeString.length;
      for (let i = 0; i < paddingLength; i++) {
        barcodeString = `0${barcodeString}`;
      }

      // append start character and check digit
      if (EpsonBarcodeFormat.CODE128 === barcodeFormat) {
        barcodeString = this.formatCode128PosCheckId(barcodeString);
        barcodeString = `{A${barcodeString}${this.getCheckDigit(barcodeString)}`;
      }
      this.addNewLine();
      this.align(PrintJob.Epos2Align.EPOS2_ALIGN_CENTER);
      this.addText(`${stringForOrder(order, Strings.RECEIPT_YOUR_CHECK_NUMBER)} ${posCheckId}.`);
      this.addNewLine();
      this.addNewLine();
      this.addBarcode(barcodeString, barcodeFormat, PrintJob.Epos2Hri.EPOS2_HRI_BELOW);
      this.addNewLine();
    }
    return this;
  }

  private displayPrice(priceInCents: number | string): string {
    if (_.isNumber(priceInCents)) {
      const dollars: number = priceInCents / 100;
      return `$${dollars.toFixed(2).toString()}`;
    }
    return priceInCents;
  }

  private addVendorHeader(pickUpText?: string): this {
    this.addNewLine();
    if (pickUpText) {
      const vendorInstructionsSize = gcn.menu.settings.get('vendorInstructionsSize');
      switch (vendorInstructionsSize) {
        case GuestReceiptTextSize.Small:
          this.setTextSize(1);
          break;
        case GuestReceiptTextSize.Medium:
          this.setTextSize(2);
          break;
        case GuestReceiptTextSize.Large:
        default:
          this.setTextSize(3);
          break;
      }

      this.addText(pickUpText);
      this.setTextSize(1);
      this.addNewLine();
    }
    this.addNewLine();
    return this;
  }

  private getBarcode(barcodeString: string | undefined): string {
    if (!barcodeString) {
      return '';
    }

    const splitBarcodeString = barcodeString.split(',');
    return splitBarcodeString[0];
  }

  private addModifiers(
    menu: GcnMenu,
    location: GcnLocation,
    orderedPriceOption: OrderedPriceOption,
    columnCount: number,
    alignments: PrintJob.Epos2Align[],
    options: Options,
    parentOrderedItemQuantity: number,
    spacings: string[] = ['  '],
    level: number = 0,
  ): this {
    const shouldPrintOrderedItemPluInline = location.get('printOrderedItemPluWithOrderedItems');
    let hasStartedTable = false;

    const printDefaultMods = gcn.menu.settings.get('printDefaultMods');
    const printZeroDollarMods = gcn.menu.settings.get('printZeroDollarMods');
    const printDeselectedMods = gcn.menu.settings.get('printDeselectedMods');
    const printModCodes = gcn.menu.settings.get('printModCodes');

    orderedPriceOption.addonSets?.forEach((orderedModGroup) => {
      // Mod group maybe undefined if we are reprinting an old order
      const modGroup = menu.getAddonSetWithId(orderedModGroup._id) as GcnAddonSet | undefined;

      const selectedMods = (orderedModGroup.items || []).filter((orderedMod) => {
        // Skip ignored mods for the purposes of printing a receipt.
        return OrderHelper.shouldPrintOrderedModInModGroup(orderedMod, modGroup);
      });
      const deselectedMods = orderedModGroup.deselectedItems || [];

      [selectedMods, deselectedMods].forEach((orderedMods, isDeselected) => {
        orderedMods.forEach((orderedMod) => {
          if (level === 0 && !hasStartedTable) {
            hasStartedTable = true;
            if (options.largePrint) {
              this.setFont(PrintJob.Epos2Font.EPOS2_FONT_B);
              this.setTextSize(3);
            }
            this.startNewTableCommand(columnCount, spacings, alignments);
          }

          let subModsPrefix = '';
          const subMods = modGroup?.subModsInSet(orderedMod._id);
          if (printModCodes && subMods?.length) {
            subModsPrefix = subMods.map((subMod) => subMod.name).join(', ');
          }
          // Add space
          if (subModsPrefix) {
            subModsPrefix += ' ';
          }

          if (
            !printDefaultMods &&
            modGroup?.addonIsSelectedByDefault(orderedMod._id) &&
            !isDeselected
          ) {
            this.addModifiers(
              menu,
              location,
              orderedMod.priceOption,
              columnCount,
              alignments,
              options,
              parentOrderedItemQuantity,
              spacings,
              level + 1,
            );
            return;
          }
          if (!printZeroDollarMods && orderedMod.priceOption.price === 0) {
            this.addModifiers(
              menu,
              location,
              orderedMod.priceOption,
              columnCount,
              alignments,
              options,
              parentOrderedItemQuantity,
              spacings,
              level + 1,
            );
            return;
          }
          if (!printDeselectedMods && isDeselected) {
            // Deselected mods only have mod cods and what not as nested children
            return;
          }

          let modName = orderedMod.name;
          let barcode = '';
          if (orderedMod.priceOption.barcode && orderedMod.priceOption.price !== 0) {
            // printing only the first barcode after splitting by comma
            barcode = `[${this.getBarcode(orderedMod.priceOption.barcode)}]`;
          }
          // Generate the deselected pre-text if applicable and add to the name
          if (isDeselected) {
            modName = str(Strings.DESELECTED_MOD_PREFIX, [modName]);
          }

          // Do not add sub mods for deselected mods
          // As they are probably 'No' codes from brink and we handle that with
          // Strings.DESELECTED_MOD_PREFIX above
          if (!isDeselected) {
            modName = `${subModsPrefix}${modName}`;
          }

          // Only add if not deselected or we allow printing of deselected mods
          // Print a + for each level down that we go
          const indent = ` ${'+'.repeat(level + 1)}`;
          let modLine = `${indent} ${modName}`;
          // We do not want to show the price for deselected mods
          if (options.showPrices && !isDeselected) {
            modLine = `${indent} ${orderedMod.priceOption.quantity}× ${modName}`;
            const orderedModPrice = parentOrderedItemQuantity * orderedMod.priceOption.price;
            if (shouldPrintOrderedItemPluInline) {
              this.addTextRow([[modLine, '   '], [barcode], [this.displayPrice(orderedModPrice)]]);
            } else {
              this.addTextRow([[modLine, '   '], [this.displayPrice(orderedModPrice)]]);
            }
          } else {
            if (shouldPrintOrderedItemPluInline) {
              this.addTextRow([[modLine, '   '], [barcode], ['']]);
            } else {
              this.addTextRow([[modLine, '   '], ['']]);
            }
          }

          // Deselected mods only have mod cods and what not as nested children
          if (!isDeselected) {
            this.addModifiers(
              menu,
              location,
              orderedMod.priceOption,
              columnCount,
              alignments,
              options,
              parentOrderedItemQuantity,
              spacings,
              level + 1,
            );
          }
        });
      });
    });

    if (hasStartedTable && level === 0) {
      this.finishTableCommand();
      if (options.largePrint) {
        this.setTextSize(1);
        this.setFont(PrintJob.Epos2Font.EPOS2_FONT_A);
      }
    }

    return this;
  }

  private addI9nComboItem(
    menu: GcnMenu,
    location: GcnLocation,
    order: GcnOrder,
    i9nComboItem: {
      id: string;
      name: string;
      price: number;
      items: GcnOrderedItem[];
    },
    alignments: PrintJob.Epos2Align[],
    options: Options,
  ): this {
    const columnCount = options.showPrices ? 2 : 1;
    this.startNewTableCommand(columnCount, ['  '], alignments);

    if (options.showPrices) {
      this.addTextRow([[i9nComboItem.name, ''], [this.displayPrice(i9nComboItem.price)]]);
    } else {
      this.addTextRow([[i9nComboItem.name, ' '], ['']]);
    }

    this.finishTableCommand();

    i9nComboItem.items.forEach((orderedItem) => {
      this.addOrderedItem(menu, location, order, orderedItem, alignments, options);
    });

    this.addNewLine();
    return this;
  }

  private addOrderedItem(
    menu: GcnMenu,
    location: GcnLocation,
    order: GcnOrder,
    orderedItem: GcnOrderedItem,
    alignments: PrintJob.Epos2Align[],
    options: Options,
  ): this {
    const shouldPrintOrderedItemPluInline = location.get('printOrderedItemPluWithOrderedItems');

    if (options.largePrint) {
      this.setFont(PrintJob.Epos2Font.EPOS2_FONT_B);
      this.setTextSize(3);
    }

    const columnCount = options.showPrices ? 2 : 1;
    // `shouldPrintOrderedItemPluInline` requires an extra column for the PLUs
    const orderedItemColumnCount = shouldPrintOrderedItemPluInline ? columnCount + 1 : columnCount;

    const spacings = shouldPrintOrderedItemPluInline ? ['  ', '  '] : ['  '];

    this.startNewTableCommand(orderedItemColumnCount, spacings, alignments);

    const quantity = orderedItem.orderedPO.get('quantity');
    // If our priceOption has a name we will use that on the price line
    let priceLineName = orderedItem.get('name');
    let orderedItemPlu = orderedItem.orderedPO.get('barcode') || '';
    if (orderedItemPlu) {
      // printing only the first barcode after splitting by comma
      orderedItemPlu = `[${this.getBarcode(orderedItemPlu)}]`;
    }
    if (orderedItem.orderedPO.get('name')) {
      if (shouldPrintOrderedItemPluInline) {
        this.addTextRow([[orderedItem.get('name')], [''], ['']]);
      } else {
        this.addTextRow([[orderedItem.get('name')], ['']]);
      }
      priceLineName = ` ${orderedItem.orderedPO.get('name')} `;
    }

    if (options.showPrices) {
      const priceLineNameWithQuantity = `${quantity}× ${priceLineName}`;
      if (shouldPrintOrderedItemPluInline) {
        this.addTextRow([
          [priceLineNameWithQuantity, ''],
          [orderedItemPlu],
          [this.displayPrice(orderedItem.orderedPO.getPrice())],
        ]);
      } else {
        this.addTextRow([
          [priceLineNameWithQuantity, ''],
          [this.displayPrice(orderedItem.orderedPO.getPrice())],
        ]);
      }

      this.addUnitPriceLine(orderedItem.orderedPO, shouldPrintOrderedItemPluInline);
    } else {
      if (shouldPrintOrderedItemPluInline) {
        this.addTextRow([[priceLineName, ' '], [orderedItemPlu], ['']]);
      } else {
        this.addTextRow([[priceLineName, ' '], ['']]);
      }
    }

    this.finishTableCommand();

    if (options.largePrint) {
      this.setTextSize(1);
      this.setFont(PrintJob.Epos2Font.EPOS2_FONT_A);
    }

    this.addModifiers(
      menu,
      location,
      orderedItem.get('priceOption'),
      orderedItemColumnCount,
      alignments,
      options,
      quantity,
      spacings,
    );

    if (orderedItem.get('specialRequest')) {
      this.addTextStyle(-1, 0, 1, PrintJob.Epos2Color.EPOS2_COLOR_2);
      this.startNewTableCommand(columnCount, ['  '], alignments);
      this.addTextRow([
        [` *** ${stringForOrder(order, Strings.RECEIPT_SPECIAL_REQUEST)} ***`],
        [''],
      ]);
      this.addTextRow([[` * ${orderedItem.get('specialRequest')}`], ['']]);
      this.finishTableCommand();
      this.addTextStyle(-1, 0, 0, PrintJob.Epos2Color.EPOS2_COLOR_1);
    }

    this.addNewLine();
    return this;
  }

  private addUnitPriceLine(
    priceOption: GcnOrderedPriceOption,
    shouldPrintOrderedItemPluInline: boolean = false,
  ): this {
    const weight = priceOption.get('weight'); // weight in either oz or lbs
    const saleUnit = priceOption.get('saleUnit'); // is probably set if weight is set
    const quantity = priceOption.get('quantity'); // if weight is set this should just be 1
    const unitPriceStr = this.displayPrice(priceOption.get('unitPrice'));

    if (weight && saleUnit) {
      const saleUnitStr = MenuItemSaleUnitHelper.notation(saleUnit) || 'ea';
      const weightStr = (weight * quantity).toFixed(2).toString();
      const plural = weightStr !== '1.00' ? 's' : ' ';

      if (shouldPrintOrderedItemPluInline) {
        // we need three columns
        this.addTextRow([
          [`  ${weightStr} ${saleUnitStr}${plural}    @ ${unitPriceStr}/${saleUnitStr}`, ''],
          [''],
          [''],
        ]);
        return this;
      }
      this.addTextRow([
        [`  ${weightStr} ${saleUnitStr}${plural}    @ ${unitPriceStr}/${saleUnitStr}`, ''],
        [''],
      ]);
      return this;
    }

    if (quantity !== 1) {
      if (shouldPrintOrderedItemPluInline) {
        // we need three columns
        this.addTextRow([[`  @ ${unitPriceStr}/ea`, ''], [''], ['']]);
        return this;
      }
      this.addTextRow([[`  @ ${unitPriceStr}/ea`, ''], ['']]);
      return this;
    }
    return this;
  }

  addNotReceiptFooter(order: GcnOrder): this {
    if (!order.isPaidFor()) {
      this.align(PrintJob.Epos2Align.EPOS2_ALIGN_CENTER);
      this.addNewLine();
      this.addText(stringForOrder(order, Strings.RECEIPT_NOT_RECEIPT));
      this.addNewLine();
      this.align(PrintJob.Epos2Align.EPOS2_ALIGN_LEFT);
    }
    return this;
  }

  addOrderIDFooter(order: GcnOrder): this {
    const footerLines: string[] = [];
    // Print all the order/check ids from the POSs.
    Object.values(I9nSchemaBySystem).forEach((i9nSchema) => {
      if (i9nSchema.type !== 'pos') {
        return;
      }
      if (!i9nSchema.posCheckIdKey) {
        return;
      }

      _.each(order.getI9nDatasForSystem(i9nSchema.system), (i9nData) => {
        if (i9nData.sendData && (i9nData.sendData[i9nSchema.posCheckIdKey] || '').length) {
          footerLines.push(
            `${stringForOrder(order, Strings.RECEIPT_CHECK_ID)}: ${
              i9nData.sendData[i9nSchema.posCheckIdKey]
            }`,
          );
        }
      });
    });

    _.each(order.getPunchhI9nData(), (punchhData) => {
      const punchhSendData = punchhData.sendData || {};
      if (punchhSendData.transaction_no) {
        footerLines.push(
          `${stringForOrder(order, Strings.RECEIPT_TRANS_NO)}: ${punchhSendData.transaction_no}`,
        );
      }
    });

    if (footerLines.length) {
      this.addNewLine();
      _.each(footerLines, (footerLine) => {
        this.addText(footerLine);
        this.addNewLine();
      });
      this.addNewLine();
    }
    return this;
  }

  addFooter(
    location: GcnLocation,
    order: GcnOrder,
    printReceiptFooterText: boolean = false,
    extraFooterLines: string[] = [],
    settings: KioskMenuSettings,
    receiptFooterBase64ImageData?: string,
  ): this {
    this.align(PrintJob.Epos2Align.EPOS2_ALIGN_CENTER);
    if (location.get('receiptFooterText') && printReceiptFooterText) {
      this.addTemplatedContent(location, order, location.get('receiptFooterText')!, settings);
    }

    if (extraFooterLines.length) {
      this.addNewLine();
      this.addNewLine();
      extraFooterLines.forEach((line) => {
        this.addText(line);
        this.addNewLine();
      });
    }

    if (receiptFooterBase64ImageData) {
      this.addReceiptImage(receiptFooterBase64ImageData);
      this.addNewLine();
    }

    this.addNewLine();
    this.addText('Powered by Bite');

    // Add a larger than expected number of empty lines at the bottom of the receipt to avoid the
    // paper getting stuck in the stands where the printer is sitting behind an additional cover
    // that's part of the stand.
    _.times(10, () => {
      this.addNewLine();
    });

    this.cut();
    return this;
  }

  addPunchhBarcode(location: GcnLocation, punchhKey: string): this {
    if (!gcn.loyaltyManager.shouldPrintBarcode()) {
      return this;
    }

    const punchhSettings = location.getPunchhSettings();
    if (!punchhSettings?.printBarcodes) {
      return this;
    }

    this.align(PrintJob.Epos2Align.EPOS2_ALIGN_CENTER);
    if (punchhSettings.header) {
      this.addText(punchhSettings.header);
      this.addNewLine();
      this.addNewLine();
    }

    if (
      (
        gcn.location.getLoyaltyIntegration() as GcnLocation.PartialPunchhLoyaltyI9n<IntegrationSystem.Punchh>
      ).printerBarcodeType === PrinterBarcodeType.Barcode
    ) {
      this.addBarcode(
        punchhKey,
        punchhKey.length === 12 ? EpsonBarcodeFormat.EAN13 : EpsonBarcodeFormat.UPC_A,
        PrintJob.Epos2Hri.EPOS2_HRI_BELOW,
      );
    } else {
      this.addQRCode(punchhKey, PUNCHH_QR_CODE_SIZE);
    }
    const punchhTrailerFields = [
      'trailer1',
      'trailer2',
      'trailer3',
      'trailer4',
      'trailer5',
    ] as const;
    punchhTrailerFields.forEach((trailerField) => {
      const trailerLine = punchhSettings[trailerField];
      if (trailerLine) {
        this.addNewLine();
        this.addText(trailerLine);
      }
    });
    this.addNewLine();
    return this;
  }

  addSmgSurveyCode(location: GcnLocation, order: GcnOrder): this {
    const smgOrderData = order.getI9nDatasForSystem(IntegrationSystem.Smg)[0];
    if (!smgOrderData?.validationData!.smgSurveyCode) {
      return this;
    }

    const smgSettings = location.getSmgSettings()!;

    this.align(PrintJob.Epos2Align.EPOS2_ALIGN_CENTER);
    if (smgSettings?.header?.length) {
      this.addNewLine();
      this.addText(smgSettings.header);
      this.addNewLine();
      this.addNewLine();
    }

    this.addNewLine();
    this.addText(smgOrderData.validationData.smgSurveyCode);
    this.addNewLine();

    if (smgSettings?.footer?.length) {
      this.addText(smgSettings.footer);
      this.addNewLine();
      this.addNewLine();
    }
    this.align(PrintJob.Epos2Align.EPOS2_ALIGN_LEFT);

    return this;
  }

  addFailedSendHeader(order: GcnOrder, sendKioskOrderErr?: GcnError): this {
    let errorMessage: string = stringForOrder(order, Strings.RECEIPT_FAILED);

    if (
      Errors.isPOSValidation86dError(sendKioskOrderErr?.code) &&
      !order.isBiteFutureOrder() &&
      !order.isCheckinOrder()
    ) {
      errorMessage = stringForOrder(order, Strings.RECEIPT_FAILED_ITEM_86D);
    }

    if (order.transactions?.length) {
      errorMessage += `\n\n${stringForOrder(order, Strings.PAYMENT_WAS_TAKEN)}`;
    }

    const clientState = order.get('clientState');
    if (
      OrderClientState.KitchenSendError === clientState ||
      OrderClientState.MaitredSendError === clientState ||
      sendKioskOrderErr
    ) {
      this.align(PrintJob.Epos2Align.EPOS2_ALIGN_CENTER);
      this.setTextSize(2);
      this.setFont(PrintJob.Epos2Font.EPOS2_FONT_B);
      this.addText(errorMessage);
      this.addNewLine();
      this.addNewLine();
      this.addNewLine();
      this.setFont(PrintJob.Epos2Font.EPOS2_FONT_A);
      this.setTextSize(1);
    }
    return this;
  }

  /**
   * This prints out the QRCode containing the PLUs of all the orderedItems
   * at the bottom of the receipt. Same function is duplicated in `expo_print_job.ts`
   */
  addOrderQRCode(menu: GcnMenu, location: GcnLocation, order: GcnOrder): this {
    if (!location.get('printQRCodeWithOrderedItemsPlus')) {
      return this;
    }

    const qrCodePrefixFor12DigitPlu = location.get('qrCodePrefixFor12DigitPlu');
    const qrCodePrefixFor6DigitPlu = location.get('qrCodePrefixFor6DigitPlu');
    const qrCodePrefixFor39DigitPlu = location.get('qrCodePrefixFor39DigitPlu');
    const qrCodePrefixForRestOfPlu = location.get('qrCodePrefixForRestOfPlu');

    const orderedItemBarcodeBlocks: string[] = [];
    const noBarcodeItemNames: string[] = [];
    // converts the hexCoded delimiter to `\r\n`
    const qrCodeItemDelimiter =
      location.get('qrCodeItemDelimiter')?.replace(/\\x([0-9A-F]{2})/gi, (chars, hex) => {
        return String.fromCharCode(parseInt(hex, 16));
      }) || '';
    const qrCodeShouldContainFreeMods = !!location.get('qrCodeShouldContainFreeMods');
    order.orderedItems.forEach((orderedItem) => {
      const barcodes: string[] = [];
      const orderedItemBarcode = this.getBarcode(orderedItem.orderedPO.get('barcode'));
      const orderedItemQuantity = orderedItem.orderedPO.get('quantity') || 1;
      if (orderedItemBarcode) {
        barcodes.push(orderedItemBarcode);

        // Skip ignored mods for the purposes of printing a qr code.
        OrderHelper.getAllSelectedOrderedMods(menu, orderedItem.get('priceOption'), true).forEach(
          (orderedMod) => {
            if (orderedMod.priceOption.barcode) {
              // If the mod is free, we only print it if the location is configured to print free mods
              const canPrintThisMod =
                orderedMod.priceOption.price !== 0 ? true : qrCodeShouldContainFreeMods;
              if (canPrintThisMod) {
                Array.from(Array(orderedMod.priceOption.quantity).keys()).forEach(() => {
                  barcodes.push(orderedMod.priceOption.barcode!);
                });
              }
            } else {
              noBarcodeItemNames.push(`${orderedMod.priceOption.quantity}× ${orderedMod.name}`);
            }
          },
        );
      } else {
        noBarcodeItemNames.push(
          `${orderedItem.get('priceOption').quantity}× ${orderedItem.get('name')}`,
        );
      }

      if (barcodes.length) {
        // Build the payload for a single item's barcodes by first adding the prefix and
        // then joining with a delimiter
        const singleItemBarcodeBlock = barcodes
          .map((barcode) => {
            if (!location.get('qrCodeItemNeedsBarcodeTypePrefix')) {
              return barcode;
            }
            if (barcode.length === 12) {
              return `${qrCodePrefixFor12DigitPlu}${barcode}`; // UPC A
            }
            if (barcode.length === 6) {
              return `${qrCodePrefixFor6DigitPlu}${barcode}`; // UPC E
            }
            if (barcode.length === 39) {
              return `${qrCodePrefixFor39DigitPlu}${barcode}`; // Code 39
            }
            return `${qrCodePrefixForRestOfPlu}${barcode}`; // Code 128
          })
          .map((barcodeWithPrefix) => {
            return `${barcodeWithPrefix}${qrCodeItemDelimiter}`;
          })
          .join('');
        Array.from(Array(orderedItemQuantity).keys()).forEach(() => {
          orderedItemBarcodeBlocks.push(singleItemBarcodeBlock);
        });
      }
    });

    if (orderedItemBarcodeBlocks.length) {
      const kQrCodePayloadMaxLength = 1400;
      let currentQrCodePayload = '';

      orderedItemBarcodeBlocks.forEach((orderedItemBarcodeBlock, i) => {
        // Start each payload with header/delimiter
        if (!currentQrCodePayload.length) {
          currentQrCodePayload += location.get('qrCodeHeader') || '';
          if (currentQrCodePayload.length) {
            currentQrCodePayload += qrCodeItemDelimiter;
          }
        }
        // Add the current item to the payload
        currentQrCodePayload += orderedItemBarcodeBlock;

        // Do we need to print the QR code now or will the next item still fit in?
        let shouldAddQrCode = false;
        // This is the last block so we definitely need to print a qr code
        if (i === orderedItemBarcodeBlocks.length - 1) {
          shouldAddQrCode = true;
        } else {
          const nextBlock = orderedItemBarcodeBlocks[i + 1];
          // The next block will spill over
          if (currentQrCodePayload.length + nextBlock.length > kQrCodePayloadMaxLength) {
            shouldAddQrCode = true;
          }
        }

        if (shouldAddQrCode) {
          this.align(PrintJob.Epos2Align.EPOS2_ALIGN_CENTER);
          this.addNewLine();
          this.addQRCode(currentQrCodePayload, 3);
          this.addNewLine();
          this.align(PrintJob.Epos2Align.EPOS2_ALIGN_LEFT);

          currentQrCodePayload = '';
        }
      });
    }

    if (noBarcodeItemNames.length) {
      // print the menu item names that do not have barcodes
      this.addTextStyle(-1, 0, 1, PrintJob.Epos2Color.EPOS2_COLOR_1);
      this.addRepeatedText('_');
      this.addNewLine();
      this.addNewLine();
      this.addText(str(Strings.RECEIPT_MENU_ITEMS_WITHOUT_BARCODE));
      this.addNewLine();
      noBarcodeItemNames.forEach((orderedItemDescription) => {
        this.addText(`${orderedItemDescription}`);
        this.addNewLine();
      });
      this.addNewLine();
      this.addRepeatedText('_');
      this.addNewLine();
      this.addTextStyle(-1, 0, 0, PrintJob.Epos2Color.EPOS2_COLOR_1);
    }

    return this;
  }
}

module GcnPrintJob {
  export function receiptPrintJob(
    location: GcnLocation,
    menu: GcnMenu,
    order: GcnOrder,
    options: {
      printedFromAdmin?: true;
      extraFooterLines?: string[];
      sendKioskOrderErr?: GcnError;
      receiptHeaderBase64ImageData?: string;
      receiptFooterBase64ImageData?: string;
    } = {},
  ): PrintJob.Payload {
    const printJob = new GcnPrintJob(PrinterDestination.Receipt);

    if (!options.printedFromAdmin) {
      printJob.addFailedSendHeader(order, options.sendKioskOrderErr);
    }

    printJob.addLocationHeader(
      order,
      location,
      menu.get('settings'),
      options.receiptHeaderBase64ImageData,
    );
    printJob.addReceiptHeader(location, order, menu.get('settings'));
    if (!options.printedFromAdmin) {
      printJob.addNewLine();
      printJob.addNewLine();

      if (order.isPaidAtCashier()) {
        printJob.align(PrintJob.Epos2Align.EPOS2_ALIGN_CENTER);
        printJob.addTextStyle(-1, 0, 1, PrintJob.Epos2Color.EPOS2_COLOR_2);
        printJob.addText(stringForOrder(order, Strings.RECEIPT_PAY_AT_CASHIER));
        printJob.addNewLine();
        printJob.addNewLine();
        printJob.align(PrintJob.Epos2Align.EPOS2_ALIGN_LEFT);
        printJob.addTextStyle(-1, 0, 0, PrintJob.Epos2Color.EPOS2_COLOR_1);
      }
    }

    printJob.addOrderBody(menu, location, order, { showPrices: true });
    printJob.addIncludeUtensilsBody(order);
    printJob.addOrderTotals(order);
    printJob.addOrderNumberBarcode(location, order);

    printJob.addOrderQRCode(menu, location, order);

    _.each(order.getPunchhI9nData(), (punchhData) => {
      const punchhValidationData = punchhData.validationData || {};
      if (punchhValidationData.punchhKey && !order.attributes.hasLoyaltyProgram) {
        printJob.addPunchhBarcode(location, punchhValidationData.punchhKey);
      }
    });

    printJob.addSmgSurveyCode(location, order);

    if (gcn.menu.settings.get('printTransactionReceiptText')) {
      order.transactions?.forEach((transaction) => {
        if (
          transaction.has('receiptText') &&
          transaction.get('gateway') !== Gateway.VerifonePointSca
        ) {
          printJob.addText(transaction.get('receiptText')!);
          printJob.addNewLine();
          printJob.addNewLine();
        }
      });
    }

    if (!options.printedFromAdmin) {
      printJob.addNotReceiptFooter(order);
    }
    printJob.addOrderIDFooter(order);

    const loyaltyPointsEarnedText = str(Strings.RECEIPT_LOYALTY_POINTS_EARNED_TEXT);
    if (order.get('loyaltyAuthData') && loyaltyPointsEarnedText) {
      printJob.addNewLine();
      printJob.addText(loyaltyPointsEarnedText);
      printJob.addNewLine();
    }

    printJob.addFooter(
      location,
      order,
      true,
      options.extraFooterLines,
      menu.get('settings'),
      options.receiptFooterBase64ImageData,
    );

    const payload = printJob.toJSON();
    Log.debug('Receipt Print Job:');
    Log.debug(stringFromPrintJobPayload(payload));
    return payload;
  }

  export function kitchenPrintJob(
    location: GcnLocation,
    menu: GcnMenu,
    order: GcnOrder,
  ): PrintJob.Payload {
    return new GcnPrintJob(PrinterDestination.Kitchen)
      .addReceiptHeader(location, order, menu.get('settings'))
      .addNewLine()
      .align(PrintJob.Epos2Align.EPOS2_ALIGN_CENTER)
      .setTextSize(2)
      .addText(fulfillmentMethodStr(order))
      .addNewLine()
      .addNewLine()
      .setTextSize(1)
      .align(PrintJob.Epos2Align.EPOS2_ALIGN_LEFT)
      .addOrderBody(menu, location, order, { largePrint: true })
      .addIncludeUtensilsBody(order)
      .addOrderQRCode(menu, location, order)
      .addFooter(location, order, undefined, undefined, menu.get('settings'))
      .toJSON();
  }

  export function stringFromPrintJobPayload(
    payload: PrintJob.Payload,
    maxLineLength: number = 42,
  ): string {
    return payload.commands.reduce((printJobString, command) => {
      const [eposCmd, ...otherArguments] = command;
      switch (eposCmd) {
        case PrintJob.EposCmd.addText: {
          const text = otherArguments[0] as string;
          // const secondLineIndent = (otherArguments[1] as string || '');
          return `${printJobString}${text}\n`;
        }
        case PrintJob.EposCmd.addRepeatedText: {
          const text = otherArguments[0] as string;
          let string = '';
          let maxLength = maxLineLength;
          while (maxLength > 0) {
            if (text.length > maxLength) {
              string += text.substring(0, maxLength);
              maxLength = 0;
            } else {
              string += text;
              maxLength -= text.length;
            }
          }
          return `${printJobString}${string}\n`;
        }
        case PrintJob.EposCmd.addBarcode: {
          const barcodePayload = otherArguments[0] as string;
          const barcodeType = otherArguments[1] as EpsonBarcodeFormat;
          // we are not looking for localization here
          // eslint-disable-next-line no-restricted-syntax
          const barcodeName = EpsonBarcodeFormatHelper.name(barcodeType);
          return `${printJobString}${barcodeName} Barcode: ${barcodePayload}\n`;
        }
        case PrintJob.EposCmd.addSymbol: {
          const qrCodePayload = otherArguments[0] as string;
          const qrCodeType = otherArguments[1] as number;
          const qrCodeLevel = otherArguments[2] as number;
          const qrCodeSize = otherArguments[3] as number;
          return `${printJobString}QR Code (type: ${qrCodeType}; level: ${qrCodeLevel}; size: ${qrCodeSize}):\n${qrCodePayload}\n`;
        }
        case PrintJob.EposCmd.addFeedLine: {
          const lineBreakCount = otherArguments[0] as number;
          return `${printJobString}${'\n'.repeat(lineBreakCount)}`;
        }
        case PrintJob.EposCmd.addSound:
          return `${printJobString}SOUND!!! SOUND!!! SOUND!!!\n`;
        case PrintJob.EposCmd.addCut:
          return `${printJobString}CUT!!! CUT!!! CUT!!!\n`;
        default:
          return printJobString;
      }
    }, '');
  }
}

export default GcnPrintJob;

import { Strings } from '@biteinc/common';

import { BackboneEvents } from '~/app/js/backbone-events';
import { localizeStr } from '~/app/js/localization/localization';

import { GCNLoyaltyBarcodeView } from './gcn_loyalty_barcode_view';
import { GCNPhoneNumberEntryView } from './gcn_phone_number_entry_view';

export const GCNLoyaltyPhoneNumberView = GCNPhoneNumberEntryView.extend({
  initialize(options, ...args) {
    // If flash client, then we bring up the keyboard entry for phone number instead of keypad
    options.useNativeInput = !!window.isFlash;
    GCNPhoneNumberEntryView.prototype.initialize.apply(this, [options, ...args]);

    this._lookupUser = options.lookupUserMethod;

    this.listenTo(gcn.loyaltyManager, BackboneEvents.GCNLoyaltyManager.DidUpdateAuthGuest, () => {
      if (this._callback) {
        this._callback();
      }
      gcn.menuView.dismissStablePopup();
    });

    this.listenTo(
      gcn.loyaltyManager,
      BackboneEvents.GCNLoyaltyManager.DidFailAuth,
      this._updateLabelWithError,
    );
  },

  _updateLabelWithError(errorMsg) {
    this.__$label.htmlOrText(errorMsg);
    this.__$label.toggleClass('error', true);
  },

  render(...args) {
    GCNPhoneNumberEntryView.prototype.render.apply(this, args);

    this.__$label.htmlOrText(localizeStr(Strings.LOYALTY_APP_AUTH_PHONE_FALLBACK_LABEL));

    this.setDoneButtonText(localizeStr(Strings.SEARCH));
    this.__$doneButton.onButtonTapOrHold('lpnvDone', () => {
      if (!this.__$doneButton.hasClass('disabled')) {
        this._lookupUser(this.__userNumber);
      }
    });

    this.__$icon.hide();
    this.__$skipButton.hide();

    if (gcn.loyaltyManager.shouldEnableBarcodeAsFallbackToPhoneNumber()) {
      // If barcode is enabled as fallback, then show the barcode button
      this.__$footer.prepend(
        `<div>
          <div class="fallback-or-text-barcode-button">${localizeStr(Strings.OR).toUpperCase()}</div>
          <div class="fallback-button barcode-button">${localizeStr(
            Strings.LOYALTY_APP_AUTH_BARCODE_FALLBACK,
          )}</div>
        </div>`,
      );

      this.$('.barcode-button').onButtonTapOrHold('lpnvBarcode', () => {
        const loyaltyBarcodeView = new GCNLoyaltyBarcodeView({
          callback: () => {
            gcn.menuView.dismissStablePopup();
          },
        });
        this.listenToOnce(
          loyaltyBarcodeView,
          BackboneEvents.GCNLoyaltyBarcodeView.DidUpdateAuthGuest,
          () => {
            gcn.menuView.dismissStablePopup();
          },
        );
        gcn.menuView.showStablePopup(loyaltyBarcodeView, 'loyalty-barcode-view');
      });
    }

    return this;
  },
});

import React, { useEffect, useState } from 'react';

import { Strings } from '@biteinc/common';
import { BitePlatform, LoyaltyAuthMethod } from '@biteinc/enums';
import { Button, Dialog, DialogContent } from '@biteinc/ui';

import { useLocalize } from '~/app/js/localization/localization';
import { LOYALTY_VIEW_EXIT_EVENT } from '~/helpers/custom_events';
import {
  useAuthFriendlyName,
  useHasFetchedRewards,
  useLocation,
  useLoyaltyStatus,
  usePrefetchedRewards,
  usePreSelectedRewards,
  useSettings,
} from '~/stores';

import {
  BarcodeScanComponent,
  CardNumberComponent,
  EmailComponent,
  EmailPasswordComponent,
  EmailPhoneNumber,
  PhoneNumberAsAuthComponent,
  Rewards,
  SignUpPhoneNumberComponent,
  SignUpQRHeaderComponent,
  UsernamePasswordComponent,
} from './loyalty-form-components';
import { LoyaltyUtils } from './loyalty.utils';

enum LoyaltyModalState {
  Login = 'login',
  Signup = 'signup',
  Account = 'account',
}

const AccountContents = ({
  dismissModal,
  disabled,
}: {
  dismissModal: () => void;
  disabled: boolean;
}): JSX.Element => {
  const authFriendlyName = useAuthFriendlyName();
  const status = useLoyaltyStatus();
  const preSelectedRewards = usePreSelectedRewards();
  const rewards = usePrefetchedRewards();
  const str = useLocalize();
  const location = useLocation();
  const { hideStatusAndPoints } = location.getLoyaltyIntegration()!;

  const renderLoyaltyAccountPoints = (): JSX.Element => {
    // since `status` can contain HTML we can only set the status using `dangerouslySetInnerHTML`
    if (/<\/?[a-z][\s\S]*>/i.test(status)) {
      return (
        <div
          className="loyalty-account__points tw-font-normal tw-text-5xl tw-text-center"
          dangerouslySetInnerHTML={{ __html: status }}
        />
      );
    }
    return (
      <div className="loyalty-account__points tw-font-normal tw-text-5xl tw-text-center">
        {status}
      </div>
    );
  };

  return (
    <React.Fragment key="account-contents">
      <div className="tw-w-full tw-items-center">
        <div className="tw-flex tw-flex-row tw-justify-between tw-color-black tw-text-2xl tw-font-bold  tw-text-left tw-tracking-normal">
          <div className="tw-inline tw-overflow-hidden tw-whitespace-pre-wrap tw-shrink tw-grow-0 tw-basis-auto tw-flex-wrap tw-font-title">
            {str(Strings.LOYALTY_INTRO_WELCOME_BACK)}
          </div>
          <div
            onClick={() => {
              gcn.loyaltyManager.clearLoyaltyAuthDataRewardsAndPreSelectedRewards();
            }}
            className="tw-flex tw-shrink tw-grow-0 tw-basis-auto tw-opacity-30 tw-text-black tw-text-lg tw-font-normal tw-text-center tw-underline hover:tw-cursor-pointer hover:tw-opacity-50"
          >
            {str(Strings.SIGN_OUT)}
          </div>
        </div>
        <div className="tw-pb-2 tw-text-black tw-text-5xl tw-font-normal tw-tracking-normal tw-text-left tw-border-0 tw-border-b tw-border-solid tw-border-[#cccccc] tw-font-title">
          {authFriendlyName}
        </div>
      </div>
      {status?.length && (
        <div className="tw-my-0 tw-mx-auto tw-justify-center tw-pt-8">
          <div>
            <div className="tw-text-black tw-font-normal tw-text-lg tw-tracking-normal tw-text-center">
              {str(Strings.LOYALTY_INTRO_ACCOUNT_STATUS)}
            </div>
            {!hideStatusAndPoints && renderLoyaltyAccountPoints()}
          </div>
        </div>
      )}
      {rewards && rewards?.length > 0 && (
        <div className="tw-p-3 tw-flex tw-flex-col">
          <Rewards
            rewards={rewards}
            preSelectedRewards={preSelectedRewards}
          />
        </div>
      )}
      {!rewards?.length && (
        <div className="tw-p-3 tw-flex tw-flex-col">
          <div className="tw-p-5 tw-text-lg">{str(Strings.LOYALTY_NO_REWARDS_TEXT)}</div>
        </div>
      )}
      <div className="tw-flex tw-flex-row tw-w-full tw-pt-8 tw-pb-0 tw-justify-between tw-items-center tw-gap-5">
        <Button
          onClick={() => {
            dismissModal();
          }}
          variant="outline"
          className="tw-w-1/2 tw-border-solid tw-border tw-border-[#999] tw-text-lg "
          size="xl"
        >
          {str(Strings.LOYALTY_EXIT_TO_MENU)}
        </Button>
        <Button
          onClick={() => {
            dismissModal();
          }}
          variant="outline"
          className={`tw-w-1/2 tw-border-solid tw-border tw-border-[#999] tw-text-lg ${
            preSelectedRewards?.length !== 0
              ? `tw-border-transparent loyalty-account__reward_button-selected tw-text-white tw-bg-[--color-primary] hover:tw-border-[--color-primary] hover:tw-bg-[--color-primary] hover:tw-opacity-90`
              : ``
          }`}
          disabled={preSelectedRewards?.length === 0 || disabled}
          size="xl"
        >
          {str(Strings.LOYALTY_ACCOUNT_APPLY)}
        </Button>
      </div>
    </React.Fragment>
  );
};

function SignUp({
  onLogin,
  handleDismissModal,
}: {
  onLogin: () => void;
  handleDismissModal: () => void;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
}): JSX.Element {
  const settings = useSettings();

  const hasPhoneSignUpMethod = !!settings.simpleSignupSmsTemplate;
  const hasQRSignUpImages = !!settings.loyaltySignUpQrCodeImage?.length;

  const [signUpMethod, setSignUpMethod] = useState(() => {
    if (!hasQRSignUpImages) {
      return LoyaltyUtils.LoyaltySignUpMethod.PHONE;
    }

    return LoyaltyUtils.LoyaltySignUpMethod.QR;
  });

  return signUpMethod === LoyaltyUtils.LoyaltySignUpMethod.QR ? (
    <SignUpQRHeaderComponent
      setPhoneSignUp={
        hasPhoneSignUpMethod
          ? () => setSignUpMethod(LoyaltyUtils.LoyaltySignUpMethod.PHONE)
          : undefined
      }
      handleDismissModal={handleDismissModal}
      onLogin={onLogin}
    />
  ) : (
    <SignUpPhoneNumberComponent
      setQrSignUp={
        hasQRSignUpImages ? () => setSignUpMethod(LoyaltyUtils.LoyaltySignUpMethod.QR) : undefined
      }
      onLogin={onLogin}
      handleDismissModal={handleDismissModal}
    />
  );
}

function LogIn({
  handleDismissModal,
  onSignUp,
  hasSignUpMethod,
  hasOrder,
}: {
  handleDismissModal: () => void;
  onSignUp: () => void;
  hasSignUpMethod: boolean;
  hasOrder: boolean;
}): JSX.Element {
  const location = useLocation();
  const str = useLocalize();

  const [selectedAuthMethod, setSelectedAuthMethod] = useState<LoyaltyAuthMethod>(() => {
    const { authMethods } = location.getLoyaltyIntegration()!;
    return authMethods[0];
  });

  const emphasizeSignUp = hasSignUpMethod && gcn.loyaltyManager.isPunchhDirect();

  return (
    <>
      <div className="tw-w-full tw-items-center">
        <div className="tw-w-full tw-items-center tw-content-center tw-text-black tw-text-left tw-tracking-normal">
          <div className="tw-text-4xl tw-pb-5 tw-font-title">
            {str(Strings.WELCOME_TO_NO_NAME) || str(Strings.WELCOME_TO, [location.brandName()])}
          </div>
          <div className="tw-text-lg">{str(Strings.LOYALTY_INTRO_LOGIN_AND_EARN)}</div>
        </div>
      </div>
      {selectedAuthMethod === LoyaltyAuthMethod.PhoneNumber && (
        <PhoneNumberAsAuthComponent
          setSelectedAuthMethod={setSelectedAuthMethod}
          handleDismissModal={handleDismissModal}
          emphasizeSignUp={emphasizeSignUp}
          onSignUp={onSignUp}
          hasOrder={hasOrder}
        />
      )}
      {selectedAuthMethod === LoyaltyAuthMethod.Barcode && (
        <BarcodeScanComponent
          setSelectedAuthMethod={setSelectedAuthMethod}
          handleDismissModal={handleDismissModal}
          hasOrder={hasOrder}
        />
      )}
      {selectedAuthMethod === LoyaltyAuthMethod.CardNumber && (
        <CardNumberComponent
          handleDismissModal={handleDismissModal}
          hasOrder={hasOrder}
        />
      )}
      {selectedAuthMethod === LoyaltyAuthMethod.EmailPassword && (
        <EmailPasswordComponent
          handleDismissModal={handleDismissModal}
          hasOrder={hasOrder}
        />
      )}
      {selectedAuthMethod === LoyaltyAuthMethod.Email && (
        <EmailComponent
          handleDismissModal={handleDismissModal}
          hasOrder={hasOrder}
        />
      )}
      {selectedAuthMethod === LoyaltyAuthMethod.EmailPhoneNumber && (
        <EmailPhoneNumber
          handleDismissModal={handleDismissModal}
          hasOrder={hasOrder}
        />
      )}
      {selectedAuthMethod === LoyaltyAuthMethod.UsernamePassword && (
        <UsernamePasswordComponent
          handleDismissModal={handleDismissModal}
          hasOrder={hasOrder}
        />
      )}
      {hasSignUpMethod && (
        <div className="tw-mt-auto tw-flex tw-content-center tw-justify-center tw-w-full tw-pt-8">
          <div className="tw-cursor-pointer">
            <p>
              {!emphasizeSignUp && `${str(Strings.NOT_A_MEMBER)} `}
              <span
                className="link tw-underline"
                onClick={() => {
                  emphasizeSignUp ? handleDismissModal() : onSignUp();
                }}
              >
                {emphasizeSignUp ? str(Strings.CONTINUE_AS_GUEST) : str(Strings.SIGN_UP)}
              </span>
            </p>
          </div>
        </div>
      )}
    </>
  );
}

export const LoyaltyAccount = ({
  closeModal,
  dismissOnAuth,
}: {
  closeModal: () => void;
  withDispatch?: boolean;
  dismissOnAuth?: boolean;
}): JSX.Element => {
  const settings = useSettings();
  const hasFetchedRewards = useHasFetchedRewards();

  const hasScrim = !!settings.loyaltyAccountScrimImage?.length;
  const enableSimpleLoyaltySignup = !!settings.enableSimpleLoyaltySignup;
  const hasQRSignUpMethod = !!settings.loyaltySignUpQrCodeImage?.length;
  const hasPhoneSignUpMethod = !!settings.simpleSignupSmsTemplate;

  const canSignUp = enableSimpleLoyaltySignup && (hasQRSignUpMethod || hasPhoneSignUpMethod);

  const handleDismissModal = (): void => {
    closeModal();
  };

  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = React.useState(() => {
    if (hasFetchedRewards) {
      return LoyaltyModalState.Account;
    }
    return LoyaltyModalState.Login;
  });

  useEffect(() => {
    if (hasFetchedRewards) {
      setState(LoyaltyModalState.Account);
    } else {
      setState(LoyaltyModalState.Login);
    }
  }, [hasFetchedRewards]);

  if (hasFetchedRewards && dismissOnAuth) {
    handleDismissModal();
    return <></>;
  }

  const isIOSKiosk = window.platform === BitePlatform.KioskIos;

  return (
    <div
      className={`tw-w-full tw-h-full tw-flex tw-overflow-auto tw-flex-grow tw-font-body ${isIOSKiosk ? 'tw-max-h-[826px]' : 'tw-max-h-[80vh]'}`}
    >
      <div
        className={`tw-w-full tw-flex tw-items-center tw-flex-col tw-text-center ${
          !hasScrim ? 'tw-p-8' : ''
        }`}
      >
        {hasScrim && (
          <div className="tw-h-[150x] tw-min-h-[150px] tw-max-h-[150px] tw-w-full tw-overflow-hidden">
            <img
              className="tw-w-full"
              src={
                state === LoyaltyModalState.Signup
                  ? // Use the special image for sign up screen and fall back to the regular one
                    settings.loyaltyAccountScrimSignUpImage[0]?.url ||
                    settings.loyaltyAccountScrimImage[0].url
                  : settings.loyaltyAccountScrimImage[0].url
              }
              alt="loyalty-account-scrim"
            />
          </div>
        )}
        <div
          className={`${
            !hasScrim ? '' : 'tw-p-8'
          } tw-flex tw-flex-col tw-items-start tw-flex-grow tw-w-full tw-overflow-y-auto`}
        >
          {state === LoyaltyModalState.Login && (
            <LogIn
              handleDismissModal={handleDismissModal}
              onSignUp={() => setState(LoyaltyModalState.Signup)}
              hasSignUpMethod={canSignUp}
              hasOrder={!!dismissOnAuth}
            />
          )}
          {state === LoyaltyModalState.Signup && (
            <SignUp
              onLogin={() => setState(LoyaltyModalState.Login)}
              handleDismissModal={handleDismissModal}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
            />
          )}
          {state === LoyaltyModalState.Account && (
            <AccountContents
              disabled={false}
              dismissModal={handleDismissModal}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export const LoyaltyAccountModal = ({
  withDispatch,
  dismissOnAuth,
}: {
  withDispatch?: boolean;
  dismissOnAuth?: boolean;
}): JSX.Element => {
  const [open, setOpen] = React.useState(true);

  const onOpenChange = (_open?: boolean): void => {
    if (!_open && withDispatch) {
      document.dispatchEvent(LOYALTY_VIEW_EXIT_EVENT);
    }
    setOpen(!!_open);
  };

  return (
    <Dialog
      open={open}
      onOpenChange={onOpenChange}
    >
      <DialogContent
        className="tw-min-h-[826px] tw-w-[60%] tw-max-w-[500px]"
        onOpenAutoFocus={(event) => {
          event.preventDefault();
        }}
      >
        <LoyaltyAccount
          withDispatch={withDispatch}
          closeModal={() => onOpenChange(false)}
          dismissOnAuth={dismissOnAuth}
        />
      </DialogContent>
    </Dialog>
  );
};
